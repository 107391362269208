// TdhTableComponent.js

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { fetchPrices, fetchSznPrices } from './apiService';
import './TdhTableComponent.css'; // Import the component-specific CSS
import { ArrowUpDown, ChevronRight } from 'lucide-react'; // Import the ChevronRight icon
import ReactPaginate from 'react-paginate';
import { NavLink } from 'react-router-dom'; // Import NavLink for routing

const TdhTableComponent = () => {
  const [prices, setPrices] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'ID', direction: 'ascending' });
  const [selectedSZN, setSelectedSZN] = useState('All');
  const [selectedSupply, setSelectedSupply] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const itemsPerPage = 25;
  const [sznPrices, setSznPrices] = useState([]);

  useEffect(() => {
    const getPrices = async () => {
      try {
        const response = await fetchPrices({
          page: currentPage + 1,
          pageSize: itemsPerPage,
          sortKey: sortConfig.key,
          sortDirection: sortConfig.direction === 'ascending' ? 'asc' : 'desc',
          szn: selectedSZN,
          supply: selectedSupply,
          searchTerm: searchTerm,
        });
        setPrices(response.data);
        setTotalRecords(response.totalCount);
      } catch (error) {
        console.error('Error fetching prices:', error);
      }
    };

    getPrices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    sortConfig,
    selectedSZN,
    selectedSupply,
    searchTerm,
  ]);

  useEffect(() => {
    const getSznPrices = async () => {
      try {
        const response = await fetchSznPrices();
        setSznPrices(response.data);
      } catch (error) {
        console.error('Error fetching SZN prices:', error);
      }
    };

    getSznPrices();
  }, []);

  const sortTable = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    // Handle numeric sorting for specific columns
    if (key === 'HighestBid' || key === 'LastSalePrice' || key === 'FloorPrice' || key === 'PPTDH' || key === 'HodlRate') {
      setPrices(prevPrices => {
        const sortedPrices = [...prevPrices].sort((a, b) => {
          const aValue = parseFloat(a[key]) || 0;
          const bValue = parseFloat(b[key]) || 0;
          return direction === 'ascending' ? aValue - bValue : bValue - aValue;
        });
        return sortedPrices;
      });
    }
    
    setSortConfig({ key, direction });
    setCurrentPage(0); // Reset to first page when sorting changes
  };

  // Handle page change
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  // Unique values for filters
  const [uniqueSZNs, setUniqueSZNs] = useState(['All']);
  const [uniqueSupplies, setUniqueSupplies] = useState(['All']);

  useEffect(() => {
    // Fetch unique SZNs and Supplies
    const fetchUniqueValues = async () => {
      try {
        const response = await fetchPrices({
          page: 1,
          pageSize: 1000, // Fetch all records to get unique values
        });
        const data = response.data;
        const szns = new Set(data.map(item => item.TypeSeason));
        setUniqueSZNs(['All', ...Array.from(szns).filter(szn => szn !== null && szn !== '')]);
        const supplies = new Set(data.map(item => item.Supply));
        setUniqueSupplies(['All', ...Array.from(supplies).sort((a, b) => a - b)]);
      } catch (error) {
        console.error('Error fetching unique values:', error);
      }
    };
    fetchUniqueValues();
  }, []);

  const calculateTotalPPTDH = () => {
    const totalPPTDH = sznPrices.reduce((sum, szn) => sum + (parseFloat(szn.PPTDHTotal) || 0), 0);
    const totalCards = sznPrices.reduce((sum, szn) => sum + (parseInt(szn.CardCount) || 0), 0);
    const averagePPTDH = totalCards > 0 ? totalPPTDH / totalCards : 0;
    return { totalPPTDH, averagePPTDH };
  };

  return (
    <div className="tdh-table-container">
      <Helmet>
        <title>6529 Memes NFT TDH & PPTDH Dashboard | Track TDH & PPTDH Trends</title>
        <meta 
          name="description" 
          content="6529 Memes NFT TDH (Total Days Held) & PPTDH (Price Per TDH) tracker. Compare PPTDH across all tokens to identify the lowest-cost, most efficient buys."
        />
        <meta 
          property="og:title" 
          content="6529 Memes NFT TDH & PPTDH Dashboard | Track TDH & PPTDH Trends"
        />
        <meta 
          property="og:description" 
          content="6529 Memes NFT TDH (Total Days Held) & PPTDH (Price Per TDH) tracker. Compare PPTDH across all tokens to identify the lowest-cost, most efficient buys."
        />
        <meta property="og:image" content="https://6529stats.xyz/share-image.png" />
        <meta property="og:url" content="https://6529stats.xyz/tdh" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta 
          name="twitter:title" 
          content="6529 Memes NFT TDH & PPTDH Dashboard | Track TDH & PPTDH Trends"
        />
        <meta 
          name="twitter:description" 
          content="6529 Memes NFT TDH (Total Days Held) & PPTDH (Price Per TDH) tracker. Compare PPTDH across all tokens to identify the lowest-cost, most efficient buys."
        />
        <meta name="twitter:image" content="https://6529stats.xyz/share-image.png" />
      </Helmet>

      <div className="tdh-header">
        <h1 className="tdh-title">6529 Memes NFT Collection TDH & PPTDH Prices Dashboard</h1>
      </div>

      <form className="tdh-filters-form" onSubmit={(e) => e.preventDefault()}>
        <div className="tdh-filters-row">
          <div className="tdh-filter-item">
            <label>SZN</label>
            <select
              value={selectedSZN}
              onChange={(e) => {
                setSelectedSZN(e.target.value);
                setCurrentPage(0);
              }}
            >
              {uniqueSZNs.map((szn) => (
                <option key={szn} value={szn}>{szn}</option>
              ))}
            </select>
          </div>
          <div className="tdh-filter-item">
            <label>Supply</label>
            <select
              value={selectedSupply}
              onChange={(e) => {
                setSelectedSupply(e.target.value);
                setCurrentPage(0);
              }}
            >
              {uniqueSupplies.map((supply) => (
                <option key={supply} value={supply}>{supply}</option>
              ))}
            </select>
          </div>
          <div className="tdh-filter-item">
            <label>Search</label>
            <div className="tdh-search-container">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  if (e.target.value.length >= 2) {
                    setShowSuggestions(true);
                  } else {
                    setShowSuggestions(false);
                  }
                }}
                onFocus={() => {
                  if (searchTerm.length >= 2) {
                    setShowSuggestions(true);
                  }
                }}
                onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
                placeholder="Search cards..."
              />
              {showSuggestions && searchTerm.length >= 2 && (
                <ul className="tdh-suggestions-list">
                  {prices
                    .filter(item => item.Name.toLowerCase().includes(searchTerm.toLowerCase()))
                    .slice(0, 5)
                    .map((suggestion, index) => (
                      <li
                        key={index}
                        onMouseDown={() => {
                          setSearchTerm(suggestion.Name);
                          setShowSuggestions(false);
                          setCurrentPage(0);
                        }}
                      >
                        {suggestion.Name}
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </form>

      {/* Standalone info row */}
      <div className="tdh-info-row">
        <span className="tdh-records-count">
          <strong>Number of Records:</strong> {totalRecords}
        </span>
        <span className="tdh-records-count">
          <strong>Last Updated:</strong> {new Date().toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
          })}
        </span>
      </div>

      <div className="table-wrapper">
        <table className="centered-table">
          <thead>
            <tr>
              <th onClick={() => sortTable('ID')}>
                Token No <ArrowUpDown className="inline" />
              </th>
              <th>
                <div className="tdh-header-content">
                  Name
                </div>
              </th>
              <th>
                <div className="tdh-header-content">
                  Artist
                </div>
              </th>
              <th onClick={() => sortTable('Supply')}>
                Supply <ArrowUpDown className="inline" />
              </th>
              <th onClick={() => sortTable('TypeSeason')}>
                SZN <ArrowUpDown className="inline" />
              </th>
              <th onClick={() => sortTable('FloorPrice')}>
                Ask Price <ArrowUpDown className="inline" />
              </th>
              <th onClick={() => sortTable('HighestBid')}>
                Top Bid <ArrowUpDown className="inline" />
              </th>
              <th onClick={() => sortTable('LastSalePrice')}>
                Last Sale Price <ArrowUpDown className="inline" />
              </th>
              <th onClick={() => sortTable('HodlRate')}>
                TDH Rate <ArrowUpDown className="inline" />
                <span className="tooltip">
                  <span style={{ color: 'red' }}>*</span>
                  <span className="tooltiptext">Calculated as 3941 / Supply</span>
                </span>
              </th>
              <th onClick={() => sortTable('PPTDH')}>
                PPTDH <ArrowUpDown className="inline" />
                <span className="tooltip">
                  <span style={{ color: 'red' }}>*</span>
                  <span className="tooltiptext">Calculated as Ask Price / TDH Rate</span>
                </span>
              </th>
              <th>
                <div className="tdh-header-content">
                  6529
                </div>
              </th>
              <th>
                <div className="tdh-header-content">
                  OS
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {prices.map((price, index) => (
              <tr key={index}>
                <td>{price.ID}</td>
                <td>
                  <NavLink
                    to={`/token/${price.ID}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="name-link"
                  >
                    {price.Name} <ChevronRight className="chevron-icon" />
                  </NavLink>
                </td>
                <td>
                  <a
                    href={`https://6529.io/${price.ArtistHandle}/identity`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="name-link"
                  >
                    {price.Artist} <ChevronRight className="chevron-icon" />
                  </a>
                </td>
                <td>{price.Supply}</td>
                <td>{price.TypeSeason}</td>
                <td>{price.FloorPrice}</td>
                <td>{price.HighestBid ? parseFloat(price.HighestBid).toFixed(5) : 'N/A'}</td>
                <td>{price.LastSalePrice ? parseFloat(price.LastSalePrice).toFixed(5) : 'N/A'}</td>
                <td>{price.HodlRate}</td>
                <td>{price.PPTDH}</td>
                <td>
                  <a
                    href={`https://6529.io/the-memes/${price.ID}?focus=live`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tdh-icon-link"
                  >
                    <img src={require('./assets/seizeIcon.png')} alt="6529" className="tdh-table-icon" />
                  </a>
                </td>
                <td>
                  <a
                    href={`https://opensea.io/assets/ethereum/0x33FD426905F149f8376e227d0C9D3340AaD17aF1/${price.ID}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tdh-icon-link"
                  >
                    <img src={require('./assets/openseaIcon.png')} alt="OpenSea" className="tdh-table-icon" />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="pagination">
        <ReactPaginate
          previousLabel={'← Previous'}
          nextLabel={'Next →'}
          breakLabel={'...'}
          pageCount={Math.ceil(totalRecords / itemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      </div>

      {/* SZN PPTDH Table */}
      <div className="tdh-szn-card">
        <table className="tdh-szn-table">
          <thead>
            <tr>
              <th colSpan="3">SZN PPTDH Analysis</th>
            </tr>
            <tr>
              <th>SZN</th>
              <th>PPTDH Total</th>
              <th>Average PPTDH</th>
            </tr>
          </thead>
          <tbody>
            {sznPrices
              .sort((a, b) => a.SZN_ID - b.SZN_ID)
              .map((szn, index) => (
                <tr key={index}>
                  <td>{szn.SZN_ID}</td>
                  <td>{parseFloat(szn.PPTDHTotal).toFixed(4)}</td>
                  <td>{parseFloat(szn.AveragePPTDH).toFixed(4)}</td>
                </tr>
              ))}
            <tr className="tdh-szn-total">
              <td>All SZNs</td>
              <td>{calculateTotalPPTDH().totalPPTDH.toFixed(4)}</td>
              <td>{calculateTotalPPTDH().averagePPTDH.toFixed(4)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TdhTableComponent;
