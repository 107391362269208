// TokenComponent.js

import React, { useEffect, useState, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, Link } from 'react-router-dom';
import { fetchTokenData, fetchOwnerCount, fetchCommunityMembersData, fetchHolderLowSupplyData } from './apiService';
import './TokenComponent.css';
import etherscanIcon from './assets/etherscan.png';
import { ChevronDown, ChevronUp, ChevronRight, ArrowUpDown } from 'lucide-react';
import ReactPaginate from 'react-paginate';

const TokenComponent = () => {
    const { tokenid } = useParams();
    const [tokenData, setTokenData] = useState(null);
    const [ownerCount, setOwnerCount] = useState(null);
    const [communityMembers, setCommunityMembers] = useState(null);
    const [supplyExcl, setSupplyExcl] = useState(null);
    const [supplyExclFullSetHolders, setSupplyExclFullSetHolders] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isKeyMetricsOpen, setIsKeyMetricsOpen] = useState(false);
    const [isDistributionOpen, setIsDistributionOpen] = useState(false);
    const [isHistoricalOpen, setIsHistoricalOpen] = useState(false);
    const [holderLowSupplyData, setHolderLowSupplyData] = useState(null);
    const [currentBidsPage, setCurrentBidsPage] = useState(0);
    const [currentAsksPage, setCurrentAsksPage] = useState(0);
    const bidsPerPage = 10;
    const asksPerPage = 10;
    const [currentSalesPage, setCurrentSalesPage] = useState(0);
    const salesPerPage = 10;
    const [salesSortConfig, setSalesSortConfig] = useState({ key: 'date', direction: 'desc' });
    const [selectedType, setSelectedType] = useState('All');

    useEffect(() => {
        const getTokenData = async () => {
            try {
                const data = await fetchTokenData(tokenid);
                setTokenData(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching token data:', error);
                setLoading(false);
            }
        };
        getTokenData();
    }, [tokenid]);

    useEffect(() => {
        const getOwnerCount = async () => {
            try {
                const count = await fetchOwnerCount(tokenid);
                setOwnerCount(count);
            } catch (error) {
                console.error('Error fetching owner count:', error);
            }
        };
        getOwnerCount();
    }, [tokenid]);

    useEffect(() => {
        const getCommunityMembers = async () => {
            try {
                const data = await fetchCommunityMembersData(tokenid);
                setCommunityMembers(data);
            } catch (error) {
                console.error('Error fetching community members data:', error);
            }
        };
        getCommunityMembers();
    }, [tokenid]);

    useEffect(() => {
        if (tokenData && communityMembers) {
            const supply = parseInt(tokenData.tokenInfo.supply, 10) || 0;

            // Define the consolidation keys to exclude
            const excludedConsolidationKeys = [
                "0x32b8bce7cf8c6203a841b0d08bde5ae89f0af103-0xc2ce4ccef11a8171f443745cea3bceeaadd750c7",
                "0x6daa633c23615a29471deafae351727867e7dad1-0xa094ca13e855c3e926f9069ee140602ace421fe3-0xfd22004806a6846ea67ad883356be810f0428793",
                "0x924817154d311bd2de599f3d659d053ab0133e06-0xc6400a5584db71e41b0e5dfbdc769b54b91256cd"
            ];

            // Calculate sum of balances for excluded consolidation keys
            const sumExcludedBalances = communityMembers.holders
                .filter(holder => excludedConsolidationKeys.includes(holder.consolidation_key))
                .reduce((acc, holder) => acc + (holder.balance || 0), 0);

            // Calculate Supply (Excl ...)
            const supplyExclCalc = supply - sumExcludedBalances;
            setSupplyExcl(supplyExclCalc >= 0 ? supplyExclCalc : 0);

            // Calculate number of holders with memes_cards_sets >=1 excluding excluded consolidation keys
            const holdersFullSet = communityMembers.holders
                .filter(holder => 
                    !excludedConsolidationKeys.includes(holder.consolidation_key) && 
                    holder.memes_cards_sets && 
                    holder.memes_cards_sets >= 1
                ).length;

            // Calculate Supply (Excl ... and full set holders)
            const supplyExclFullSetHoldersCalc = supplyExclCalc - holdersFullSet;
            setSupplyExclFullSetHolders(
                supplyExclFullSetHoldersCalc >= 0 ? supplyExclFullSetHoldersCalc : 0
            );
        }
    }, [tokenData, communityMembers]);

    // Add new useEffect for holder low supply data
    useEffect(() => {
        const getHolderLowSupplyData = async () => {
            try {
                const data = await fetchHolderLowSupplyData(tokenid);
                setHolderLowSupplyData(data.data);
            } catch (error) {
                console.error('Error fetching holder low supply data:', error);
            }
        };
        getHolderLowSupplyData();
    }, [tokenid]);

    // Memoize bids, asks, and sales
    const bids = useMemo(() => tokenData?.bids || [], [tokenData]);
    const asks = useMemo(() => tokenData?.asks || [], [tokenData]);
    const sales = useMemo(() => tokenData?.sales || [], [tokenData]);

    // Calculate paginated bids and asks
    const paginatedBids = useMemo(() => {
        const startIndex = currentBidsPage * bidsPerPage;
        return bids.slice(startIndex, startIndex + bidsPerPage);
    }, [bids, currentBidsPage]);

    const paginatedAsks = useMemo(() => {
        const startIndex = currentAsksPage * asksPerPage;
        return asks.slice(startIndex, startIndex + asksPerPage);
    }, [asks, currentAsksPage]);

    // Memoize sorted sales
    const sortedSales = useMemo(() => {
        if (!sales.length) return [];
        
        const sortedArray = [...sales];
        sortedArray.sort((a, b) => {
            switch(salesSortConfig.key) {
                case 'date':
                    return new Date(a.date) - new Date(b.date);
                case 'price_usd':
                    return parseFloat(a.price_usd) - parseFloat(b.price_usd);
                case 'price_eth':
                    return parseFloat(a.price_eth) - parseFloat(b.price_eth);
                case 'orderside':
                    return a.orderside.localeCompare(b.orderside);
                default:
                    return 0;
            }
        });

        return salesSortConfig.direction === 'desc' ? sortedArray.reverse() : sortedArray;
    }, [sales, salesSortConfig]);

    // Filter sales based on selected type
    const filteredSales = useMemo(() => {
        if (selectedType === 'All') return sortedSales;
        return sortedSales.filter(sale => sale.orderside === selectedType);
    }, [sortedSales, selectedType]);

    // Update paginated sales to use filtered data
    const paginatedSales = useMemo(() => {
        const startIndex = currentSalesPage * salesPerPage;
        return filteredSales.slice(startIndex, startIndex + salesPerPage);
    }, [filteredSales, currentSalesPage]);

    // Handle pagination
    const handleBidsPageClick = (selectedPage) => {
        setCurrentBidsPage(selectedPage.selected);
    };

    const handleAsksPageClick = (selectedPage) => {
        setCurrentAsksPage(selectedPage.selected);
    };

    // Handle sales pagination
    const handleSalesPageClick = (selectedPage) => {
        setCurrentSalesPage(selectedPage.selected);
    };

    // Handle sales sorting
    const handleSalesSort = (key) => {
        setSalesSortConfig(prevConfig => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
        }));
        setCurrentSalesPage(0); // Reset to first page when sorting
    };

    const toggleSection = (section) => {
        switch(section) {
            case 'keyMetrics':
                setIsKeyMetricsOpen(!isKeyMetricsOpen);
                break;
            case 'distribution':
                setIsDistributionOpen(!isDistributionOpen);
                break;
            case 'historical':
                setIsHistoricalOpen(!isHistoricalOpen);
                break;
            default:
                break;
        }
    };

    // Early returns
    if (loading) {
        return <div className="spinner"></div>;
    }

    if (!tokenData) {
        return <div>Error loading token data.</div>;
    }

    // Destructure data for easier access
    const { tokenInfo, salesPrices } = tokenData;

    // Calculate Top Bid
    const topBid = bids.length > 0 ? Math.max(...bids.map(bid => parseFloat(bid.amount_eth))) : null;

    // Calculate Lowest Ask
    const lowestAsk = asks.length > 0 ? Math.min(...asks.map(ask => parseFloat(ask.amount_eth))) : null;

    // Get Last Sale
    const lastSale = sales.length > 0 ? parseFloat(sales[0].price_eth) : null;

    return (
        <div className="token-container">
            {tokenInfo && (
                <Helmet>
                    <title>{`6529 Memes NFT #${tokenInfo.tokenid} – SZN ${tokenInfo.typeseason} | Live Bids, Floor & Sales History`}</title>
                    <meta 
                        name="description" 
                        content={`6529 Memes NFT #${tokenInfo.tokenid} from SZN ${tokenInfo.typeseason}. View supply (${tokenInfo.supply}), real-time bids, floor prices, latest sale prices in ETH & USD & complete sales & owner history. Live Updates`}
                    />
                    <meta 
                        property="og:title" 
                        content={`6529 Memes NFT #${tokenInfo.tokenid} – SZN ${tokenInfo.typeseason} | Live Bids, Floor & Sales History`}
                    />
                    <meta 
                        property="og:description" 
                        content={`6529 Memes NFT #${tokenInfo.tokenid} from SZN ${tokenInfo.typeseason}. View supply (${tokenInfo.supply}), real-time bids, floor prices, latest sale prices in ETH & USD & complete sales & owner history. Live Updates`}
                    />
                    <meta property="og:image" content="https://6529stats.xyz/share-image.png" />
                    <meta property="og:url" content={`https://6529stats.xyz/token/${tokenInfo.tokenid}`} />
                    <meta property="og:type" content="website" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta 
                        name="twitter:title" 
                        content={`6529 Memes NFT #${tokenInfo.tokenid} – SZN ${tokenInfo.typeseason} | Live Bids, Floor & Sales History`}
                    />
                    <meta 
                        name="twitter:description" 
                        content={`6529 Memes NFT #${tokenInfo.tokenid} from SZN ${tokenInfo.typeseason}. View supply (${tokenInfo.supply}), real-time bids, floor prices, latest sale prices in ETH & USD & complete sales & owner history. Live Updates`}
                    />
                    <meta name="twitter:image" content="https://6529stats.xyz/share-image.png" />
                </Helmet>
            )}

            <div className="token-header">
                <h1 className="token-title">
                    {tokenInfo && `6529 Memes NFT #${tokenInfo.tokenid} – SZN ${tokenInfo.typeseason} | Live Prices, Sales & Market Data`}
                </h1>
                <p className="token-metadata">
                    {tokenInfo && `${tokenInfo.name} • Artist ${tokenInfo.artist}`}
                </p>
                <p className="token-owners">
                    <span className="owners-label">Owners</span>
                    <span className="owners-count">{ownerCount !== null ? ownerCount : '...'}</span>
                    <Link 
                        to={`/token/${tokenid}/owners`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="analytics-link"
                    >
                        Owner Analytics
                        <ChevronRight size={16} className="chevron-icon" style={{ color: '#ff0000' }} />
                    </Link>
                </p>
            </div>

            {/* Metrics Grid Container */}
            <div className="metrics-grid">
                {/* Distribution Section */}
                <div className="metrics-section">
                    <div className="section-header" onClick={() => toggleSection('distribution')}>
                        <h2>Distribution</h2>
                        {isDistributionOpen ? <ChevronUp /> : <ChevronDown />}
                    </div>
                    {isDistributionOpen && (
                        <div className="metrics-content">
                            <div className="metric-tile">
                                <h3>Supply</h3>
                                <p>{tokenInfo.supply}</p>
                            </div>
                            <div className="metric-tile">
                                <h3>
                                    Supply Excl 6529
                                    <button 
                                        className="tooltip-button"
                                        aria-label="6529 Museum, 6529.eth, research.eth, social.6529.eth, and trove.6529.eth"
                                        data-tooltip="6529 Museum, 6529.eth, research.eth, social.6529.eth, and trove.6529.eth"
                                    >
                                        ⓘ
                                    </button>
                                </h3>
                                <p>{supplyExcl !== null ? supplyExcl : '...'}</p>
                            </div>
                            <div className="metric-tile">
                                <h3>
                                    Supply Excl 6529 & Full Set Holders
                                    <button 
                                        className="tooltip-button"
                                        aria-label="6529 Museum, 6529.eth, research.eth, and full set holders"
                                        data-tooltip="6529 Museum, 6529.eth, research.eth, and full set holders"
                                    >
                                        ⓘ
                                    </button>
                                </h3>
                                <p>{supplyExclFullSetHolders !== null ? supplyExclFullSetHolders : '...'}</p>
                            </div>
                            <div className="metric-tile">
                                <h3>
                                    Available <a 
                                        href="https://6529stats.xyz/hodlers/supply-enhanced"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="learn-more-link"
                                    >
                                        (Learn More)
                                    </a>
                                </h3>
                                <p>
                                    {holderLowSupplyData ? 
                                        `${holderLowSupplyData.supply_remaining_if_full_and_szn_set_holders_keep1} (${holderLowSupplyData.percentage_supply_remaining_if_full_and_szn_set_holders_keep1}% of supply)` : 
                                        '...'}
                                </p>
                            </div>
                        </div>
                    )}
                </div>

                {/* Historical Data Section */}
                <div className="metrics-section">
                    <div className="section-header" onClick={() => toggleSection('historical')}>
                        <h2>Historical Data</h2>
                        {isHistoricalOpen ? <ChevronUp /> : <ChevronDown />}
                    </div>
                    {isHistoricalOpen && (
                        <div className="metrics-content">
                            <div className="metric-tile">
                                <h3>Highest Sale</h3>
                                <p>{salesPrices.highest_sale_eth} ETH</p>
                                <p className="usd-value">${salesPrices.highest_sale_usd.toLocaleString()}</p>
                            </div>
                            <div className="metric-tile">
                                <h3>Lowest Sale</h3>
                                <p>{tokenData?.salesPrices?.lowest_sale_eth ? `${tokenData.salesPrices.lowest_sale_eth} ETH` : '...'}</p>
                                <p className="usd-value">
                                    {tokenData?.salesPrices?.lowest_sale_usd ? `$${tokenData.salesPrices.lowest_sale_usd.toLocaleString()}` : '...'}
                                </p>
                            </div>
                            <div className="metric-tile">
                                <h3>Total Sales</h3>
                                <p>{tokenData?.sales?.length || '...'}</p>
                                <p className="usd-value">
                                    {tokenData?.sales?.length > 0 ? 
                                        `${tokenData.sales.reduce((sum, sale) => sum + parseFloat(sale.price_eth), 0).toFixed(2)} ETH ($${tokenData.sales.reduce((sum, sale) => sum + parseFloat(sale.price_usd), 0).toLocaleString()})` : 
                                        '...'}
                                </p>
                            </div>
                            <div className="metric-tile">
                                <h3>Average Sale</h3>
                                <p>
                                    {tokenData?.sales?.length > 0 ? 
                                        `${(tokenData.sales.reduce((sum, sale) => sum + parseFloat(sale.price_eth), 0) / tokenData.sales.length).toFixed(3)} ETH` : 
                                        '...'}
                                </p>
                                <p className="usd-value">
                                    {tokenData?.sales?.length > 0 ? 
                                        `$${(tokenData.sales.reduce((sum, sale) => sum + parseFloat(sale.price_usd), 0) / tokenData.sales.length).toLocaleString(undefined, {maximumFractionDigits: 2})}` : 
                                        '...'}
                                </p>
                            </div>
                        </div>
                    )}
                </div>

                {/* Key Metrics Section */}
                <div className="metrics-section">
                    <div className="section-header" onClick={() => toggleSection('keyMetrics')}>
                        <h2>Key Metrics</h2>
                        {isKeyMetricsOpen ? <ChevronUp /> : <ChevronDown />}
                    </div>
                    {isKeyMetricsOpen && (
                        <div className="metrics-content">
                            <div className="metric-tile">
                                <h3>Top Bid</h3>
                                <p>{topBid ? `${topBid.toFixed(5)} ETH` : 'N/A'}</p>
                            </div>
                            <div className="metric-tile">
                                <h3>Floor</h3>
                                <p>{lowestAsk ? `${lowestAsk.toFixed(5)} ETH` : 'N/A'}</p>
                            </div>
                            <div className="metric-tile">
                                <h3>Last Sale</h3>
                                <p>{lastSale ? `${lastSale.toFixed(5)} ETH` : 'N/A'}</p>
                            </div>
                            <div className="metric-tile">
                                <h3>PPTDH</h3>
                                <p>{tokenInfo.pptdh || 'N/A'}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Tables Container */}
            <div className="tables-container">
                {/* Bids Table Section */}
                <div className="table-section">
                    <h2>Bids</h2>
                    <div className="table-container">
                        <table className="data-table">
                            <thead>
                                <tr>
                                    <th>Amount (ETH)</th>
                                    <th>Bidder</th>
                                    <th>Quantity</th>
                                    <th>Bid Type</th>
                                    <th>Marketplace</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedBids.map((bid, index) => (
                                    <tr key={index}>
                                        <td>{parseFloat(bid.amount_eth).toFixed(4)}</td>
                                        <td>
                                            <a
                                                href={`https://etherscan.io/address/${bid.maker}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="address-link"
                                            >
                                                {`${bid.maker.substring(0, 6)}...${bid.maker.substring(bid.maker.length - 4)}`}
                                            </a>
                                        </td>
                                        <td>{bid.quantity}</td>
                                        <td>{bid.criteria_kind}</td>
                                        <td>
                                            {bid.source_icon && (
                                                <a href={bid.source_url} target="_blank" rel="noopener noreferrer">
                                                    <img src={bid.source_icon} alt={bid.source_name} className="marketplace-table-icon" />
                                                </a>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {bids.length > bidsPerPage && (
                        <ReactPaginate
                            previousLabel={'← Previous'}
                            nextLabel={'Next →'}
                            breakLabel={'...'}
                            pageCount={Math.ceil(bids.length / bidsPerPage)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handleBidsPageClick}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    )}
                </div>

                {/* Asks Table Section */}
                <div className="table-section">
                    <h2>Asks</h2>
                    <div className="table-container">
                        <table className="data-table">
                            <thead>
                                <tr>
                                    <th>Amount (ETH)</th>
                                    <th>Seller</th>
                                    <th>Quantity</th>
                                    <th>Marketplace</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedAsks.map((ask, index) => (
                                    <tr key={index}>
                                        <td>{parseFloat(ask.amount_eth).toFixed(4)}</td>
                                        <td>
                                            <a
                                                href={`https://etherscan.io/address/${ask.maker}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="address-link"
                                            >
                                                {`${ask.maker.substring(0, 6)}...${ask.maker.substring(ask.maker.length - 4)}`}
                                            </a>
                                        </td>
                                        <td>{ask.quantity}</td>
                                        <td>
                                            {ask.source_icon && (
                                                <a href={ask.source_url} target="_blank" rel="noopener noreferrer">
                                                    <img src={ask.source_icon} alt={ask.source_name} className="marketplace-table-icon" />
                                                </a>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {asks.length > asksPerPage && (
                        <ReactPaginate
                            previousLabel={'← Previous'}
                            nextLabel={'Next →'}
                            breakLabel={'...'}
                            pageCount={Math.ceil(asks.length / asksPerPage)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handleAsksPageClick}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    )}
                </div>
            </div>

            {/* Sales Table */}
            <div className="table-section">
                <h2>Most Recent Sales</h2>
                <div className="sales-header">
                    <div className="sales-filters">
                        <select
                            value={selectedType}
                            onChange={(e) => {
                                setSelectedType(e.target.value);
                                setCurrentSalesPage(0);
                            }}
                            className="sales-filter-select"
                        >
                            <option value="All">All Types</option>
                            <option value="bid">Bid</option>
                            <option value="ask">Ask</option>
                        </select>
                    </div>
                    <span className="records-count">Records: {filteredSales.length}</span>
                </div>
                <div className="table-container">
                    <table className="data-table">
                        <thead>
                            <tr>
                                <th onClick={() => handleSalesSort('date')}>
                                    <div className="header-content">
                                        Date
                                        <ArrowUpDown className={`sort-icon ${salesSortConfig.key === 'date' ? 'active' : ''}`} />
                                    </div>
                                </th>
                                <th onClick={() => handleSalesSort('price_usd')}>
                                    <div className="header-content">
                                        Price (USD)
                                        <ArrowUpDown className={`sort-icon ${salesSortConfig.key === 'price_usd' ? 'active' : ''}`} />
                                    </div>
                                </th>
                                <th onClick={() => handleSalesSort('price_eth')}>
                                    <div className="header-content">
                                        Price (ETH)
                                        <ArrowUpDown className={`sort-icon ${salesSortConfig.key === 'price_eth' ? 'active' : ''}`} />
                                    </div>
                                </th>
                                <th onClick={() => handleSalesSort('orderside')}>
                                    <div className="header-content">
                                        Type
                                        <ArrowUpDown className={`sort-icon ${salesSortConfig.key === 'orderside' ? 'active' : ''}`} />
                                    </div>
                                </th>
                                <th>Seller</th>
                                <th>Buyer</th>
                                <th>Tx</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedSales.map((sale, index) => (
                                <tr key={index}>
                                    <td>{new Date(sale.date).toLocaleDateString(undefined, {
                                        day: 'numeric',
                                        month: 'short',
                                        year: '2-digit'
                                    })}</td>
                                    <td>${parseFloat(sale.price_usd).toLocaleString()}</td>
                                    <td>{parseFloat(sale.price_eth).toFixed(4)}</td>
                                    <td>{sale.orderside.charAt(0).toUpperCase() + sale.orderside.slice(1)}</td>
                                    <td>
                                        <a
                                            href={`https://etherscan.io/address/${sale.seller}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="address-link"
                                        >
                                            {`${sale.seller.substring(0, 6)}...${sale.seller.substring(sale.seller.length - 4)}`}
                                        </a>
                                    </td>
                                    <td>
                                        <a
                                            href={`https://etherscan.io/address/${sale.buyer}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="address-link"
                                        >
                                            {`${sale.buyer.substring(0, 6)}...${sale.buyer.substring(sale.buyer.length - 4)}`}
                                        </a>
                                    </td>
                                    <td>
                                        {sale.txhash && (
                                            <a
                                                href={`https://etherscan.io/tx/${sale.txhash}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img src={etherscanIcon} alt="Etherscan" className="marketplace-table-icon" />
                                            </a>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {filteredSales.length > salesPerPage && (
                    <ReactPaginate
                        previousLabel={'← Previous'}
                        nextLabel={'Next →'}
                        breakLabel={'...'}
                        pageCount={Math.ceil(filteredSales.length / salesPerPage)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handleSalesPageClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                )}
            </div>
        </div>
    );
};

export default TokenComponent;
