// apiService.js

const API_BASE_URL = "https://api.6529stats.xyz/api";

// Function to fetch community members data
export const fetchCommunityMembersData = async (tokenid) => {
    const response = await fetch(`${API_BASE_URL}/communitymembers/${tokenid}`, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch community members data for token ${tokenid}.`);
    }
    return await response.json();
};

// Function to fetch 6529 TDH data
export const fetch6529TdhData = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/6529tdh`, {
            method: 'GET'
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching 6529 TDH data:', error);
        throw error;
    }
};

// Fetch Portfolio All Data (used by MyPortfolioComponent)
export const fetchPortfolioAllData = async (profile, params) => {
    const filteredParams = Object.fromEntries(
        Object.entries(params || {}).filter(
            ([_, v]) => v !== undefined && v !== null && v !== 'undefined' && v !== 'All'
        )
    );
    const queryString = new URLSearchParams(filteredParams).toString();
    const url = queryString ? 
        `${API_BASE_URL}/portfolio_all/${profile}?${queryString}` :
        `${API_BASE_URL}/portfolio_all/${profile}`;
    
    const response = await fetch(url, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch portfolio_all data for profile: ${profile}.`);
    }
    return await response.json();
};

// Fetch Portfolio Bids Data (used by MyPortfolioBidsComponent)
export const fetchPortfolioBidsData = async (profile) => {
    const response = await fetch(`${API_BASE_URL}/portfolio_bids/${profile}`, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch portfolio bids data for profile: ${profile}.`);
    }
    return await response.json();
};

// Existing fetch functions
export const fetchPrices = async (params) => {
    const filteredParams = Object.fromEntries(
        Object.entries(params || {}).filter(
            ([_, v]) => v !== undefined && v !== null && v !== 'undefined' && v !== ''
        )
    );
    const queryString = new URLSearchParams(filteredParams).toString();
    const response = await fetch(`${API_BASE_URL}/prices?${queryString}`, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error("Failed to fetch prices data.");
    }
    return await response.json();
};

export const fetchSznPrices = async () => {
    const response = await fetch(`${API_BASE_URL}/szn_prices`, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error("Failed to fetch SZN prices data.");
    }
    return await response.json();
};

export const fetchSznPricesHistory = async () => {
    const response = await fetch(`${API_BASE_URL}/szn_prices_history`, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error("Failed to fetch SZN prices history data.");
    }
    return await response.json();
};

// Function to fetch sales data
export const fetchSalesData = async (timePeriod) => {
    let endpoint;
    switch (timePeriod) {
        case 'last24hours':
            endpoint = 'sales_last24hours';
            break;
        case 'last7days':
            endpoint = 'sales_last7days';
            break;
        case 'last30days':
            endpoint = 'sales_last30days';
            break;
        case '2022':
            endpoint = 'sales_2022';
            break;
        case '2023':
            endpoint = 'sales_2023';
            break;
        case '2024':
            endpoint = 'sales_2024';
            break;
        case 'all':
        default:
            endpoint = 'sales_all';
            break;
    }
    const response = await fetch(`${API_BASE_URL}/${endpoint}`, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch sales data for ${timePeriod}.`);
    }
    return await response.json();
};

// Function to fetch sales_bot_AllTime data
export const fetchSalesBotAllTimeData = async (params) => {
    const filteredParams = Object.fromEntries(
        Object.entries(params || {}).filter(
            ([_, v]) => v !== undefined && v !== null && v !== 'undefined' && v !== 'All'
        )
    );

    // Handle supply comparison parameters
    if (filteredParams.supply) {
        filteredParams.supplyCompare = filteredParams.supplyCompare || 'gt';
    }

    const queryString = new URLSearchParams(filteredParams).toString();
    const response = await fetch(`${API_BASE_URL}/sales_bot_AllTime?${queryString}`, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error('Failed to fetch sales bot data.');
    }
    return await response.json();
};

// Function to fetch sales summary
export const fetchSalesSummary = async ({ timeFrame, startDate, endDate }) => {
    let url = `${API_BASE_URL}/sales_summary?`;

    if (timeFrame && timeFrame !== 'custom') {
        const validTimeFrames = ['24h', '7d', '30d', 'ytd', 'all'];
        if (!validTimeFrames.includes(timeFrame)) {
            throw new Error('Invalid timeFrame parameter.');
        }
        url += `timeFrame=${timeFrame}`;
    } else if (timeFrame === 'custom') {
        if (!startDate || !endDate) {
            throw new Error('startDate and endDate are required for custom time frame.');
        }
        url += `timeFrame=custom&startDate=${startDate}&endDate=${endDate}`;
    } else {
        throw new Error('Invalid parameters for fetchSalesSummary.');
    }

    const response = await fetch(url, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch sales summary for ${timeFrame}.`);
    }
    return await response.json();
};

// Function to fetch token data (Used by TokenComponent)
export const fetchTokenData = async (tokenid) => {
    const response = await fetch(`${API_BASE_URL}/token/${tokenid}`, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch data for token ${tokenid}.`);
    }
    return await response.json();
};

// New function to fetch holder low supply data
export const fetchHolderLowSupplyData = async (tokenid) => {
    const response = await fetch(`${API_BASE_URL}/holderlowsupply/${tokenid}`, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch holder low supply data for token ${tokenid}.`);
    }
    return await response.json();
};

// Function to fetch owner count (Used by TokenComponent)
export const fetchOwnerCount = async (tokenid) => {
    const response = await fetch(`${API_BASE_URL}/tokenowners?token_id=${tokenid}`, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch owner count for token ${tokenid}.`);
    }
    const data = await response.json();
    return data.totalOwners;
};

// Function to fetch owners data with pagination
export const fetchOwnersData = async (tokenid, searchTerm = '', page = 1, page_size = 2500) => {
    const params = new URLSearchParams({
        token_id: tokenid,
        page: page,
        page_size: page_size,
    });
    if (searchTerm) {
        params.append('search', searchTerm);
    }
    const response = await fetch(`${API_BASE_URL}/tokenowners?${params.toString()}`, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch owners data for token ${tokenid}.`);
    }
    return await response.json();
};

// Function to fetch owners aggregated stats data
export const fetchOwnersAggregatedStatsLatest = async () => {
    const response = await fetch(`${API_BASE_URL}/ownersaggregatedstats_latest`, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error("Failed to fetch owners aggregated stats data.");
    }
    return await response.json();
};

// Function to fetch daily metrics data
export const fetchDailyMetrics = async () => {
    const response = await fetch(`${API_BASE_URL}/daily_metrics`, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error("Failed to fetch daily metrics data.");
    }
    return await response.json();
};

// Function to fetch hodler supply data
export const fetchHodlerSupplyData = async (params = {}) => {
    const {
        page = 1,
        pageSize = 25,
        sortKey = 'Token',
        sortDirection = 'ascending'
    } = params;

    const response = await fetch(`${API_BASE_URL}/hodler_supply`, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error('Failed to fetch hodler supply data.');
    }
    const result = await response.json();
    
    // Client-side pagination and sorting since the backend doesn't support it yet
    let data = [...result.data];
    
    // Sort the data
    data.sort((a, b) => {
        let aValue = a[sortKey];
        let bValue = b[sortKey];

        // Handle null or undefined values
        if (aValue === null || aValue === undefined) aValue = '';
        if (bValue === null || bValue === undefined) bValue = '';

        // Handle numeric values for specific columns
        if (sortKey === 'Supply_Excl_6529_Museum' || sortKey === 'Supply_Excl_6529_Museum_IndivSZNholder' || 
            sortKey === 'Supply' || sortKey === 'Token' || sortKey === 'FloorPrice' || 
            sortKey === 'HighestBid' || sortKey === 'LastSalePrice') {
            // Convert to numbers for numeric comparison
            aValue = parseFloat(aValue) || 0;
            bValue = parseFloat(bValue) || 0;
            return sortDirection === 'ascending' ? aValue - bValue : bValue - aValue;
        }

        if (typeof aValue === 'string') {
            aValue = aValue.toLowerCase();
            bValue = bValue.toLowerCase();
        }

        if (aValue < bValue) {
            return sortDirection === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
            return sortDirection === 'ascending' ? 1 : -1;
        }
        return 0;
    });

    // Calculate pagination
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedData = data.slice(startIndex, endIndex);

    return {
        data: paginatedData,
        totalCount: result.data.length
    };
};

export const fetchHodlerSupplyEnhancedData = async (
    page = 1,
    pageSize = 25,
    sortKey = 'Card No',
    sortDirection = 'ascending',
    szn = 'All',
    searchTerm = ''
) => {
    const params = new URLSearchParams({
        page: page,
        pageSize: pageSize,
        sortKey: sortKey,
        sortDirection: sortDirection,
    });
    if (szn !== 'All') {
        params.append('szn', szn);
    }
    if (searchTerm) {
        params.append('searchTerm', searchTerm);
    }
    const response = await fetch(`${API_BASE_URL}/hodler_supply_enhanced?${params.toString()}`, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error('Failed to fetch hodler supply enhanced data.');
    }
    return await response.json();
};

// Function to fetch under mint count data
export const fetchUnderMintCount = async () => {
    const response = await fetch(`${API_BASE_URL}/under_mint_count`, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error('Failed to fetch under mint count data.');
    }
    return await response.json();
};

// Function to fetch owners aggregated stats historical data
export const fetchOwnersAggregatedStatsHistorical = async () => {
    const response = await fetch(`${API_BASE_URL}/ownersaggregatedstatshistorical`, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error('Failed to fetch owners aggregated stats historical data.');
    }
    return await response.json();
};

// Function to fetch PPTDH data
export const fetchPPTDH = async () => {
    try {
        const response = await fetch('/api/low_pptdh', {
            method: 'GET'
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching PPTDH data:', error);
        throw error;
    }
};

// Function to fetch monthly PPTDH averages
export const fetchMonthlyPPTDHAverages = async () => {
    const response = await fetch(`${API_BASE_URL}/monthly_pptdh_averages`, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error('Failed to fetch monthly PPTDH averages.');
    }
    return await response.json();
};

// Function to fetch pebblesmain data
export const fetchPebblesMainData = async (params) => {
    const queryString = new URLSearchParams(
        Object.fromEntries(
            Object.entries(params).filter(([_, v]) => v !== undefined && v !== null && v !== 'All')
        )
    ).toString();
    
    const response = await fetch(`${API_BASE_URL}/pebblesmain?${queryString}`, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error('Failed to fetch pebbles main data.');
    }
    return await response.json();
};

// Function to fetch pebbles sales data
export const fetchPebblesSalesData = async (params) => {
    const queryString = new URLSearchParams(
        Object.fromEntries(
            Object.entries(params).filter(([_, v]) => v !== undefined && v !== null && v !== 'All')
        )
    ).toString();
    
    const response = await fetch(`${API_BASE_URL}/pebblessales?${queryString}`, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error('Failed to fetch pebbles sales data.');
    }
    return await response.json();
};

// Function to fetch Pebbles portfolio data
export const fetchPortfolioPebblesData = async (profile) => {
    const response = await fetch(`${API_BASE_URL}/portfoliopebbles/${profile}`, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch Pebbles portfolio data for profile: ${profile}.`);
    }
    return await response.json();
};

// Function to fetch artist data
export const fetchArtistData = async (params) => {
    const filteredParams = Object.fromEntries(
        Object.entries(params || {}).filter(
            ([_, v]) => v !== undefined && v !== null && v !== 'undefined' && v !== 'All'
        )
    );
    const queryString = new URLSearchParams(filteredParams).toString();
    const response = await fetch(`${API_BASE_URL}/artist?${queryString}`, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error('Failed to fetch artist data.');
    }
    return await response.json();
};

// Function to fetch holders all data
export const fetchHoldersAllData = async (params) => {
    const filteredParams = Object.fromEntries(
        Object.entries(params || {}).filter(
            ([_, v]) => v !== undefined && v !== null && v !== 'undefined' && v !== 'All'
        )
    );
    const queryString = new URLSearchParams(filteredParams).toString();
    const response = await fetch(`${API_BASE_URL}/holdersall?${queryString}`, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error('Failed to fetch holders data.');
    }
    return await response.json();
};

// Function to fetch individual holder data
export const fetchHolderIndividualData = async (profile) => {
    const response = await fetch(`${API_BASE_URL}/holdersindividual/${profile}`, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch individual holder data for profile: ${profile}.`);
    }
    return await response.json();
};

// Function to get artwork stream URL
export const getArtworkStreamUrl = (tokenId) => {
    return `/api/stream-artwork/${tokenId}`;
};

// Function to get compact image URL from a record
export const getCompactImageUrl = (record) => {
    // Handle both ImageCompact (from Prices table) and image (from salesreservoir table)
    return record?.ImageCompact || record?.image || null;
};

// Function to fetch artist directory data
export const fetchArtistDirectory = async () => {
    const response = await fetch(`${API_BASE_URL}/artist-directory`, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error('Failed to fetch artist directory data.');
    }
    return await response.json();
};

// Function to calculate TDH
export const calculateTDH = async (tokenIds) => {
    const response = await fetch(`${API_BASE_URL}/tdhcalc/${tokenIds.join(',')}`, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error('Failed to calculate TDH.');
    }
    return await response.json();
};

// Function to search consolidation TDH
export const searchConsolidationTDH = async (address, tokenIds) => {
    const response = await fetch(`${API_BASE_URL}/consolidation-tdh/${address}/${tokenIds.join(',')}`, {
        method: 'GET'
    });
    if (!response.ok) {
        throw new Error('Failed to search consolidation TDH.');
    }
    return await response.json();
};

