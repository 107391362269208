import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { 
  fetchDailyMetrics, 
  fetchUnderMintCount, 
  fetchOwnersAggregatedStatsHistorical,
  fetchPPTDH,
  fetchMonthlyPPTDHAverages,
  fetch6529TdhData
} from './apiService';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import './InsightsComponent.css';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const InsightsComponent = () => {
  const [data, setData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [holdersChartData, setHoldersChartData] = useState(null);
  const [historicalData, setHistoricalData] = useState(null);
  const [pptdhChartData, setPptdhChartData] = useState(null);
  const [monthlyPPTDHData, setMonthlyPPTDHData] = useState(null);
  const [tdhChartData, setTdhChartData] = useState(null);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const [metricsResponse, mintCountResponse, historicalResponse, pptdhResponse, monthlyPPTDHResponse, tdhResponse] = await Promise.all([
          fetchDailyMetrics(),
          fetchUnderMintCount(),
          fetchOwnersAggregatedStatsHistorical(),
          fetchPPTDH(),
          fetchMonthlyPPTDHAverages(),
          fetch6529TdhData()
        ]);

        setData(metricsResponse.data);
        setHistoricalData(historicalResponse.data);

        // Set up cards chart data
        const dates = mintCountResponse.data.map(item => new Date(item.date).toLocaleDateString());
        const lowFloorCounts = mintCountResponse.data.map(item => item.low_floor);
        const totalCounts = mintCountResponse.data.map(item => item.count);

        setChartData({
          labels: dates,
          datasets: [
            {
              label: 'Cards Under Mint price',
              data: lowFloorCounts,
              borderColor: 'red',
              backgroundColor: 'red',
              pointRadius: 3,
              tension: 0.1
            },
            {
              label: 'No Of Cards Available',
              data: totalCounts,
              borderColor: 'green',
              backgroundColor: 'green',
              pointRadius: 3,
              tension: 0.1
            }
          ]
        });

        // Set up holders chart data
        const holdersDates = historicalResponse.data.map(item => new Date(item.fetch_timestamp).toLocaleDateString());
        const holdersCounts = historicalResponse.data.map(item => item.holders_any_tokenid);

        setHoldersChartData({
          labels: holdersDates,
          datasets: [
            {
              label: 'Holders Count',
              data: holdersCounts,
              borderColor: 'red',
              backgroundColor: 'red',
              pointRadius: 3,
              tension: 0.1
            }
          ]
        });

        // Process PPTDH data
        setPptdhChartData({
          labels: pptdhResponse.data.map(item => item.date),
          datasets: [
            {
              label: 'PPTDH',
              data: pptdhResponse.data.map(item => item.pptdh),
              borderColor: 'red',
              backgroundColor: 'red',
              pointRadius: 3,
              tension: 0.1
            }
          ]
        });

        // Set up monthly PPTDH chart data
        setMonthlyPPTDHData({
          labels: monthlyPPTDHResponse.data.map(item => item.month),
          datasets: [
            {
              label: 'Average Monthly PPTDH',
              data: monthlyPPTDHResponse.data.map(item => item.avg_pptdh),
              borderColor: 'blue',
              backgroundColor: 'blue',
              pointRadius: 3,
              tension: 0.1,
              yAxisID: 'y'
            },
            {
              label: 'Number of Sales',
              data: monthlyPPTDHResponse.data.map(item => item.sale_count),
              borderColor: 'red',
              backgroundColor: 'red',
              pointRadius: 3,
              tension: 0.1,
              yAxisID: 'y1'
            }
          ]
        });

        // Set up 6529 TDH chart data
        if (tdhResponse.data && tdhResponse.data.dates) {
          setTdhChartData({
            labels: tdhResponse.data.dates,
            datasets: [
              {
                label: 'social.6529.eth,trove.punk6529.eth,punk6529',
                data: tdhResponse.data.datasets[0].data,
                borderColor: '#FF5733',
                backgroundColor: '#FF5733',
                pointRadius: 3,
                tension: 0.1
              },
              {
                label: 'research.6529.eth',
                data: tdhResponse.data.datasets[1].data,
                borderColor: '#33FF57',
                backgroundColor: '#33FF57',
                pointRadius: 3,
                tension: 0.1
              },
              {
                label: '6529Museum',
                data: tdhResponse.data.datasets[2].data,
                borderColor: '#3357FF',
                backgroundColor: '#3357FF',
                pointRadius: 3,
                tension: 0.1
              },
              {
                label: 'Combined 6529 Ecosystem',
                data: tdhResponse.data.datasets[3].data,
                borderColor: '#FF33F5',
                backgroundColor: '#FF33F5',
                pointRadius: 3,
                tension: 0.1,
                borderWidth: 3
              }
            ]
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchAllData();
  }, []);

  const getHistoricalValue = (metric, days) => {
    if (!historicalData || historicalData.length === 0) return null;
    const today = new Date();
    const targetDate = new Date(today.getTime() - (days * 24 * 60 * 60 * 1000));
    const historicalEntry = historicalData
      .sort((a, b) => Math.abs(new Date(a.fetch_timestamp) - targetDate) - Math.abs(new Date(b.fetch_timestamp) - targetDate))[0];

    switch(metric) {
      case 'Holders Count':
        return historicalEntry.holders_any_tokenid;
      case 'Full Set Holders':
        return historicalEntry.holders_all_tokenid;
      case 'Hold Min One Card per SZN':
        return historicalEntry.holders_any_token_each_season;
      default:
        return null;
    }
  };

  const calculateChange = (currentValue, historicalValue) => {
    if (!currentValue || !historicalValue) return 'N/A';
    const change = currentValue - historicalValue;
    const arrow = change >= 0 ? '↑' : '↓';
    const className = change >= 0 ? 'arrow-up' : 'arrow-down';
    return (
      <>
        {historicalValue}{' '}
        <span className={className}>
          {arrow}{Math.abs(change)}
        </span>
      </>
    );
  };

  if (!data) {
    return <div className="insights-container">Loading...</div>;
  }

  const { interval_metrics, single_metrics } = data;

  // Columns for the sales table
  const extendedIntervals = ['24h', '24h vs Avg', '7d', '30d', 'ytd'];

  const now = new Date();
  const startOfYear = new Date(now.getFullYear(), 0, 1);
  const dayOfYear = Math.floor((now - startOfYear) / (24*3600*1000)) + 1;

  const getDailyAverages = (value7d, value30d, valueYtd) => {
    const daily7d = value7d / 7.0;
    const daily30d = value30d / 30.0;
    const dailyYtd = dayOfYear > 0 ? (valueYtd / dayOfYear) : 0;
    return { daily7d, daily30d, dailyYtd };
  };

  const formatComparison = (val24h, daily7d, daily30d, dailyYtd) => {
    if (isNaN(val24h) || isNaN(daily7d) || isNaN(daily30d) || isNaN(dailyYtd)) return 'N/A';

    const buildLine = (val24h, dailyVal, label) => {
      if (dailyVal === 0) return `No data for ${label}`;
      const diffPct = ((val24h - dailyVal) / dailyVal) * 100;
      const arrow = diffPct > 0 ? '▲' : diffPct < 0 ? '▼' : '→';
      const className = diffPct > 0 ? 'arrow-up' : diffPct < 0 ? 'arrow-down' : '';
      const sign = diffPct > 0 ? '+' : '';
      return (
        <div key={label} className={`comparison-line ${className}`}>
          {arrow} {sign}{diffPct.toFixed(1)}% vs {label} avg
        </div>
      );
    };

    return (
      <>
        {buildLine(val24h, daily7d, '7D')}
        {buildLine(val24h, daily30d, '30D')}
        {buildLine(val24h, dailyYtd, 'YTD')}
      </>
    );
  };

  // Prepare data rows for Sales table
  const intervalMetricsRows = Object.entries(interval_metrics).map(([metricName, timeData]) => {
    const row = { metricName };
    row['24h'] = timeData['24h'] !== undefined ? timeData['24h'] : 'N/A';
    row['7d'] = timeData['7d'] !== undefined ? timeData['7d'] : 'N/A';
    row['30d'] = timeData['30d'] !== undefined ? timeData['30d'] : 'N/A';
    row['ytd'] = timeData['ytd'] !== undefined ? timeData['ytd'] : 'N/A';

    if (metricName === 'Sales in ETH' || metricName === 'Sales Count') {
      const val24h = parseFloat(row['24h']);
      const val7d = parseFloat(row['7d']);
      const val30d = parseFloat(row['30d']);
      const valYtd = parseFloat(row['ytd']);
      const { daily7d, daily30d, dailyYtd } = getDailyAverages(val7d, val30d, valYtd);

      row['24h vs Avg'] = formatComparison(val24h, daily7d, daily30d, dailyYtd);
    } else {
      row['24h vs Avg'] = 'N/A';
    }

    // If it's one of the highest metrics, remove 'N/A' from the 24h vs Avg column
    if (['Highest Sale', 'Highest Revenue Card', 'Highest Sales Count Card'].includes(metricName)) {
      if (row['24h vs Avg'] === 'N/A') {
        row['24h vs Avg'] = '';
      }
    }

    return row;
  });

  // Single metrics order
  const singleMetricOrder = [
    'Holders Count',
    'Full Set Holders',
    'Hold Min One Card per SZN',
    'Lowest PPTDH'
  ];
  const singleMetricsRows = singleMetricOrder.map(metricName => ({
    metricName,
    metricValue: single_metrics[metricName] || 'N/A',
  }));

  const currentValue = historicalData?.[historicalData.length - 1];

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        },
        ticks: {
          color: 'white'
        }
      },
      x: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        },
        ticks: {
          color: 'white',
          maxRotation: 45,
          minRotation: 45
        }
      }
    },
    plugins: {
      legend: { labels: { color: 'white' } },
      title: {
        display: true,
        text: 'Comparison of Cards Under Mint Price and Total Cards Available',
        color: 'white',
        font: { size: 16 }
      },
      tooltip: {
        callbacks: {
          title: function(context) {
            const date = new Date(context[0].label);
            return date.toLocaleDateString('en-GB', {
              day: 'numeric',
              month: 'short',
              year: 'numeric'
            });
          }
        }
      }
    }
  };

  const holdersChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: false,
        grid: { color: 'rgba(255, 255, 255, 0.1)' },
        ticks: { color: 'white' }
      },
      x: {
        grid: { color: 'rgba(255, 255, 255, 0.1)' },
        ticks: { color: 'white', maxRotation: 45, minRotation: 45 }
      }
    },
    plugins: {
      legend: { labels: { color: 'white' } },
      title: {
        display: true,
        text: 'No Of Holders Holding Any 6529 Meme Card',
        color: 'white',
        font: { size: 16 }
      },
      tooltip: {
        callbacks: {
          title: function(context) {
            const date = new Date(context[0].label);
            return date.toLocaleDateString('en-GB', {
              day: 'numeric',
              month: 'short',
              year: 'numeric'
            });
          }
        }
      }
    }
  };

  const pptdhChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: false,
        grid: { color: 'rgba(255, 255, 255, 0.1)' },
        ticks: { color: 'white' },
        title: { display: true, text: 'PPTDH', color: 'white', font: { size: 12 } }
      },
      x: {
        grid: { color: 'rgba(255, 255, 255, 0.1)' },
        ticks: {
          color: 'white',
          maxRotation: 45,
          minRotation: 45,
          callback: function(value) {
            const date = new Date(this.getLabelForValue(value));
            return date.toLocaleDateString('en-GB', {
              day: 'numeric', month: 'short', year: 'numeric'
            });
          }
        },
        title: { display: true, text: 'Dates', color: 'white', font: { size: 12 } }
      }
    },
    plugins: {
      legend: { labels: { color: 'white' } },
      tooltip: {
        callbacks: {
          title: function(context) {
            const date = new Date(context[0].label);
            return date.toLocaleDateString('en-GB', {
              day: 'numeric', month: 'short', year: 'numeric'
            });
          },
          label: function(context) {
            return `PPTDH: ${context.parsed.y.toFixed(4)}`;
          }
        }
      },
      title: {
        display: true, text: 'PPTDH Over Time', color: 'white', font: { size: 16 }
      }
    }
  };

  // Modify monthlyPPTDHChartOptions tooltip to show 4 decimal places for Average Monthly PPTDH
  const monthlyPPTDHChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        type: 'linear', display: true, position: 'left',
        title: { display: true, text: 'PPTDH', color: 'white' },
        grid: { color: 'rgba(255, 255, 255, 0.1)' },
        ticks: { color: 'white' }
      },
      y1: {
        type: 'linear', display: true, position: 'right',
        title: { display: true, text: 'Number of Sales', color: 'white' },
        grid: { drawOnChartArea: false },
        ticks: { color: 'white' }
      },
      x: {
        grid: { color: 'rgba(255, 255, 255, 0.1)' },
        ticks: { color: 'white', maxRotation: 45, minRotation: 45 }
      }
    },
    plugins: {
      legend: { labels: { color: 'white' } },
      title: {
        display: true,
        text: ['Average Monthly PPTDH'],
        color: 'white',
        font: { size:16 },
        padding: { bottom: 10 }
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            if (context.dataset.label === 'Average Monthly PPTDH') {
              return `Average Monthly PPTDH: ${context.parsed.y.toFixed(4)}`;
            } else {
              return `Number of Sales: ${context.parsed.y}`;
            }
          }
        }
      }
    }
  };

  // TDH Chart Options
  const tdhChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        grid: { color: 'rgba(255, 255, 255, 0.1)' },
        ticks: { 
          color: 'white',
          callback: function(value) {
            return value + '%';
          }
        },
        title: { display: true, text: 'Percentage of Total TDH', color: 'white', font: { size: 12 } }
      },
      x: {
        grid: { color: 'rgba(255, 255, 255, 0.1)' },
        ticks: {
          color: 'white',
          maxRotation: 45,
          minRotation: 45
        },
        title: { display: true, text: 'Date', color: 'white', font: { size: 12 } }
      }
    },
    plugins: {
      legend: { 
        labels: { 
          color: 'white',
          boxWidth: 40,
          padding: 15,
          font: {
            size: 11
          }
        },
        position: 'top',
        align: 'start'
      },
      title: {
        display: true,
        text: '6529 Ecosystem TDH Percentage',
        color: 'white',
        font: { size: 16 },
        padding: { bottom: 20 }
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            return `${context.dataset.label}: ${context.parsed.y}%`;
          }
        }
      }
    }
  };

  return (
    <div className="insights-container">
      <Helmet>
        <title>6529 Memes NFT Insights | Sales, Holder & TDH Analytics</title>
        <meta 
          name="description" 
          content="6529 Memes NFT Insights and Analytics Dashboard. Explore trends in ownership, price efficiency, and sales through detailed graphs & stats"
        />
        <meta 
          property="og:title" 
          content="6529 Memes NFT Insights | Sales, Holder & TDH Analytics"
        />
        <meta 
          property="og:description" 
          content="6529 Memes NFT Insights and Analytics Dashboard. Explore trends in ownership, price efficiency, and sales through detailed graphs & stats"
        />
        <meta property="og:image" content="https://6529stats.xyz/share-image.png" />
        <meta property="og:url" content="https://6529stats.xyz/insights" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta 
          name="twitter:title" 
          content="6529 Memes NFT Insights | Sales, Holder & TDH Analytics"
        />
        <meta 
          name="twitter:description" 
          content="6529 Memes NFT Insights and Analytics Dashboard. Explore trends in ownership, price efficiency, and sales through detailed graphs & stats"
        />
        <meta name="twitter:image" content="https://6529stats.xyz/share-image.png" />
      </Helmet>

      <h1 className="main-header">6529 Memes NFT Analytics – Sales, Holders & TDH Trends & Insights</h1>

      {/* Sales Table */}
      <h2 className="section-header">Sales</h2>
      <table className="metrics-table">
        <thead>
          <tr>
            <th>Metric</th>
            {extendedIntervals.map(interval => (
              <th key={interval}>{interval.toUpperCase()}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {intervalMetricsRows.map((row, index) => (
            <tr key={index} className={index % 2 !== 0 ? 'even-row' : ''}>
              <td className="metric-name-cell">{row.metricName}</td>
              <td>{row['24h']}</td>
              <td>{row['24h vs Avg']}</td>
              <td>{row['7d']}</td>
              <td>{row['30d']}</td>
              <td>{row['ytd']}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Hodlers & PPTDH Table */}
      <h2 className="section-header">Hodlers & PPTDH</h2>
      <table className="metrics-table">
        <thead>
          <tr>
            <th>Metric</th>
            <th>24h</th>
            <th>7d</th>
            <th>30d</th>
          </tr>
        </thead>
        <tbody>
          {singleMetricsRows.map((row, index) => {
            if (row.metricName === 'Lowest PPTDH') {
              return (
                <tr key={index} className={index % 2 !== 0 ? 'even-row' : ''}>
                  <td className="metric-name-cell">{row.metricName}</td>
                  <td colSpan="3">
                    {Array.isArray(row.metricValue) ? (
                      <div className="pptdh-values">
                        {row.metricValue.map((item, idx) => (
                          <div key={idx}>
                            <strong>Card No: {item.ID}</strong> - {item.Name} (PPTDH: {item.PPTDH})
                          </div>
                        ))}
                      </div>
                    ) : (
                      row.metricValue
                    )}
                  </td>
                </tr>
              );
            }

            const currentVal = currentValue ? (
              row.metricName === 'Holders Count' ? currentValue.holders_any_tokenid :
              row.metricName === 'Full Set Holders' ? currentValue.holders_all_tokenid :
              row.metricName === 'Hold Min One Card per SZN' ? currentValue.holders_any_token_each_season :
              null
            ) : null;

            const day = getHistoricalValue(row.metricName, 1);
            const week = getHistoricalValue(row.metricName, 7);
            const month = getHistoricalValue(row.metricName, 30);

            return (
              <tr key={index} className={index % 2 !== 0 ? 'even-row' : ''}>
                <td className="metric-name-cell">{row.metricName}</td>
                <td>{(day && currentVal) ? calculateChange(currentVal, day) : 'N/A'}</td>
                <td>{(week && currentVal) ? calculateChange(currentVal, week) : 'N/A'}</td>
                <td>{(month && currentVal) ? calculateChange(currentVal, month) : 'N/A'}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Swap the order of these two charts: 
          Previously: Under Mint Price, then Holders Chart
          Now: Holders Chart first, then Under Mint Price */}
      
      {/* Holders Chart */}
      <div className="chart-section">
        <h2 className="chart-section-title">Number of Holders Over Time</h2>
        <div className="chart-container">
          {holdersChartData && <Line data={holdersChartData} options={holdersChartOptions} />}
        </div>
      </div>

      {/* Under Mint Price Chart */}
      <div className="chart-section">
        <h2 className="chart-section-title">Cards Under Mint Price vs Total Cards Available</h2>
        <div className="chart-container">
          {chartData && <Line data={chartData} options={chartOptions} />}
        </div>
      </div>

      {/* PPTDH Chart */}
      <div className="chart-section">
        <h2 className="chart-section-title">Lowest PPTDH Over Time</h2>
        <div className="chart-container">
          {pptdhChartData && <Line data={pptdhChartData} options={pptdhChartOptions} />}
        </div>
      </div>

      {/* Monthly PPTDH Chart with 4 decimal places for Average Monthly PPTDH */}
      {monthlyPPTDHData && (
        <div className="chart-section">
          <h2 className="chart-section-title">Monthly Average PPTDH based on Secondary Sales</h2>
          <div className="chart-container">
            <Line data={monthlyPPTDHData} options={monthlyPPTDHChartOptions} />
          </div>
        </div>
      )}

      {/* 6529 TDH Percentage Chart */}
      {tdhChartData && (
        <div className="chart-section">
          <h2 className="chart-section-title">6529 Ecosystem TDH Percentage</h2>
          <div className="chart-container">
            <Line data={tdhChartData} options={tdhChartOptions} />
          </div>
          <div className="chart-notes">
            <p><strong>Note:</strong> The addresses represented in this chart are:</p>
            <ul>
              <li><strong>social.6529.eth,trove.punk6529.eth,punk6529:</strong> 0x6daa633c23615a29471deafae351727867e7dad1-0xa094ca13e855c3e926f9069ee140602ace421fe3-0xfd22004806a6846ea67ad883356be810f0428793</li>
              <li><strong>research.6529.eth:</strong> 0x32b8bce7cf8c6203a841b0d08bde5ae89f0af103-0xc2ce4ccef11a8171f443745cea3bceeaadd750c7</li>
              <li><strong>6529Museum:</strong> 0x924817154d311bd2de599f3d659d053ab0133e06-0xc6400a5584db71e41b0e5dfbdc769b54b91256cd</li>
            </ul>
          </div>
        </div>
      )}

      {/* Contact section */}
      <div className="contact-section">
        <p>If you would like to see any other collection metrics here, please contact us.</p>
      </div>
    </div>
  );
};

export default InsightsComponent;
