import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import './HomeComponent.css';
import { Link } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { fetchOwnersAggregatedStatsHistorical, getCompactImageUrl } from './apiService';
import debounce from 'lodash/debounce';

// Register ChartJS components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const handleSearchChange = debounce(async (value, setSearchTerm, setSuggestions, setSelectedSuggestion) => {
    setSearchTerm(value);
    if (!value.trim()) {
        setSuggestions([]);
        return;
    }

    try {
        const response = await fetch(`/api/search-cards?query=${encodeURIComponent(value)}`);
        const data = await response.json();
        setSuggestions(data);
        setSelectedSuggestion(data[0]); // Select first suggestion by default
    } catch (error) {
        console.error('Error fetching suggestions:', error);
        setSuggestions([]);
    }
}, 300);

function HomeComponent() {
    const [featuredTokens, setFeaturedTokens] = useState(null);
    const [holdersChartData, setHoldersChartData] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [selectedSuggestion, setSelectedSuggestion] = useState(null);
    const [holderInput, setHolderInput] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [featuredResponse, historicalResponse] = await Promise.all([
                    fetch('https://api.6529stats.xyz/api/homepage'),
                    fetchOwnersAggregatedStatsHistorical()
                ]);
                
                const featuredData = await featuredResponse.json();
                setFeaturedTokens(featuredData);

                // Process holders data
                const holdersDates = historicalResponse.data.map(item => 
                    new Date(item.fetch_timestamp).toLocaleDateString()
                );
                const holdersCounts = historicalResponse.data.map(item => 
                    item.holders_any_tokenid
                );

                setHoldersChartData({
                    labels: holdersDates,
                    datasets: [{
                        label: 'Holders Count',
                        data: holdersCounts,
                        borderColor: 'red',
                        backgroundColor: 'red',
                        pointRadius: 3,
                        tension: 0.1
                    }]
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const holdersChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: false,
                min: 9700,
                max: 10000,
                grid: { color: 'rgba(255, 255, 255, 0.1)' },
                ticks: { color: 'white' }
            },
            x: {
                grid: { color: 'rgba(255, 255, 255, 0.1)' },
                ticks: { 
                    color: 'white', 
                    maxRotation: 45, 
                    minRotation: 45 
                }
            }
        },
        plugins: {
            legend: { labels: { color: 'white' } },
            title: {
                display: true,
                text: 'No Of Holders Holding Any 6529 Meme Card',
                color: 'white',
                font: { size: 16 }
            },
            tooltip: {
                callbacks: {
                    title: function(context) {
                        const date = new Date(context[0].label);
                        return date.toLocaleDateString('en-GB', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric'
                        });
                    }
                }
            }
        }
    };

    const renderTokenInfo = (token) => (
        <div className="featured-token-info">
            <div className="token-details">
                <h4 className="token-name">{token.name}</h4>
                <div className="token-metadata">
                    <span>Card {token.tokenId}</span>
                    <span className="separator">|</span>
                    <span>Season {token.szn}</span>
                    <span className="separator">|</span>
                    <span>Artist: {token.artist}</span>
                </div>
            </div>
        </div>
    );

    return (
        <div className="home-main">
            <Helmet>
                <title>6529 Memes NFT Analytics | Real-time Prices & Market Valuations</title>
                <meta 
                    name="description" 
                    content="Track real-time 6529 Memes NFT prices, sales data, and holder analytics. Get personalised portfolio insights and market valuations for the complete collection."
                />
                <meta 
                    property="og:title" 
                    content="6529 Memes NFT Analytics | Real-time Prices & Market Valuations"
                />
                <meta 
                    property="og:description" 
                    content="Track real-time 6529 Memes NFT prices, sales data, and holder analytics. Get personalised portfolio insights and market valuations for the complete collection."
                />
                <meta property="og:image" content="https://6529stats.xyz/share-image.png" />
                <meta property="og:url" content="https://6529stats.xyz/" />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta 
                    name="twitter:title" 
                    content="6529 Memes NFT Analytics | Real-time Prices & Market Valuations"
                />
                <meta 
                    name="twitter:description" 
                    content="Track real-time 6529 Memes NFT prices, sales data, and holder analytics. Get personalised portfolio insights and market valuations for the complete collection."
                />
                <meta name="twitter:image" content="https://6529stats.xyz/share-image.png" />
            </Helmet>
            <div className="home-content">
                <h1 className="home-title">6529 Memes NFT Collection Stats and Analytics</h1>

                <div className="home-kpi-container">
                    <Link to="/prices" className="home-kpi-box">
                        <div className="kpi-content">
                            <h2>Prices</h2>
                            <p>Current 6529 Prices</p>
                        </div>
                    </Link>

                    <Link to="/hodlers" className="home-kpi-box">
                        <div className="kpi-content">
                            <h2>Hodlers</h2>
                            <p>Explore 6529 NFT Hodlers</p>
                        </div>
                    </Link>

                    <Link to="/sales-all-time" className="home-kpi-box">
                        <div className="kpi-content">
                            <h2>Sales</h2>
                            <p>6529 Sales History</p>
                        </div>
                    </Link>

                    <Link to="/portfolio" className="home-kpi-box">
                        <div className="kpi-content">
                            <h2>Portfolio</h2>
                            <p>View Your 6529 Portfolio</p>
                        </div>
                    </Link>
                </div>

                {featuredTokens && (
                    <div className="featured-tokens-section">
                        <div className="featured-tokens-container">
                            {featuredTokens.topSeller && (
                                <div className="featured-token-card">
                                    <h3 className="featured-token-title">Top Seller By Count Last 7 Days</h3>
                                    <div className="featured-token-content">
                                        <Link 
                                            to={`/token/${featuredTokens.topSeller.tokenId}`} 
                                            className="token-link"
                                        >
                                            <div className="featured-token-image-container">
                                                <img 
                                                    src={getCompactImageUrl(featuredTokens.topSeller) || featuredTokens.topSeller.image} 
                                                    alt={featuredTokens.topSeller.name}
                                                    className="featured-token-image"
                                                />
                                            </div>
                                            {renderTokenInfo(featuredTokens.topSeller)}
                                        </Link>
                                        <div className="price-info">
                                            <div className="price-item">
                                                <span className="price-label">Top Bid</span>
                                                <span className="price-value">{featuredTokens.topSeller.prices.highestBid.toFixed(2)}</span>
                                            </div>
                                            <div className="price-item">
                                                <span className="price-label">Floor Price</span>
                                                <span className="price-value">{featuredTokens.topSeller.prices.floorPrice.toFixed(2)}</span>
                                            </div>
                                            <div className="price-item">
                                                <span className="price-label">Last Sale</span>
                                                <span className="price-value">{featuredTokens.topSeller.prices.lastSalePrice.toFixed(2)}</span>
                                            </div>
                                            <div className="price-item">
                                                <span className="price-label">7d Sales</span>
                                                <span className="price-value">{featuredTokens.topSeller.sales.count}x ({featuredTokens.topSeller.sales.totalValue.toFixed(2)})</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {featuredTokens.recentMint && (
                                <div className="featured-token-card">
                                    <h3 className="featured-token-title">Most Recently Minted Card</h3>
                                    <div className="featured-token-content">
                                        <Link 
                                            to={`/token/${featuredTokens.recentMint.tokenId}`} 
                                            className="token-link"
                                        >
                                            <div className="featured-token-image-container">
                                                <img 
                                                    src={getCompactImageUrl(featuredTokens.recentMint) || featuredTokens.recentMint.image} 
                                                    alt={featuredTokens.recentMint.name}
                                                    className="featured-token-image"
                                                />
                                            </div>
                                            {renderTokenInfo(featuredTokens.recentMint)}
                                        </Link>
                                        <div className="price-info price-info-three">
                                            <div className="price-item">
                                                <span className="price-label">Top Bid</span>
                                                <span className="price-value">{featuredTokens.recentMint.prices.highestBid.toFixed(2)}</span>
                                            </div>
                                            <div className="price-item">
                                                <span className="price-label">Floor Price</span>
                                                <span className="price-value">{featuredTokens.recentMint.prices.floorPrice.toFixed(2)}</span>
                                            </div>
                                            <div className="price-item">
                                                <span className="price-label">Last Sale</span>
                                                <span className="price-value">{featuredTokens.recentMint.prices.lastSalePrice.toFixed(2)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {/* Holders Chart Section */}
                <div className="chart-section">
                    <h2 className="chart-section-title">Number of Holders Over Time</h2>
                    <div className="chart-container">
                        {holdersChartData && <Line data={holdersChartData} options={holdersChartOptions} />}
                    </div>
                </div>

                {/* Low Available Supply Section */}
                {featuredTokens && featuredTokens.lowAvailableSupply && (
                    <div className="low-supply-section">
                        <div className="low-supply-card">
                            <h2 className="section-title">Cards With Low Available Supply</h2>
                            <p className="section-description">
                                These cards have 50 or fewer tokens available when accounting for set holders and 6529 team holdings.
                            </p>
                            <div className="featured-tokens-container">
                                {featuredTokens.lowAvailableSupply.map((card) => (
                                    <div key={card.tokenId} className="featured-token-card">
                                        <div className="featured-token-content">
                                            <Link 
                                                to={`/token/${card.tokenId}`} 
                                                className="token-link"
                                            >
                                                <div className="featured-token-image-container">
                                                    <img 
                                                        src={getCompactImageUrl(card) || card.image} 
                                                        alt={card.name}
                                                        className="featured-token-image"
                                                    />
                                                </div>
                                                {renderTokenInfo(card)}
                                            </Link>
                                            <div className="price-info">
                                                <div className="price-item">
                                                    <span className="price-label">Supply</span>
                                                    <span className="price-value">{card.supply}</span>
                                                </div>
                                                <div className="price-item">
                                                    <span className="price-label">Held by 6529</span>
                                                    <span className="price-value">{card.no6529owned}</span>
                                                </div>
                                                <div className="price-item">
                                                    <span className="price-label">Full Set Hodlers</span>
                                                    <span className="price-value">{card.no_of_full_set_holders}</span>
                                                </div>
                                                <div className="price-item">
                                                    <span className="price-label">SZN Set Hodlers</span>
                                                    <span className="price-value">{card.no_of_szn_set_holders}</span>
                                                </div>
                                            </div>
                                            <div className="price-info">
                                                <div className="price-item">
                                                    <span className="price-label">Available Supply</span>
                                                    <span className="price-value">{card.supply_remaining_if_full_and_szn_set_holders_keep1} ({card.percentage_supply_remaining_if_full_and_szn_set_holders_keep1}%)</span>
                                                </div>
                                                <div className="price-item">
                                                    <span className="price-label">Top Bid</span>
                                                    <span className="price-value">{card.prices.highestBid.toFixed(3)}</span>
                                                </div>
                                                <div className="price-item">
                                                    <span className="price-label">Floor Price</span>
                                                    <span className="price-value">{card.prices.floorPrice.toFixed(3)}</span>
                                                </div>
                                                <div className="price-item">
                                                    <span className="price-label">Last Sale</span>
                                                    <span className="price-value">{card.prices.lastSalePrice.toFixed(3)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="price-bands-cta">
                                <a href="https://6529stats.xyz/hodlers/supply-enhanced" target="_blank" rel="noopener noreferrer" className="view-all-prices-btn">
                                    View Supply Metrics For All Cards
                                    <i className="fas fa-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                )}

                {/* Supply Enhanced Section */}
                <div className="supply-enhanced-section">
                    <div className="supply-enhanced-card">
                        <Link to="/hodlers/supply-enhanced" className="supply-enhanced-content-wrapper">
                            <div className="supply-enhanced-content">
                                <h2>Supply Enhanced Analysis</h2>
                                <div className="supply-enhanced-description">
                                    <p>Discover cards with potential low availability based on detailed hodler statistics. View the supply breakdown and hodlers profile for each card, including:</p>
                                    <ul>
                                        <li>6529 Holdings</li>
                                        <li>Set and SZN Set Hodlers</li>
                                        <li>Available Supply Metrics</li>
                                    </ul>
                                    <div className="supply-enhanced-cta">
                                        <span>Explore Supply Analysis For All Cards</span>
                                        <i className="fas fa-arrow-right"></i>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div className="supply-enhanced-search">
                            <p>For a detailed analysis on any specific card type the name or number here</p>
                            <div className="search-container">
                                <input
                                    type="text"
                                    placeholder="Search by card name or number..."
                                    onChange={(e) => handleSearchChange(e.target.value, setSearchTerm, setSuggestions, setSelectedSuggestion)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' && selectedSuggestion) {
                                            window.location.href = `https://6529stats.xyz/token/${selectedSuggestion.ID}/owners`;
                                        }
                                    }}
                                    value={searchTerm}
                                />
                                {suggestions.length > 0 && searchTerm && (
                                    <div className="suggestions-dropdown">
                                        {suggestions.map((suggestion) => (
                                            <div
                                                key={suggestion.ID}
                                                className="suggestion-item"
                                                onClick={() => {
                                                    window.location.href = `https://6529stats.xyz/token/${suggestion.ID}/owners`;
                                                }}
                                            >
                                                <span className="suggestion-id">#{suggestion.ID}</span>
                                                <span className="suggestion-name">{suggestion.Name}</span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* House Artist Section */}
                <div className="house-artist-section">
                    <h2 className="section-title">
                        Meet the House Artist <a href="https://6529.io/6529er/identity" target="_blank" rel="noopener noreferrer" className="artist-link">6529er</a>
                    </h2>
                    <p className="artist-description">
                        6529er is the house artist within the 6529 ecosystem and as well as creating the{' '}
                        <a href="https://6529.io/6529-gradient?sort=id&sort_dir=ASC" target="_blank" rel="noopener noreferrer">
                            Super Gradient Collection
                        </a>{' '}
                        he is responsible for some of the finest meme cards.
                    </p>
                    <p className="artist-description">
                        None more so than the collection's original Genesis set as well the collection's premium card Nakamoto Freedom.
                    </p>

                    <div className="featured-tokens-container">
                        {featuredTokens && featuredTokens.genesisCards && featuredTokens.genesisCards.map((card) => (
                            <div key={card.tokenId} className="featured-token-card">
                                <div className="featured-token-content">
                                    <Link 
                                        to={`/token/${card.tokenId}`} 
                                        className="token-link"
                                    >
                                        <div className="featured-token-image-container">
                                            <img 
                                                src={getCompactImageUrl(card) || card.image} 
                                                alt={card.name}
                                                className="featured-token-image"
                                            />
                                        </div>
                                        <div className="featured-token-info">
                                            <div className="token-details">
                                                <h4 className="token-name">{card.name}</h4>
                                                <div className="token-metadata">
                                                    <span>Card {card.tokenId}</span>
                                                    <span className="separator">|</span>
                                                    <span>Season {card.szn}</span>
                                                    <span className="separator">|</span>
                                                    <span>Artist: {card.artist}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="price-info">
                                        <div className="price-item">
                                            <span className="price-label">Top Bid</span>
                                            <span className="price-value">{card.prices.highestBid.toFixed(2)}</span>
                                        </div>
                                        <div className="price-item">
                                            <span className="price-label">Floor Price</span>
                                            <span className="price-value">{card.prices.floorPrice.toFixed(2)}</span>
                                        </div>
                                        <div className="price-item">
                                            <span className="price-label">Last Sale</span>
                                            <span className="price-value">{card.prices.lastSalePrice.toFixed(2)}</span>
                                        </div>
                                        <div className="price-item">
                                            <span className="price-label">Sales To Date</span>
                                            <span className="price-value small">{card.sales.count}x ({card.sales.totalValue.toFixed(2)})</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Holder Profile Section */}
                <div className="holder-profile-section">
                    <div className="holder-profile-card">
                        <h2>Explore Holder Profiles</h2>
                        <div className="holder-profile-description">
                            <p>
                                Discover detailed breakdowns of 6529 ecosystem holdings for any holder. Each profile includes:
                            </p>
                            <ul>
                                <li>Complete portfolio analysis across 6529 Memes and Next Gen Pebbles</li>
                                <li>External holdings in Mfers and Rektguy collections</li>
                                <li>Aggregated data by collection and season</li>
                                <li>Individual card details with current market prices</li>
                                <li>Real-time portfolio valuations</li>
                            </ul>
                            <p className="profile-note">
                                Note: Profiles are available for any wallet holding at least one 6529 Meme card
                            </p>
                        </div>
                        <div className="holder-search">
                            <p>Enter a 6529 profile name or wallet address to view their holdings</p>
                            <div className="search-container">
                                <input
                                    type="text"
                                    placeholder="Enter profile name or wallet address..."
                                    value={holderInput}
                                    onChange={(e) => setHolderInput(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' && holderInput.trim()) {
                                            window.open(`https://6529stats.xyz/hodlers/${holderInput.trim()}`, '_blank');
                                        }
                                    }}
                                />
                                <button 
                                    className="view-profile-btn"
                                    onClick={() => {
                                        if (holderInput.trim()) {
                                            window.open(`https://6529stats.xyz/hodlers/${holderInput.trim()}`, '_blank');
                                        }
                                    }}
                                >
                                    View Profile
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* PPTDH Section */}
                {featuredTokens && featuredTokens.pptdh && (
                    <div className="pptdh-section">
                        <div className="pptdh-card">
                            <h2 className="section-title">PPTDH Under 0.005</h2>
                            <p className="section-description">
                                Cards with a low PPTDH under 0.005.
                            </p>
                            <div className="featured-tokens-container">
                                {featuredTokens.pptdh.map((card) => (
                                    <div key={card.tokenId} className="featured-token-card">
                                        <div className="featured-token-content">
                                            <Link 
                                                to={`/token/${card.tokenId}`} 
                                                className="token-link"
                                            >
                                                <div className="featured-token-image-container">
                                                    <img 
                                                        src={getCompactImageUrl(card) || card.image} 
                                                        alt={card.name}
                                                        className="featured-token-image"
                                                    />
                                                </div>
                                                {renderTokenInfo(card)}
                                            </Link>
                                            <div className="price-info">
                                                <div className="price-item">
                                                    <span className="price-label">PPTDH</span>
                                                    <span className="price-value">{card.pptdh.toFixed(4)}</span>
                                                </div>
                                                <div className="price-item">
                                                    <span className="price-label">Top Bid</span>
                                                    <span className="price-value">{card.prices.highestBid.toFixed(3)}</span>
                                                </div>
                                                <div className="price-item">
                                                    <span className="price-label">Floor Price</span>
                                                    <span className="price-value">{card.prices.floorPrice.toFixed(3)}</span>
                                                </div>
                                                <div className="price-item">
                                                    <span className="price-label">Last Sale</span>
                                                    <span className="price-value">{card.prices.lastSalePrice.toFixed(3)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="price-bands-cta">
                                <a href="https://6529stats.xyz/prices" target="_blank" rel="noopener noreferrer" className="view-all-prices-btn">
                                    View All Cards & Prices
                                    <i className="fas fa-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                )}

                {/* Pebbles Section */}
                <div className="pebbles-section">
                    <div className="pebbles-card">
                        <h2>NextGen Pebbles Collection</h2>
                        <div className="pebbles-description">
                            <p>
                                NextGen is an on-chain generative art NFT contract and Pebbles created by{' '}
                                <a href="https://6529.io/zeblocks/identity" target="_blank" rel="noopener noreferrer" className="artist-link">
                                    Zeblocks
                                </a>{' '}
                                was the first collection launched using it.
                            </p>
                            <p>
                                It launched in February 2024 and the 1000 Pebbles were minted for 0.06529 each. Learn more about the{' '}
                                <a href="https://6529.io/nextgen/about" target="_blank" rel="noopener noreferrer">
                                    6529 NextGen Contract
                                </a>
                            </p>
                        </div>
                        
                        {featuredTokens && featuredTokens.pebbles && (
                            <div className="pebbles-grid">
                                {featuredTokens.pebbles.map((pebble) => (
                                    <div key={pebble.tokenId} className="featured-token-card">
                                        <div className="featured-token-content">
                                            <a 
                                                href={`https://6529.io/nextgen/token/${pebble.tokenId}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="token-link"
                                            >
                                                <div className="featured-token-image-container">
                                                    <img 
                                                        src={getCompactImageUrl(pebble) || pebble.image} 
                                                        alt={pebble.name}
                                                        className="featured-token-image"
                                                    />
                                                </div>
                                                <div className="featured-token-info">
                                                    <div className="token-details">
                                                        <h4 className="token-name">{pebble.name}</h4>
                                                        <div className="token-metadata">
                                                            <span>Token {pebble.tokenId}</span>
                                                            <span className="separator">|</span>
                                                            <span>Palette: {pebble.palette}</span>
                                                            <span className="separator">|</span>
                                                            <span>Artist: {pebble.artist}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                            <div className="price-info">
                                                <div className="price-item">
                                                    <span className="price-label">Top Bid</span>
                                                    <span className="price-value">{pebble.prices.highestBid.toFixed(3) || 'None'}</span>
                                                </div>
                                                <div className="price-item">
                                                    <span className="price-label">Token Floor Price</span>
                                                    <span className="price-value">{pebble.prices.floorPrice ? pebble.prices.floorPrice.toFixed(3) : 'Not Listed'}</span>
                                                </div>
                                                <div className="price-item">
                                                    <span className="price-label">Last Sale</span>
                                                    <span className="price-value">{pebble.prices.lastSalePrice ? pebble.prices.lastSalePrice.toFixed(3) : 'None'}</span>
                                                </div>
                                                <div className="price-item">
                                                    <span className="price-label">Collection Floor</span>
                                                    <span className="price-value">{pebble.prices.collectionFloor.toFixed(3)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className="price-bands-cta">
                            <a href="https://6529stats.xyz/nextgen/pebbles/prices" target="_blank" rel="noopener noreferrer" className="view-all-prices-btn">
                                View All Pebbles
                                <i className="fas fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>

                {/* Price Bands Section - moved to end */}
                {featuredTokens && featuredTokens.priceBandedCards && (
                    <div className="price-bands-section">
                        <div className="price-bands-card">
                            <h2>Explore Cards By Price Range</h2>
                            <p className="price-bands-description">
                                Discover the diverse range of 6529 Meme cards across different price points. 
                                From entry-level collectibles to premium pieces, there's something for every collector.
                            </p>
                            
                            <div className="price-bands-container">
                                {featuredTokens.priceBandedCards.map((band) => (
                                    <div key={band.label} className="price-band">
                                        <h3 className="price-band-title">{band.label}</h3>
                                        <div className="price-band-cards">
                                            {band.cards.map((card) => (
                                                <div key={card.tokenId} className="featured-token-card">
                                                    <div className="featured-token-content">
                                                        <Link 
                                                            to={`/token/${card.tokenId}`} 
                                                            className="token-link"
                                                        >
                                                            <div className="featured-token-image-container">
                                                                <img 
                                                                    src={getCompactImageUrl(card) || card.image} 
                                                                    alt={card.name}
                                                                    className="featured-token-image"
                                                                />
                                                            </div>
                                                            {renderTokenInfo(card)}
                                                        </Link>
                                                        <div className="price-info price-info-three">
                                                            <div className="price-item">
                                                                <span className="price-label">Top Bid</span>
                                                                <span className="price-value">{card.prices.highestBid.toFixed(3)}</span>
                                                            </div>
                                                            <div className="price-item">
                                                                <span className="price-label">Floor Price</span>
                                                                <span className="price-value">{card.prices.floorPrice.toFixed(3)}</span>
                                                            </div>
                                                            <div className="price-item">
                                                                <span className="price-label">Last Sale</span>
                                                                <span className="price-value">{card.prices.lastSalePrice.toFixed(3)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            
                            <div className="price-bands-cta">
                                <a href="https://6529stats.xyz/prices" target="_blank" rel="noopener noreferrer" className="view-all-prices-btn">
                                    View All Cards & Prices
                                    <i className="fas fa-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default HomeComponent; 