import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { ArrowUpDown, Download } from 'lucide-react';
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import './ArtistComponent.css';
import { fetchArtistData, getArtworkStreamUrl, getCompactImageUrl } from './apiService';
import seizeIcon from './assets/seizeIcon.png';
import openseaIcon from './assets/openseaIcon.png';

function ArtistComponent() {
    const [artistData, setArtistData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [sortConfig, setSortConfig] = useState({ key: 'ID', direction: 'asc' });
    const [artistSearch, setArtistSearch] = useState('');
    const [isDownloading, setIsDownloading] = useState(false);
    const [selectedSeason, setSelectedSeason] = useState('');
    const [loading, setLoading] = useState(true);

    const SEASONS = [
        { value: 'SZN1', label: 'Season 1 (Cards 1-47)' },
        { value: 'SZN2', label: 'Season 2 (Cards 48-86)' },
        { value: 'SZN3', label: 'Season 3 (Cards 87-118)' },
        { value: 'SZN4', label: 'Season 4 (Cards 119-151)' },
        { value: 'SZN5', label: 'Season 5 (Cards 152-180)' },
        { value: 'SZN6', label: 'Season 6 (Cards 181-212)' },
        { value: 'SZN7', label: 'Season 7 (Cards 213-245)' },
        { value: 'SZN8', label: 'Season 8 (Cards 246-278)' },
        { value: 'SZN9', label: 'Season 9 (Cards 279-310)' },
        { value: 'SZN10', label: 'Season 10 (Cards 311+)' }
    ];

    const itemsPerPage = 25;

    // Format date
    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
    };

    // Fetch data function wrapped in useCallback
    const fetchDataWithParams = useCallback(async () => {
        try {
            setLoading(true);
            const response = await fetchArtistData({
                page: currentPage + 1,
                pageSize: itemsPerPage,
                sortKey: sortConfig.key,
                sortDirection: sortConfig.direction,
                artistSearch: artistSearch
            });
            setArtistData(response.data);
            setTotalRecords(response.totalCount);
        } catch (error) {
            console.error('Error fetching artist data:', error);
        } finally {
            setLoading(false);
        }
    }, [currentPage, itemsPerPage, sortConfig, artistSearch]);

    // Fetch data when filters, sorting, or pagination changes
    useEffect(() => {
        fetchDataWithParams();
    }, [fetchDataWithParams]);

    // Handle sorting
    const handleSort = (key) => {
        setSortConfig(prevConfig => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
        }));
        setCurrentPage(0);
    };

    // Handle page change
    const handlePageClick = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
        window.scrollTo(0, 0);
    };

    // Handle search
    const handleSearch = (e) => {
        e.preventDefault();
        setCurrentPage(0);
        fetchDataWithParams();
    };

    // Handle clear
    const handleClear = () => {
        setArtistSearch('');
        setCurrentPage(0);
    };

    // Handle download
    const handleDownload = async () => {
        if (!selectedSeason) {
            alert('Please select a season to download');
            return;
        }

        try {
            setIsDownloading(true);
            
            const response = await axios.get(`/api/download-meme-cards/${selectedSeason}`, {
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `meme-cards-${selectedSeason.toLowerCase()}.zip`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);

        } catch (error) {
            console.error('Error downloading files:', error);
            alert('Failed to download files. Please try again.');
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <div className="artist-main">
            <Helmet>
                <title>6529 Memes NFT Library | Browse, Download & Discover</title>
                <meta 
                    name="description" 
                    content="Browse the full 6529 Memes NFT collection. View artwork, explore artist profiles, and download high-quality images or videos of your favorite NFTs."
                />
                <meta 
                    property="og:title" 
                    content="6529 Memes NFT Library | Browse, Download & Discover"
                />
                <meta 
                    property="og:description" 
                    content="Browse the full 6529 Memes NFT collection. View artwork, explore artist profiles, and download high-quality images or videos of your favorite NFTs."
                />
                <meta property="og:image" content="https://6529stats.xyz/share-image.png" />
                <meta property="og:url" content="https://6529stats.xyz/artists" />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta 
                    name="twitter:title" 
                    content="6529 Memes NFT Library | Browse, Download & Discover"
                />
                <meta 
                    name="twitter:description" 
                    content="Browse the full 6529 Memes NFT collection. View artwork, explore artist profiles, and download high-quality images or videos of your favorite NFTs."
                />
                <meta name="twitter:image" content="https://6529stats.xyz/share-image.png" />
            </Helmet>

            <h1 className="artist-title">6529 Memes NFT Library | Browse, Download & Discover</h1>

            {/* Combined Search and Download Row */}
            <form className="artist-filters-form" onSubmit={handleSearch}>
                <div className="artist-filters-row">
                    {/* Search Section */}
                    <div className="artist-filter-item">
                        <label>Search Artist</label>
                        <input
                            type="text"
                            value={artistSearch}
                            onChange={(e) => setArtistSearch(e.target.value)}
                            placeholder="Enter artist name..."
                        />
                    </div>

                    {/* Download Section */}
                    <div className="artist-download-section">
                        <select 
                            value={selectedSeason}
                            onChange={(e) => setSelectedSeason(e.target.value)}
                            className="artist-season-select"
                            disabled={isDownloading}
                        >
                            <option value="">Select Season...</option>
                            {SEASONS.map(season => (
                                <option key={season.value} value={season.value}>
                                    {season.label}
                                </option>
                            ))}
                        </select>
                        <button 
                            className="artist-download-button"
                            onClick={handleDownload}
                            disabled={isDownloading || !selectedSeason}
                            type="button"
                            style={{ 
                                backgroundColor: 'var(--artist-accent)',
                                opacity: (isDownloading || !selectedSeason) ? 0.7 : 1
                            }}
                        >
                            {isDownloading ? (
                                <>
                                    <ClipLoader size={20} color="#ffffff" />
                                    <span>Downloading...</span>
                                </>
                            ) : (
                                <>
                                    <Download size={20} />
                                    <span>Download Season</span>
                                </>
                            )}
                        </button>
                    </div>
                </div>

                {/* Action buttons and record count */}
                <div className="artist-action-buttons-row">
                    <div className="artist-buttons-group">
                        <button type="submit">Search</button>
                        <button type="button" onClick={handleClear}>Clear</button>
                    </div>
                    <span className="artist-records-count">
                        <strong>Number of Records:</strong> {totalRecords}
                    </span>
                </div>
            </form>

            {/* Table */}
            {loading ? (
                <div className="artist-loading">
                    <ClipLoader size={50} color="#ff0000" />
                    <p>Loading data...</p>
                </div>
            ) : (
                <table className="artist-table">
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('ID')}>
                                <div className="artist-header-content">
                                    Card No
                                    <ArrowUpDown className={`artist-sort-icon ${sortConfig.key === 'ID' ? (sortConfig.direction === 'asc' ? 'artist-sorted-asc' : 'artist-sorted-desc') : ''}`} />
                                </div>
                            </th>
                            <th onClick={() => handleSort('Artist')}>
                                <div className="artist-header-content">
                                    Artist
                                    <ArrowUpDown className={`artist-sort-icon ${sortConfig.key === 'Artist' ? (sortConfig.direction === 'asc' ? 'artist-sorted-asc' : 'artist-sorted-desc') : ''}`} />
                                </div>
                            </th>
                            <th>Artist Profile</th>
                            <th>Art</th>
                            <th>Art Download</th>
                            <th>Card Name</th>
                            <th onClick={() => handleSort('TypeSeason')}>
                                <div className="artist-header-content">
                                    SZN
                                    <ArrowUpDown className={`artist-sort-icon ${sortConfig.key === 'TypeSeason' ? (sortConfig.direction === 'asc' ? 'artist-sorted-asc' : 'artist-sorted-desc') : ''}`} />
                                </div>
                            </th>
                            <th>Mint Date</th>
                            <th>6529</th>
                            <th>OS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {artistData.map((item) => (
                            <tr key={item.ID}>
                                <td>
                                    <a href={`https://6529.io/the-memes/${item.ID}?focus=the-art`} target="_blank" rel="noopener noreferrer">
                                        {item.ID}
                                    </a>
                                </td>
                                <td>{item.Artist}</td>
                                <td>
                                    <a href={`https://6529.io/${item.ArtistHandle}/identity`} target="_blank" rel="noopener noreferrer">
                                        {item.ArtistHandle}
                                    </a>
                                </td>
                                <td>
                                    {getCompactImageUrl(item) && (
                                        <img 
                                            src={getCompactImageUrl(item)}
                                            alt={item.Name}
                                            className="artist-item-image"
                                            loading="lazy"
                                            onClick={() => window.open(getCompactImageUrl(item), '_blank')}
                                        />
                                    )}
                                </td>
                                <td>
                                    <a 
                                        href={getArtworkStreamUrl(item.ID)} 
                                        download={`meme_${item.ID}`}
                                        className="artist-download-link"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Download size={20} />
                                        <span>Download</span>
                                    </a>
                                </td>
                                <td>
                                    <a href={`https://6529.io/the-memes/${item.ID}?focus=the-art`} target="_blank" rel="noopener noreferrer">
                                        {item.Name}
                                    </a>
                                </td>
                                <td>{item.TypeSeason}</td>
                                <td>{formatDate(item.MintDate)}</td>
                                <td>
                                    <a href={`https://6529.io/${item.ArtistHandle}/identity`} target="_blank" rel="noopener noreferrer">
                                        <img src={seizeIcon} alt="6529" className="artist-icon-image" />
                                    </a>
                                </td>
                                <td>
                                    <a href={`https://opensea.io/assets/ethereum/0x33FD426905F149f8376e227d0C9D3340AaD17aF1/${item.ID}`} target="_blank" rel="noopener noreferrer">
                                        <img src={openseaIcon} alt="OpenSea" className="artist-icon-image" />
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            {/* Pagination */}
                <ReactPaginate
                    previousLabel={'← Previous'}
                    nextLabel={'Next →'}
                    breakLabel={'...'}
                    pageCount={Math.ceil(totalRecords / itemsPerPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'artist-pagination'}
                    activeClassName={'active'}
                    forcePage={currentPage}
                />
        </div>
    );
}

export default ArtistComponent; 