import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { fetchSalesSummary } from './apiService';
import './SalesAnalyticsComponent.css';

const timeFrames = ['24h', '7d', '30d', 'ytd', 'all'];
const metrics = [
  { label: 'Sales Count', key: 'sales_count' },
  { label: 'Sales Turnover ETH', key: 'sales_eth' },
  { label: 'Sales Turnover USD', key: 'sales_usd' },
  { label: 'Highest Sale ETH', key: 'highest_sale_eth' },
  { label: 'Highest Sale USD', key: 'highest_sale_usd' },
  { label: 'Bid Percentage', key: 'bid_percentage' },
  { label: 'Ask Percentage', key: 'ask_percentage' },
];

const SalesAnalyticsComponent = () => {
  const [data, setData] = useState({});
  const [customData, setCustomData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [customStartDate, setCustomStartDate] = useState('');
  const [customEndDate, setCustomEndDate] = useState('');
  const [customLoading, setCustomLoading] = useState(false);
  const [customError, setCustomError] = useState(false);

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);
      setError(false);
      try {
        const fetchPromises = timeFrames.map((frame) => fetchSalesSummary({ timeFrame: frame }));
        const results = await Promise.all(fetchPromises);
        const formattedData = {};
        timeFrames.forEach((frame, index) => {
          formattedData[frame] = results[index].data;
        });
        setData(formattedData);
      } catch (err) {
        console.error('Error fetching sales summary data:', err);
        setError(true);
      }
      setLoading(false);
    };

    fetchInitialData();
  }, []);

  const handleFetchCustomData = async () => {
    if (!customStartDate || !customEndDate) {
      alert('Please select both start and end dates.');
      return;
    }

    if (new Date(customStartDate) > new Date(customEndDate)) {
      alert('Start date must be before end date.');
      return;
    }

    setCustomLoading(true);
    setCustomError(false);
    try {
      const result = await fetchSalesSummary({
        timeFrame: 'custom',
        startDate: customStartDate,
        endDate: customEndDate,
      });

      setCustomData(result.data);
    } catch (err) {
      console.error('Error fetching custom sales summary:', err);
      setCustomError(true);
    }
    setCustomLoading(false);
  };

  if (loading) {
    return <div className="sales-analytics loading">Loading...</div>;
  }

  if (error) {
    return <div className="sales-analytics error">Failed to load sales data.</div>;
  }

  return (
    <div className="sales-analytics">
      <Helmet>
        <title>6529 Memes NFT Sales Stats | Track Sales Trends Over Time</title>
        <meta 
          name="description" 
          content="6529 Memes NFT sales trends over 24H, 7D, 30D, YTD & more. View & explore total sales, market volume & highest recorded sale. Custom date selector included."
        />
        <meta 
          property="og:title" 
          content="6529 Memes NFT Sales Stats | Track Sales Trends Over Time"
        />
        <meta 
          property="og:description" 
          content="6529 Memes NFT sales trends over 24H, 7D, 30D, YTD & more. View & explore total sales, market volume & highest recorded sale. Custom date selector included."
        />
        <meta property="og:image" content="https://6529stats.xyz/share-image.png" />
        <meta property="og:url" content="https://6529stats.xyz/sales-analytics" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta 
          name="twitter:title" 
          content="6529 Memes NFT Sales Stats | Track Sales Trends Over Time"
        />
        <meta 
          name="twitter:description" 
          content="6529 Memes NFT sales trends over 24H, 7D, 30D, YTD & more. View & explore total sales, market volume & highest recorded sale. Custom date selector included."
        />
        <meta name="twitter:image" content="https://6529stats.xyz/share-image.png" />
      </Helmet>

      <h1 className="title">6529 Memes NFT Sales – View Aggregated Data by Period</h1>
      <p className="description">
        View selected sales metrics by the predefined time frames: 24H, 7D, 30D, YTD, All.<br />
        Or <strong>choose any custom time period and view the results in the Custom column</strong>.
      </p>

      <div className="custom-date-selector">
        <label htmlFor="startDate">Start Date:</label>
        <input 
          type="date" 
          id="startDate" 
          value={customStartDate} 
          onChange={(e) => setCustomStartDate(e.target.value)} 
          max={customEndDate || ''}
        />
        
        <label htmlFor="endDate">End Date:</label>
        <input 
          type="date" 
          id="endDate" 
          value={customEndDate} 
          onChange={(e) => setCustomEndDate(e.target.value)} 
          min={customStartDate || ''}
        />

        <button onClick={handleFetchCustomData} disabled={customLoading}>
          {customLoading ? 'Fetching...' : 'Fetch Custom Data'}
        </button>
      </div>

      {customError && <div className="error-message">Failed to load custom sales data.</div>}

      <table className="sales-analytics-table">
        <thead>
          <tr>
            <th>Metric</th>
            {timeFrames.map((frame) => (
              <th key={frame}>{frame.toUpperCase()}</th>
            ))}
            <th>Custom</th>
          </tr>
        </thead>
        <tbody>
          {metrics.map((metric) => (
            <tr key={metric.key}>
              <td className="metric-label">{metric.label}</td>
              {timeFrames.map((frame) => (
                <td key={frame}>
                  {metric.key.includes('percentage')
                    ? `${data[frame][metric.key]}%`
                    : metric.key.includes('sales_count')
                    ? data[frame][metric.key]
                    : metric.key.includes('eth')
                    ? parseFloat(data[frame][metric.key]).toFixed(4)
                    : metric.key.includes('usd')
                    ? `$${parseFloat(data[frame][metric.key]).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                    : data[frame][metric.key]}
                </td>
              ))}
              <td>
                {customLoading ? (
                  'Loading...'
                ) : customData ? (
                  metric.key.includes('percentage')
                    ? `${customData[metric.key]}%`
                    : metric.key.includes('sales_count')
                    ? customData[metric.key]
                    : metric.key.includes('eth')
                    ? parseFloat(customData[metric.key]).toFixed(4)
                    : metric.key.includes('usd')
                    ? `$${parseFloat(customData[metric.key]).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                    : customData[metric.key]
                ) : (
                  'N/A'
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="update-section">
        <p className="update-info"><strong>Sales Data Updated Every 2 Minutes</strong></p>
      </div>
    </div>
  );
};

export default SalesAnalyticsComponent;