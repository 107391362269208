import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { fetchArtistDirectory } from './apiService';
import './MeetTheArtistComponent.css';
// Import the image directly
import baiweiImage from './assets/306_sale_pfp.png';

// Create a mapping of image paths to imported images
const imageMap = {
  '306_sale_pfp.png': baiweiImage,
  '/assets/306_sale_pfp.png': baiweiImage
};

function MeetTheArtistComponent() {
    const [artists, setArtists] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadArtists = async () => {
            try {
                const response = await fetchArtistDirectory();
                setArtists(response.data);
            } catch (error) {
                console.error('Error loading artists:', error);
            } finally {
                setLoading(false);
            }
        };

        loadArtists();
    }, []);

    // Function to resolve image path
    const resolveImagePath = (imagePath) => {
        if (!imagePath) return null;
        
        // Check if we have this image in our map
        if (imageMap[imagePath]) {
            return imageMap[imagePath];
        }
        
        // If it's an absolute URL, return as is
        if (imagePath.startsWith('http')) {
            return imagePath;
        }
        
        // Default case, return the path as is
        console.warn(`Image not found in map: ${imagePath}`);
        return imagePath;
    };

    if (loading) {
        return <div className="meet-artist-loading">Loading...</div>;
    }

    return (
        <div className="meet-artist-container">
            <Helmet>
                <title>6529 NFT Artists | Learn About the Talent Behind the Memes</title>
                <meta 
                    name="description" 
                    content="Learn more about 6529 Meme artists. Dive into bios, explore curated art pages, and follow their creative work outside of the 6529 ecosystem."
                />
                <meta 
                    property="og:title" 
                    content="6529 NFT Artists | Learn About the Talent Behind the Memes"
                />
                <meta 
                    property="og:description" 
                    content="Learn more about 6529 Meme artists. Dive into bios, explore curated art pages, and follow their creative work outside of the 6529 ecosystem."
                />
                <meta property="og:image" content="https://6529stats.xyz/share-image.png" />
                <meta property="og:url" content="https://6529stats.xyz/meet-the-artists" />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta 
                    name="twitter:title" 
                    content="6529 NFT Artists | Learn About the Talent Behind the Memes"
                />
                <meta 
                    name="twitter:description" 
                    content="Learn more about 6529 Meme artists. Dive into bios, explore curated art pages, and follow their creative work outside of the 6529 ecosystem."
                />
                <meta name="twitter:image" content="https://6529stats.xyz/share-image.png" />
            </Helmet>

            <h1 className="meet-artist-title">6529 NFT Artists | Learn About the Talent Behind the Memes</h1>
            
            <div className="meet-artist-grid">
                {artists.map((artist, index) => (
                    <div key={index} className="meet-artist-card">
                        {artist.image_path && (
                            <div className="meet-artist-image">
                                <img src={resolveImagePath(artist.image_path)} alt={artist.artistname} />
                            </div>
                        )}
                        <h2 className="meet-artist-name">{artist.artistname}</h2>
                        <div className="meet-artist-meme">Meme #{artist.memecard}</div>
                        <Link to={artist.profileurl} className="meet-artist-link">
                            View Profile
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default MeetTheArtistComponent; 