import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import './HodlerSupplyEnhancedComponent.css';
import { fetchHodlerSupplyEnhancedData } from './apiService';
import { ArrowUpDown, ChevronRight } from 'lucide-react';

function HodlerSupplyEnhancedComponent() {
  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'Card No', direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const pageSize = 25;

  const [filterConfig, setFilterConfig] = useState({ szn: 'All' });
  const [sznOptions, setSznOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showMobileInfo, setShowMobileInfo] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchHodlerSupplyEnhancedData(
          currentPage,
          pageSize,
          sortConfig.key,
          sortConfig.direction,
          filterConfig.szn,
          searchTerm
        );
        setData(result.data);
        setTotalPages(result.totalPages);
        setTotalRecords(result.totalTokens);
      } catch (error) {
        console.error('Error fetching hodler supply enhanced data:', error);
      }
    };
    fetchData();
  }, [currentPage, sortConfig, filterConfig, searchTerm]);

  // Fetch unique SZN options only once
  useEffect(() => {
    const fetchSznOptions = async () => {
      try {
        const szns = await fetchUniqueSznOptions();
        setSznOptions(szns);
      } catch (error) {
        console.error('Error fetching unique SZN options:', error);
      }
    };
    fetchSznOptions();
  }, []);

  const fetchUniqueSznOptions = async () => {
    // Fetch unique SZN options from the server
    const sznsData = await fetchHodlerSupplyEnhancedData(1, 1000, 'SZN', 'ascending', 'All');
    const uniqueSzns = [...new Set(sznsData.data.map(item => item['SZN']))];
    return uniqueSzns.sort((a, b) => {
      const numA = parseInt(a.replace('SZN', ''));
      const numB = parseInt(b.replace('SZN', ''));
      return numA - numB;
    });
  };

  const requestSort = (key) => {
    if (key === 'Name') return; // 'Name' column is not sortable
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    setCurrentPage(1); // Reset to page 1 when sorting changes
  };

  const getSortIcon = (columnKey) => {
    if (!columns.find(col => col.key === columnKey)?.sortable) return null;
    return (
      <ArrowUpDown
        className={`hodler-enhanced-sort-icon ${sortConfig.key === columnKey ? (sortConfig.direction === 'ascending' ? 'sorted-asc' : 'sorted-desc') : ''}`}
      />
    );
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const columns = [
    { label: 'Card No', key: 'Card No', sortable: true },
    { label: 'Name', key: 'Name', sortable: false },
    { label: 'SZN', key: 'SZN', sortable: true },
    { label: 'Supply', key: 'Supply', sortable: true },
    { label: 'Total Holders', key: 'Total Holders', sortable: true },
    { label: '6529 Owned', key: '6529 Owned', sortable: true },
    { label: '6529 % Supply', key: '6529 % Supply', sortable: true },
    { label: 'Full Set Cards Owned', key: 'Full Set Cards Owned', sortable: true },
    { label: 'Full Set Holders', key: 'Full Set Holders', sortable: true },
    { label: 'Full Set % Supply', key: 'Full Set % Supply', sortable: true },
    { label: 'SZN Set Cards Owned', key: 'SZN Set Cards Owned', sortable: true },
    { label: 'SZN Set Holders', key: 'SZN Set Holders', sortable: true },
    { label: 'SZN Set % Supply', key: 'SZN Set % Supply', sortable: true },
    { label: 'Remaining Cards', key: 'Remaining Cards', sortable: true },
    { label: 'Other Holders', key: 'Other Holders', sortable: true },
    { label: 'Other % Supply', key: 'Other % Supply', sortable: true },
    { label: '% Unique Hodlers', key: '% Unique Hodlers', sortable: true },
    { label: '% Unique Hodlers (Excl 6529)', key: '% Unique Hodlers (Excl 6529)', sortable: true },
    { label: 'Available *', key: 'Available *', sortable: true },
    { label: 'Available % **', key: 'Available % **', sortable: true },
  ];

  return (
    <div className="hodler-enhanced-container">
      <Helmet>
        <title>6529 Memes NFT Supply & Holders | Enhanced Holder Analytics</title>
        <meta 
          name="description" 
          content="Explore enhanced 6529 Memes NFT supply & ownership data. See token-level supply, exact holdings by key groups & spot tokens with low availability potential."
        />
        <meta 
          property="og:title" 
          content="6529 Memes NFT Supply & Holders | Enhanced Holder Analytics"
        />
        <meta 
          property="og:description" 
          content="Explore enhanced 6529 Memes NFT supply & ownership data. See token-level supply, exact holdings by key groups & spot tokens with low availability potential."
        />
        <meta property="og:image" content="https://6529stats.xyz/share-image.png" />
        <meta property="og:url" content="https://6529stats.xyz/supply-enhanced" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta 
          name="twitter:title" 
          content="6529 Memes NFT Supply & Holders | Enhanced Holder Analytics"
        />
        <meta 
          name="twitter:description" 
          content="Explore enhanced 6529 Memes NFT supply & ownership data. See token-level supply, exact holdings by key groups & spot tokens with low availability potential."
        />
        <meta name="twitter:image" content="https://6529stats.xyz/share-image.png" />
      </Helmet>

      <h1 className="hodler-enhanced-title">6529 Memes NFT Collection Supply & Ownership Analysis - Enhanced</h1>

      {/* Filter Container */}
      <div className="hodler-enhanced-filters-form">
        <div className="hodler-enhanced-filters-row">
          <div className="hodler-enhanced-filter-item">
            <label htmlFor="szn-filter">Filter by SZN: </label>
            <select
              id="szn-filter"
              value={filterConfig.szn}
              onChange={(e) => {
                setFilterConfig({ ...filterConfig, szn: e.target.value });
                setCurrentPage(1);
              }}
            >
              <option value="All">All</option>
              {sznOptions.map((szn) => (
                <option key={szn} value={szn}>
                  {szn}
                </option>
              ))}
            </select>
          </div>

          <div className="hodler-enhanced-filter-item">
            <label htmlFor="search-input">Search Name: </label>
            <div className="hodler-enhanced-search-container">
              <input
                type="text"
                id="search-input"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  if (e.target.value.length >= 2) {
                    setShowSuggestions(true);
                  } else {
                    setShowSuggestions(false);
                  }
                  setCurrentPage(1);
                }}
                onFocus={() => {
                  if (searchTerm.length >= 2) {
                    setShowSuggestions(true);
                  }
                }}
                onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
                placeholder="Search cards..."
                className="hodler-enhanced-search-input"
              />
              {showSuggestions && searchTerm.length >= 2 && (
                <ul className="hodler-enhanced-suggestions-list">
                  {data
                    .filter(item => item.Name.toLowerCase().includes(searchTerm.toLowerCase()))
                    .slice(0, 5)
                    .map((suggestion, index) => (
                      <li
                        key={index}
                        onMouseDown={() => {
                          setSearchTerm(suggestion.Name);
                          setShowSuggestions(false);
                          setCurrentPage(1);
                        }}
                      >
                        {suggestion.Name}
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Info row with mobile toggle */}
      <div className="hodler-enhanced-info-row">
        <button 
          className="hodler-enhanced-info-toggle" 
          onClick={() => setShowMobileInfo(!showMobileInfo)}
          style={{ display: 'none' }} // Hidden by default, shown via media query
        >
          {showMobileInfo ? 'Hide Information' : 'Show Information'}
        </button>
        <div className={`hodler-enhanced-explanations ${showMobileInfo ? 'show' : ''}`}>
          <p>
            <strong>*</strong> Available is calculated by Supply minus 6529 Owned minus number of Full Set Holders minus number of SZN Set Holders
          </p>
          <p>
            <strong>**</strong> Available % is calculated by Available as a percentage of Supply
          </p>
        </div>
        <div className="hodler-enhanced-records-count">
          <span><strong>Number of Records:</strong> {totalRecords}</span>
        </div>
      </div>

      {/* Table */}
      <div className="hodler-enhanced-table-wrapper">
        <table className="hodler-enhanced-centered-table">
          <thead>
            <tr>
              {columns.map((column) => (
                <th
                  key={column.key}
                  onClick={column.sortable ? () => requestSort(column.key) : undefined}
                  className={column.sortable ? 'sortable' : ''}
                >
                  <div className="hodler-enhanced-header-content">
                    {column.label} {getSortIcon(column.key)}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((token, index) => (
              <tr key={index}>
                <td>{token['Card No']}</td>
                <td>
                  <a
                    href={`https://6529stats.xyz/token/${token['Card No']}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hodler-enhanced-name-link"
                  >
                    {token['Name']} <ChevronRight className="hodler-enhanced-chevron-icon" />
                  </a>
                </td>
                <td>{token['SZN']}</td>
                <td>{token['Supply']}</td>
                <td>{token['Total Holders']}</td>
                <td>{token['6529 Owned']}</td>
                <td>{token['6529 % Supply']}</td>
                <td>{token['Full Set Cards Owned']}</td>
                <td>{token['Full Set Holders']}</td>
                <td>{token['Full Set % Supply']}</td>
                <td>{token['SZN Set Cards Owned']}</td>
                <td>{token['SZN Set Holders']}</td>
                <td>{token['SZN Set % Supply']}</td>
                <td>{token['Remaining Cards']}</td>
                <td>{token['Other Holders']}</td>
                <td>{token['Other % Supply']}</td>
                <td>{token['% Unique Hodlers']}</td>
                <td>{token['% Unique Hodlers (Excl 6529)']}</td>
                <td>{token['Available *']}</td>
                <td>{token['Available % **']}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="hodler-enhanced-pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default HodlerSupplyEnhancedComponent;
