// src/AboutComponent.js

import React from 'react';
import './AboutComponent.css';
import openseaIcon from './assets/openseaIcon.png';
import meIcon from './assets/meIcon.png';
import discordIcon from './assets/discordIcon.png';
import blurIcon from './assets/blurIcon.png';
import firstTweet from './assets/first.png';
import XIcon from './assets/XIcon.png';
import seizeIcon from './assets/seizeIcon.png';

const AboutComponent = () => {
  return (
    <div className="about-container">
      <h1 className="about-main-header">Learn About 6529 Memes NFTs, the 6529 Ecosystem & Vantekai</h1>

      {/* 6529 Memes NFT Collection Section */}
      <div className="about-section">
        <h2>6529 Memes NFT Collection</h2>
        <div className="about-content">
          <p>
            The 6529 Memes NFT collection is one of the NFT collection created by Punk6529. Created in June with 
            the release of its first three NFTs it now stands at over 300+ NFTs as the time of writing in February 2025.
          </p>
          <p>
            The collection is split into seasons with usually 4 seasons per year, 1 a quarter, Mint days are usually 
            Monday, Wednesday and Friday of each week.
          </p>
          <p>
            The NFTs are ERC-1155 minted on the Ethereum network and always for a fixed 0.06529 ETH.
          </p>
          <p>
            The collection has several goals and objectives and you can read more on{' '}
            <a href="https://6529.io/about/the-memes" target="_blank" rel="noopener noreferrer">
              6529.io directly
            </a>.
          </p>

          <div className="subsection">
            <h3>Other useful sites and contacts</h3>
            <div className="marketplace-section">
              <h4>Marketplaces</h4>
              <div className="marketplace-icons">
                <a href="https://opensea.io/collection/thememes6529" target="_blank" rel="noopener noreferrer">
                  <img src={openseaIcon} alt="OpenSea" />
                </a>
                <a href="https://magiceden.io/collections/ethereum/0x33fd426905f149f8376e227d0c9d3340aad17af1" target="_blank" rel="noopener noreferrer">
                  <img src={meIcon} alt="Magic Eden" />
                </a>
              </div>
            </div>

            <div className="social-section">
              <h4>Social Media</h4>
              <p>
                To keep up to date on X follow{' '}
                <a href="https://x.com/6529Collections" target="_blank" rel="noopener noreferrer">6529 Collections</a>
                {' '}and{' '}
                <a href="https://x.com/punk6529" target="_blank" rel="noopener noreferrer">Punk6529</a>
              </p>
              <p>
                The Memes Chat channel in Discord is a great way to keep up with what's going on within all things 6529
              </p>
              <a href="https://discord.gg/zSgAzWa3" target="_blank" rel="noopener noreferrer" className="discord-link">
                <img src={discordIcon} alt="Discord" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* NextGen Pebbles Section */}
      <div className="about-section">
        <h2>6529 NextGen Pebbles NFT Collection</h2>
        <div className="about-content">
          <p>
            NextGen is an on-chain generative art NFT contract. It is also a tool to support the ambitious aspirations 
            of the 6529 community in the areas of art experimentation and decentralized social organization.
          </p>
          <p>
            The first collection minted on the contract was{' '}
            <a href="https://6529.io/Zeblocks/identity" target="_blank" rel="noopener noreferrer">
              Pebbles by Zeblocks
            </a>{' '}
            in February 2024.
          </p>
          <p>
            An edition size of 1000 with a mint price of 0.06529.
          </p>
          <p>
            It is envisaged that more collections will be minted on the contact over time but as yet Pebbles is the only one.
          </p>
          <p>
            Read more about{' '}
            <a href="https://6529.io/nextgen/collection/pebbles" target="_blank" rel="noopener noreferrer">
              Pebbles on the 6529.io Website
            </a>.
          </p>

          <div className="marketplace-section">
            <h4>Marketplaces</h4>
            <div className="marketplace-icons">
              <a href="https://opensea.io/collection/pebbles-by-zeblocks" target="_blank" rel="noopener noreferrer">
                <img src={openseaIcon} alt="OpenSea" />
              </a>
              <a href="https://blur.io/eth/collection/nextgen6529" target="_blank" rel="noopener noreferrer">
                <img src={blurIcon} alt="Blur" />
              </a>
              <a href="https://magiceden.io/collections/ethereum/0x45882f9bc325e14fbb298a1df930c43a874b83ae" target="_blank" rel="noopener noreferrer">
                <img src={meIcon} alt="Magic Eden" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* 6529 Stats Site Section */}
      <div className="about-section">
        <h2>6529stats.xyz by Vantekai</h2>
        <div className="about-content">
          <p>
            This site was created by{' '}
            <a href="https://x.com/Vantekai_" target="_blank" rel="noopener noreferrer">
              Vantekai
            </a>{' '}
            (previously the cringe web3at50) in September 2024 with the initial challenge of getting one table and one page live online.
          </p>
          
          <p>
            I have been in and around and interested in NFTs and web3 since October 2021 and started collecting 6529 Memes NFTs in August 2022.
          </p>

          <div className="first-tweet">
            <p>
              I started posting some basic statistics around the collection on and off since August 2022 with this being my{' '}
              <a href="https://x.com/Vantekai_/status/1565041016574443531" target="_blank" rel="noopener noreferrer">
                first very basic entry
              </a>{' '}
              on 31 Aug 2022:
            </p>
            <a href="https://x.com/Vantekai_/status/1565041016574443531" target="_blank" rel="noopener noreferrer">
              <img src={firstTweet} alt="First Tweet about 6529 Stats" className="tweet-image" />
            </a>
          </div>

          <p>
            From there it moved on to Dune Analytics tables, spreadsheets, charts, databases, more spreadsheet and tables until in September 2024 
            with the help of the evolving AI I decided to see if I could create one table and one page online fetching data from an API. 
            Eventually I managed it and the page quickly evolved throughout the last quarter of 2024 into more than one page and one table!
          </p>

          <p>
            As well as this site I also started a Substack newsletter at the backend of 2024 and still post stats on X as and when the mood takes.
          </p>

          <p>
            There is no plan or objective other than as long as I have time available and continue to thoroughly enjoy it I think spending time 
            in using AI to code in crypto is probably not a bad investment of my time. Making a few ££ along the way even if just to cover the 
            costs wouldn't hurt but isn't necessarily a must.
          </p>

          <div className="contact-section">
            <h3>Where to find me</h3>
            <div className="contact-icons">
              <a href="https://x.com/Vantekai_" target="_blank" rel="noopener noreferrer" className="contact-link">
                <img src={XIcon} alt="X (Twitter)" />
                <span>Follow on X</span>
              </a>
              <a href="https://6529.io/Vantekai/identity" target="_blank" rel="noopener noreferrer" className="contact-link">
                <img src={seizeIcon} alt="Seize Profile" />
                <span>Seize Profile</span>
              </a>
              <a href="https://6529stats.xyz/contact" className="contact-link">
                <span>Contact Page</span>
              </a>
              <a href="https://vantekai.substack.com/" target="_blank" rel="noopener noreferrer" className="contact-link">
                <span>Substack Newsletter</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutComponent;
