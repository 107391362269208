// SalesSummaryComponent.js

import React, { useEffect, useState, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { fetchSalesData, fetchPrices } from './apiService';
import './SalesSummaryComponent.css'; // Import the component-specific CSS
import { ArrowUpDown, ChevronRight } from 'lucide-react'; // Import the ChevronRight icon
import { NavLink } from 'react-router-dom'; // Import NavLink for routing
import ReactPaginate from 'react-paginate';

const SalesSummaryComponent = () => {
    // State variables
    const [salesData, setSalesData] = useState([]);
    const [pricesData, setPricesData] = useState({});
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [selectedTimePeriod, setSelectedTimePeriod] = useState('all');
    const [selectedSZN, setSelectedSZN] = useState('All');
    const [selectedSupply, setSelectedSupply] = useState('All');
    const [searchTerm, setSearchTerm] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [loading, setLoading] = useState(false);
    const [lastSaleTime, setLastSaleTime] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 25;

    // Fetch both sales and prices data
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const [salesResponse, pricesResponse] = await Promise.all([
                    fetchSalesData(selectedTimePeriod),
                    fetchPrices({ page: 1, pageSize: 1000 })
                ]);

                // Create a lookup object for prices data
                const pricesLookup = pricesResponse.data.reduce((acc, item) => {
                    acc[item.ID] = item;
                    return acc;
                }, {});

                setSalesData(salesResponse.data);
                setPricesData(pricesLookup);
                setLastSaleTime(salesResponse.lastSaleTime);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [selectedTimePeriod]);

    // Implement sorting
    const sortTable = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    // Get unique SZNs for filters
    const uniqueSZNs = useMemo(() => {
        const szns = new Set(salesData.map(item => item.typeseason));
        return ['All', ...Array.from(szns).filter(szn => szn !== null && szn !== '')];
    }, [salesData]);

    // Get unique Supply values for filter
    const uniqueSupplies = useMemo(() => {
        const supplies = new Set(Object.values(pricesData).map(item => item.Supply));
        return ['All', ...Array.from(supplies).filter(supply => supply !== null && supply !== '').sort((a, b) => a - b)];
    }, [pricesData]);

    const sortedSalesData = useMemo(() => {
        let sortableItems = [...salesData];
        if (sortConfig.key !== null) {
            sortableItems.sort((a, b) => {
                let aValue = a[sortConfig.key];
                let bValue = b[sortConfig.key];

                // Special handling for Supply column
                if (sortConfig.key === 'supply') {
                    aValue = pricesData[a.tokenid]?.Supply || 0;
                    bValue = pricesData[b.tokenid]?.Supply || 0;
                }

                if (typeof aValue === 'number' && typeof bValue === 'number') {
                    return sortConfig.direction === 'ascending' ? aValue - bValue : bValue - aValue;
                } else if (!isNaN(parseFloat(aValue)) && !isNaN(parseFloat(bValue))) {
                    return sortConfig.direction === 'ascending' ? parseFloat(aValue) - parseFloat(bValue) : parseFloat(bValue) - parseFloat(aValue);
                } else {
                    if (aValue < bValue) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (aValue > bValue) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                    return 0;
                }
            });
        }
        return sortableItems;
    }, [salesData, sortConfig, pricesData]);

    const filteredSalesData = useMemo(() => {
        return sortedSalesData.filter(item =>
            (selectedSZN === 'All' || item.typeseason === selectedSZN) &&
            (selectedSupply === 'All' || pricesData[item.tokenid]?.Supply === parseInt(selectedSupply)) &&
            (searchTerm === '' || item.name.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    }, [sortedSalesData, selectedSZN, selectedSupply, searchTerm, pricesData]);

    // Get paginated data
    const paginatedData = useMemo(() => {
        const startIndex = currentPage * itemsPerPage;
        return filteredSalesData.slice(startIndex, startIndex + itemsPerPage);
    }, [filteredSalesData, currentPage]);

    // Handle page change
    const handlePageClick = (event) => {
        setCurrentPage(event.selected);
    };

    // Time Period options
    const timePeriodOptions = [
        { value: 'last24hours', label: 'Last 24 Hours' },
        { value: 'last7days', label: 'Last 7 Days' },
        { value: 'last30days', label: 'Last 30 Days' },
        { value: '2022', label: 'Year 2022' },
        { value: '2023', label: 'Year 2023' },
        { value: '2024', label: 'Year 2024' },
        { value: 'all', label: 'All Time' },
    ];

    // Compute totals for relevant columns
    const totals = useMemo(() => {
        const totalSalesCount = filteredSalesData.reduce((sum, item) => sum + parseInt(item.sales_count), 0);
        const totalSalesEth = filteredSalesData.reduce((sum, item) => sum + parseFloat(item.sales_eth), 0);
        const totalSalesUsd = filteredSalesData.reduce((sum, item) => sum + parseFloat(item.sales_usd), 0);
        return {
            totalSalesCount,
            totalSalesEth,
            totalSalesUsd
        };
    }, [filteredSalesData]);

    // Format the last sale time to UK format
    const formattedLastSaleTime = useMemo(() => {
        if (!lastSaleTime) return '';
        const date = new Date(lastSaleTime);
        return date.toLocaleString('en-GB', {
            day: 'numeric',
            month: 'numeric',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
    }, [lastSaleTime]);

    const suggestions = useMemo(() => {
        if (searchTerm.length < 2) return [];
        const uniqueNames = new Set(salesData.map(item => item.name));
        return Array.from(uniqueNames).filter(name =>
            name.toLowerCase().includes(searchTerm.toLowerCase())
        ).slice(0, 5);
    }, [salesData, searchTerm]);

    return (
        <div className="sales-summary-container">
            <Helmet>
                <title>6529 Memes NFT Market Insights | Total Sales & Price Analytics</title>
                <meta 
                    name="description" 
                    content="6529 Memes NFT Sales Market Summary Data – Explore total sales count, ETH/USD volume, highest & lowest sale prices, averages & aggregated token stats."
                />
                <meta 
                    property="og:title" 
                    content="6529 Memes NFT Market Insights | Total Sales & Price Analytics"
                />
                <meta 
                    property="og:description" 
                    content="6529 Memes NFT Sales Market Summary Data – Explore total sales count, ETH/USD volume, highest & lowest sale prices, averages & aggregated token stats."
                />
                <meta property="og:image" content="https://6529stats.xyz/share-image.png" />
                <meta property="og:url" content="https://6529stats.xyz/sales-summary" />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta 
                    name="twitter:title" 
                    content="6529 Memes NFT Market Insights | Total Sales & Price Analytics"
                />
                <meta 
                    name="twitter:description" 
                    content="6529 Memes NFT Sales Market Summary Data – Explore total sales count, ETH/USD volume, highest & lowest sale prices, averages & aggregated token stats."
                />
                <meta name="twitter:image" content="https://6529stats.xyz/share-image.png" />
            </Helmet>

            <h1 className="sales-summary-main-header">6529 Memes NFT Sales Summary – Individual NFT Insights</h1>

            <div className="sales-summary-filters-form">
                <div className="sales-summary-filters-row">
                    <div className="sales-summary-filter-item">
                        <label>Time Period</label>
                        <select
                            value={selectedTimePeriod}
                            onChange={(e) => {
                                setSelectedTimePeriod(e.target.value);
                                setCurrentPage(0);
                            }}
                        >
                            {timePeriodOptions.map((option) => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                            ))}
                        </select>
                    </div>

                    <div className="sales-summary-filter-item">
                        <label>SZN</label>
                        <select
                            value={selectedSZN}
                            onChange={(e) => {
                                setSelectedSZN(e.target.value);
                                setCurrentPage(0);
                            }}
                        >
                            {uniqueSZNs.map((szn) => (
                                <option key={szn} value={szn}>{szn}</option>
                            ))}
                        </select>
                    </div>

                    <div className="sales-summary-filter-item">
                        <label>Supply</label>
                        <select
                            value={selectedSupply}
                            onChange={(e) => {
                                setSelectedSupply(e.target.value);
                                setCurrentPage(0);
                            }}
                        >
                            {uniqueSupplies.map((supply) => (
                                <option key={supply} value={supply}>{supply}</option>
                            ))}
                        </select>
                    </div>

                    <div className="sales-summary-filter-item">
                        <label>Search</label>
                        <div className="sales-summary-search-container">
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                    setShowSuggestions(true);
                                }}
                                onFocus={() => setShowSuggestions(true)}
                                onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
                                placeholder="Search cards..."
                            />
                            {showSuggestions && suggestions.length > 0 && (
                                <ul className="sales-summary-suggestions-list">
                                    {suggestions.map((suggestion, index) => (
                                        <li
                                            key={index}
                                            onMouseDown={() => {
                                                setSearchTerm(suggestion);
                                                setShowSuggestions(false);
                                            }}
                                        >
                                            {suggestion}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="sales-summary-info-row">
                <span className="sales-summary-last-updated">
                    Last Sale: {formattedLastSaleTime}
                </span>
                <span className="sales-summary-records-info">
                    Total Records: {filteredSalesData.length}
                </span>
            </div>

            {loading ? (
                <div className="sales-summary-spinner"></div>
            ) : (
                <>
                    <div className="sales-summary-table-wrapper">
                        <table className="sales-summary-centered-table sales-summary-full-width-table">
                            <thead>
                                <tr>
                                    <th onClick={() => sortTable('tokenid')}>
                                        Token No <ArrowUpDown className="sales-summary-inline" />
                                    </th>
                                    <th onClick={() => sortTable('name')}>
                                        Name <ArrowUpDown className="sales-summary-inline" />
                                    </th>
                                    <th onClick={() => sortTable('artist')}>
                                        Artist <ArrowUpDown className="sales-summary-inline" />
                                    </th>
                                    <th onClick={() => sortTable('supply')}>
                                        Supply <ArrowUpDown className="sales-summary-inline" />
                                    </th>
                                    <th onClick={() => sortTable('typeseason')}>
                                        SZN <ArrowUpDown className="sales-summary-inline" />
                                    </th>
                                    <th onClick={() => sortTable('sales_count')}>
                                        Sales Count <ArrowUpDown className="sales-summary-inline" />
                                    </th>
                                    <th onClick={() => sortTable('sales_eth')}>
                                        Sales ETH <ArrowUpDown className="sales-summary-inline" />
                                    </th>
                                    <th onClick={() => sortTable('sales_usd')}>
                                        Sales USD <ArrowUpDown className="sales-summary-inline" />
                                    </th>
                                    <th onClick={() => sortTable('highest_sale_eth')}>
                                        Highest Sale ETH <ArrowUpDown className="sales-summary-inline" />
                                    </th>
                                    <th onClick={() => sortTable('highest_sale_usd')}>
                                        Highest Sale USD <ArrowUpDown className="sales-summary-inline" />
                                    </th>
                                    <th onClick={() => sortTable('lowest_sale_eth')}>
                                        Lowest Sale ETH <ArrowUpDown className="sales-summary-inline" />
                                    </th>
                                    <th onClick={() => sortTable('lowest_sale_usd')}>
                                        Lowest Sale USD <ArrowUpDown className="sales-summary-inline" />
                                    </th>
                                    <th onClick={() => sortTable('average_sale_eth')}>
                                        Average Sale ETH <ArrowUpDown className="sales-summary-inline" />
                                    </th>
                                    <th onClick={() => sortTable('average_sale_usd')}>
                                        Average Sale USD <ArrowUpDown className="sales-summary-inline" />
                                    </th>
                                    <th onClick={() => sortTable('no_bids')}>
                                        No. of Bids <ArrowUpDown className="sales-summary-inline" />
                                    </th>
                                    <th onClick={() => sortTable('no_asks')}>
                                        No. of Asks <ArrowUpDown className="sales-summary-inline" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.tokenid}</td>
                                        <td>
                                            <NavLink 
                                                to={`/token/${item.tokenid}`} 
                                                className="sales-summary-name-link"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {item.name}
                                                <ChevronRight className="sales-summary-chevron-icon" />
                                            </NavLink>
                                        </td>
                                        <td>
                                            {pricesData[item.tokenid]?.ArtistHandle ? (
                                                <a
                                                    href={`https://6529.io/${pricesData[item.tokenid].ArtistHandle}/rep`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="sales-summary-name-link"
                                                >
                                                    {item.artist} <ChevronRight className="sales-summary-chevron-icon" />
                                                </a>
                                            ) : (
                                                item.artist
                                            )}
                                        </td>
                                        <td>{pricesData[item.tokenid]?.Supply || 'N/A'}</td>
                                        <td>{item.typeseason}</td>
                                        <td>{item.sales_count}</td>
                                        <td>{parseFloat(item.sales_eth).toFixed(4)}</td>
                                        <td>{parseFloat(item.sales_usd).toFixed(2)}</td>
                                        <td>{parseFloat(item.highest_sale_eth).toFixed(4)}</td>
                                        <td>{parseFloat(item.highest_sale_usd).toFixed(2)}</td>
                                        <td>{parseFloat(item.lowest_sale_eth).toFixed(4)}</td>
                                        <td>{parseFloat(item.lowest_sale_usd).toFixed(2)}</td>
                                        <td>{parseFloat(item.average_sale_eth).toFixed(4)}</td>
                                        <td>{parseFloat(item.average_sale_usd).toFixed(2)}</td>
                                        <td>{item.no_bids}</td>
                                        <td>{item.no_asks}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan="5"><strong>Totals</strong></td>
                                    <td><strong>{totals.totalSalesCount}</strong></td>
                                    <td><strong>{totals.totalSalesEth.toFixed(4)}</strong></td>
                                    <td><strong>{totals.totalSalesUsd.toFixed(2)}</strong></td>
                                    <td colSpan="8"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <ReactPaginate
                        previousLabel={'← Previous'}
                        nextLabel={'Next →'}
                        breakLabel={'...'}
                        pageCount={Math.ceil(filteredSalesData.length / itemsPerPage)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'sales-summary-pagination'}
                        activeClassName={'active'}
                        previousClassName={'previous'}
                        nextClassName={'next'}
                        disabledClassName={'disabled'}
                        breakClassName={'break'}
                    />
                </>
            )}
        </div>
    );
};

export default SalesSummaryComponent;
