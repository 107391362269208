import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { fetch6529TdhData } from './apiService';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import './TdhDistributionComponent.css';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TdhDistributionComponent = () => {
  const [tdhChartData, setTdhChartData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const tdhResponse = await fetch6529TdhData();
        
        // Set up 6529 TDH chart data
        if (tdhResponse.data && tdhResponse.data.dates) {
          setTdhChartData({
            labels: tdhResponse.data.dates,
            datasets: [
              {
                label: 'social.6529.eth,trove.punk6529.eth,punk6529',
                data: tdhResponse.data.datasets[0].data,
                borderColor: '#FF5733',
                backgroundColor: '#FF5733',
                pointRadius: 3,
                tension: 0.1
              },
              {
                label: 'research.6529.eth',
                data: tdhResponse.data.datasets[1].data,
                borderColor: '#33FF57',
                backgroundColor: '#33FF57',
                pointRadius: 3,
                tension: 0.1
              },
              {
                label: '6529Museum',
                data: tdhResponse.data.datasets[2].data,
                borderColor: '#3357FF',
                backgroundColor: '#3357FF',
                pointRadius: 3,
                tension: 0.1
              },
              {
                label: 'Combined 6529 Ecosystem',
                data: tdhResponse.data.datasets[3].data,
                borderColor: '#FF33F5',
                backgroundColor: '#FF33F5',
                pointRadius: 3,
                tension: 0.1,
                borderWidth: 3
              }
            ]
          });
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching TDH distribution data:', error);
        setError('Failed to load TDH distribution data. Please try again later.');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // TDH Chart Options
  const tdhChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        grid: { color: 'rgba(255, 255, 255, 0.1)' },
        ticks: { 
          color: 'white',
          callback: function(value) {
            return value + '%';
          }
        },
        title: { display: true, text: 'Percentage of Total TDH', color: 'white', font: { size: 12 } }
      },
      x: {
        grid: { color: 'rgba(255, 255, 255, 0.1)' },
        ticks: {
          color: 'white',
          maxRotation: 45,
          minRotation: 45
        },
        title: { display: true, text: 'Date', color: 'white', font: { size: 12 } }
      }
    },
    plugins: {
      legend: { 
        labels: { 
          color: 'white',
          boxWidth: 40,
          padding: 15,
          font: {
            size: 11
          }
        },
        position: 'top',
        align: 'start'
      },
      title: {
        display: true,
        text: '6529 Ecosystem TDH Percentage',
        color: 'white',
        font: { size: 16 },
        padding: { bottom: 20 }
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            return `${context.dataset.label}: ${context.parsed.y}%`;
          }
        }
      }
    }
  };

  if (loading) {
    return <div className="tdh-distribution-container">Loading...</div>;
  }

  if (error) {
    return <div className="tdh-distribution-container error">{error}</div>;
  }

  return (
    <div className="tdh-distribution-container">
      <Helmet>
        <title>6529 Memes NFT TDH Network Distribution | Ecosystem Overview</title>
        <meta 
          name="description" 
          content="6529 Memes NFT TDH Distribution Tracker – Explore how TDH holding power is split across the 6529 ecosystem. Track punk6529 ecosystem TDH ownership"
        />
        <meta 
          property="og:title" 
          content="6529 Memes NFT TDH Network Distribution | Ecosystem Overview"
        />
        <meta 
          property="og:description" 
          content="6529 Memes NFT TDH Distribution Tracker – Explore how TDH holding power is split across the 6529 ecosystem. Track punk6529 ecosystem TDH ownership"
        />
        <meta property="og:image" content="https://6529stats.xyz/share-image.png" />
        <meta property="og:url" content="https://6529stats.xyz/tdh-distribution" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta 
          name="twitter:title" 
          content="6529 Memes NFT TDH Network Distribution | Ecosystem Overview"
        />
        <meta 
          name="twitter:description" 
          content="6529 Memes NFT TDH Distribution Tracker – Explore how TDH holding power is split across the 6529 ecosystem. Track punk6529 ecosystem TDH ownership"
        />
        <meta name="twitter:image" content="https://6529stats.xyz/share-image.png" />
      </Helmet>

      <h1 className="tdh-distribution-header">6529 Memes NFT Collection TDH & PPTDH Distribution Tracker</h1>
      
      {/* 6529 TDH Percentage Chart */}
      {tdhChartData && (
        <div className="tdh-chart-section">
          <h2 className="tdh-chart-title">6529 Ecosystem TDH Percentage</h2>
          <div className="tdh-chart-wrapper">
            <Line data={tdhChartData} options={tdhChartOptions} />
          </div>
          <div className="tdh-chart-notes">
            <p><strong>Note:</strong> The addresses represented in this chart are:</p>
            <ul>
              <li><strong>social.6529.eth,trove.punk6529.eth,punk6529:</strong> 0x6daa633c23615a29471deafae351727867e7dad1-0xa094ca13e855c3e926f9069ee140602ace421fe3-0xfd22004806a6846ea67ad883356be810f0428793</li>
              <li><strong>research.6529.eth:</strong> 0x32b8bce7cf8c6203a841b0d08bde5ae89f0af103-0xc2ce4ccef11a8171f443745cea3bceeaadd750c7</li>
              <li><strong>6529Museum:</strong> 0x924817154d311bd2de599f3d659d053ab0133e06-0xc6400a5584db71e41b0e5dfbdc769b54b91256cd</li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default TdhDistributionComponent; 