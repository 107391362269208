// MyPortfolioBidsComponent.js

import React, { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { ArrowUpDown, ChevronRight } from 'lucide-react';
import './MyPortfolioBidsComponent.css';
import { NavLink, useLocation } from 'react-router-dom';
import { fetchPortfolioBidsData } from './apiService';
import ReactPaginate from 'react-paginate';

const MyPortfolioBidsComponent = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const profileParam = queryParams.get('profile');

  // State variables
  const profile = profileParam || '';
  const [portfolioData, setPortfolioData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [lastUpdated, setLastUpdated] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [selectedSZN, setSelectedSZN] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 25;

  useEffect(() => {
    const handleFetchData = async () => {
      if (!profile) return;
      setLoading(true);
      setError('');
      try {
        const data = await fetchPortfolioBidsData(profile);
        setPortfolioData(data.data);
        setLastUpdated(new Date());
        setSelectedSZN('All');
      } catch (err) {
        setError('Error fetching portfolio bids data. Please try again.');
        console.error('Error details:', err);
      }
      setLoading(false);
    };

    handleFetchData();
  }, [profile]);

  const formatNumber = (num) => {
    return num.toLocaleString(undefined, { minimumFractionDigits: 4, maximumFractionDigits: 4 });
  };

  const formatDate = (date) => {
    const ukTime = date.toLocaleString('en-GB', {
      timeZone: 'Europe/London',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });

    const utcTime = date.toLocaleString('en-GB', {
      timeZone: 'UTC',
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });

    return `UK Time: ${ukTime}, UTC: ${utcTime}`;
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = useMemo(() => {
    let sortableItems = [...portfolioData];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        const isNumericField = ['ID', 'ValuationWithDepth', 'SeizedCount', 'TypeSeason'].includes(sortConfig.key);

        if (isNumericField) {
          const aNum = parseFloat(aValue) || 0;
          const bNum = parseFloat(bValue) || 0;
          return sortConfig.direction === 'ascending' ? aNum - bNum : bNum - aNum;
        } else {
          if (aValue < bValue) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (aValue > bValue) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        }
      });
    }
    return sortableItems;
  }, [portfolioData, sortConfig]);

  const filteredData = useMemo(() => {
    return sortedData.filter((item) =>
      (selectedSZN === 'All' || item.TypeSeason === selectedSZN) &&
      (searchTerm === '' || item.Name.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }, [sortedData, selectedSZN, searchTerm]);

  // Get paginated data
  const paginatedData = useMemo(() => {
    const startIndex = currentPage * itemsPerPage;
    return filteredData.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredData, currentPage, itemsPerPage]);

  // Handle page change
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const uniqueSZNs = useMemo(() => {
    const szns = new Set(portfolioData.map((item) => item.TypeSeason));
    return ['All', ...Array.from(szns).sort((a, b) => a - b)];
  }, [portfolioData]);

  const suggestions = useMemo(() => {
    if (searchTerm.length < 2) return [];
    const uniqueNames = new Set(portfolioData.map((item) => item.Name));
    return Array.from(uniqueNames)
      .filter((name) => name.toLowerCase().includes(searchTerm.toLowerCase()))
      .slice(0, 5);
  }, [portfolioData, searchTerm]);

  return (
    <div className="my-portfolio-bids-container">
      <Helmet>
        <title>6529 NFT Portfolio Bids | Full Depth & Liquidity Analysis</title>
        <meta 
          name="description" 
          content="Track the full bid depth for each 6529 Memes NFT you own. See multiple bids per token and refine your portfolio valuation using true market liquidity."
        />
        <meta 
          property="og:title" 
          content="6529 NFT Portfolio Bids | Full Depth & Liquidity Analysis"
        />
        <meta 
          property="og:description" 
          content="Track the full bid depth for each 6529 Memes NFT you own. See multiple bids per token and refine your portfolio valuation using true market liquidity."
        />
        <meta property="og:image" content="https://6529stats.xyz/share-image.png" />
        <meta property="og:url" content="https://6529stats.xyz/my-portfolio-bids" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta 
          name="twitter:title" 
          content="6529 NFT Portfolio Bids | Full Depth & Liquidity Analysis"
        />
        <meta 
          name="twitter:description" 
          content="Track the full bid depth for each 6529 Memes NFT you own. See multiple bids per token and refine your portfolio valuation using true market liquidity."
        />
        <meta name="twitter:image" content="https://6529stats.xyz/share-image.png" />
      </Helmet>

      <div className="my-portfolio-bids-page-header">
        <h1 className="my-portfolio-bids-main-header">6529 NFT Portfolio Bids | Full Depth & Liquidity Analysis</h1>
      </div>

      {loading && <p className="my-portfolio-bids-loading-message">Loading...</p>}
      {error && <p className="my-portfolio-bids-error-message">{error}</p>}

      {portfolioData.length > 0 && (
        <>
          <div className="my-portfolio-bids-filter-container">
            <div className="my-portfolio-bids-filter-section">
              <label htmlFor="szn-filter">SZN</label>
              <select
                id="szn-filter"
                value={selectedSZN}
                onChange={(e) => setSelectedSZN(e.target.value)}
              >
                {uniqueSZNs.map((szn) => (
                  <option key={szn} value={szn}>
                    {szn}
                  </option>
                ))}
              </select>
            </div>
            <div className="my-portfolio-bids-search-section">
              <label htmlFor="card-search">Search</label>
              <input
                id="card-search"
                type="text"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setShowSuggestions(true);
                }}
                onFocus={() => setShowSuggestions(true)}
                onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
                placeholder="Search cards..."
              />
              {showSuggestions && suggestions.length > 0 && (
                <ul className="my-portfolio-bids-suggestions-list">
                  {suggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      onMouseDown={() => {
                        setSearchTerm(suggestion);
                        setShowSuggestions(false);
                      }}
                    >
                      {suggestion}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          {/* Records count */}
          <div className="my-portfolio-bids-records-count">
            <span><strong>Number of Records:</strong> {filteredData.length}</span>
          </div>

          <table className="my-portfolio-bids-table">
            <thead>
              <tr>
                <th className="my-portfolio-bids-token-no" onClick={() => handleSort('ID')}>
                  Token No <ArrowUpDown className="my-portfolio-bids-inline-icon" />
                </th>
                <th className="my-portfolio-bids-card-name">Card Name</th>
                <th className="my-portfolio-bids-szn" onClick={() => handleSort('TypeSeason')}>
                  SZN <ArrowUpDown className="my-portfolio-bids-inline-icon" />
                </th>
                <th className="my-portfolio-bids-seized-count" onClick={() => handleSort('SeizedCount')}>
                  Seized Count <ArrowUpDown className="my-portfolio-bids-inline-icon" />
                </th>
                <th className="my-portfolio-bids-valuation" onClick={() => handleSort('ValuationWithDepth')}>
                  Valuation (Bids Price With Depth) <ArrowUpDown className="my-portfolio-bids-inline-icon" />
                </th>
                <th className="my-portfolio-bids-bids-used">Bids Used</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((item) => (
                <tr key={item.ID}>
                  <td className="my-portfolio-bids-token-no">{item.ID}</td>
                  <td className="my-portfolio-bids-card-name">
                    <NavLink
                      to={`/token/${item.ID}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="my-portfolio-bids-name-link"
                    >
                      {item.Name} <ChevronRight className="my-portfolio-bids-inline-icon" />
                    </NavLink>
                  </td>
                  <td className="my-portfolio-bids-szn">{item.TypeSeason}</td>
                  <td className="my-portfolio-bids-seized-count">{item.SeizedCount}</td>
                  <td className="my-portfolio-bids-valuation">{formatNumber(item.ValuationWithDepth)}</td>
                  <td className="my-portfolio-bids-bids-used">
                    {item.BidsForValuation.map((bid, index) => (
                      <div key={index} className="my-portfolio-bids-bid-item">
                        {bid.source_icon && (
                          bid.source_url ? (
                            <a href={bid.source_url} target="_blank" rel="noopener noreferrer">
                              <img
                                src={bid.source_icon}
                                alt={bid.source_name || 'Source'}
                                className="my-portfolio-bids-source-icon"
                              />
                            </a>
                          ) : (
                            <img
                              src={bid.source_icon}
                              alt={bid.source_name || 'Source'}
                              className="my-portfolio-bids-source-icon"
                            />
                          )
                        )}
                        <span className="my-portfolio-bids-bid-text">
                          Price: {formatNumber(bid.price_decimal)}, Quantity: {bid.quantity}
                        </span>
                      </div>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="my-portfolio-bids-pagination">
            <ReactPaginate
              previousLabel={'← Previous'}
              nextLabel={'Next →'}
              breakLabel={'...'}
              pageCount={Math.ceil(filteredData.length / itemsPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'my-portfolio-bids-pagination'}
              activeClassName={'active'}
            />
          </div>

          <div className="my-portfolio-bids-explanation-box">
            <p>
              <strong>Note:</strong> This table lists all the bids that made up your Valuation (Bids Price With
              Depth) for each token you own.
            </p>
            {lastUpdated && (
              <>
                <p>
                  <strong>Prices Last Updated at:</strong> {formatDate(lastUpdated)}
                </p>
                <p>
                  <strong>** Price data updated approx every 2 minutes **</strong>
                </p>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MyPortfolioBidsComponent;
