import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
// import { CSVLink } from 'react-csv'; // CSV download disabled
import { ArrowUpDown, ChevronRight, ChevronDown } from 'lucide-react';
import './SalesBotAllTimeComponent.css';
import { NavLink } from 'react-router-dom'; // Import NavLink
import { fetchSalesBotAllTimeData, getCompactImageUrl } from './apiService'; // Added import
import ReactPaginate from 'react-paginate'; // Added import

// Import images (ensure these files are inside src/assets/)
import etherscanIcon from './assets/etherscan.png';
import openseaIcon from './assets/openseaIcon.png';
import seizeIcon from './assets/seizeIcon.png';

// Utility function to capitalize first letter
const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

function SalesBotAllTimeComponent() {
  // State variables
  const [salesData, setSalesData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'createdat', direction: 'desc' });
  const [selectedSaleType, setSelectedSaleType] = useState('All');
  const [selectedMemeName, setSelectedMemeName] = useState('All');
  const [selectedSZN, setSelectedSZN] = useState('All');
  const [selectedSupply, setSelectedSupply] = useState('');
  const [supplyCompare, setSupplyCompare] = useState('gt');
  const [searchTerm, setSearchTerm] = useState('');
  const [addressSearchTerm, setAddressSearchTerm] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 25;
  const [totalRecords, setTotalRecords] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [showFilters, setShowFilters] = useState(false);

  // List of meme names
  const memeNames = [
    'Freedom to Transact',
    'Brain',
    'Open Metaverse / OM',
    'WAGMI',
    'Survive',
    'GM',
    'Seize the Memes of Production',
    "Don't Let the Institutions Steal Your JPGs",
    'Use a Hardware Wallet',
    'Freestyle',
    'Not Your Keys, Not Your Coins',
    'Digital Rights Charter',
    'BUIDL',
    'TAP',
    'Summer.jpg',
    // Add other meme names here
  ];

  // Fetch data from backend
  const fetchData = async () => {
    try {
      const response = await fetchSalesBotAllTimeData({
        page: currentPage + 1,
        pageSize: itemsPerPage,
        saleType: selectedSaleType !== 'All' ? selectedSaleType : undefined,
        memeName: selectedMemeName !== 'All' ? selectedMemeName : undefined,
        szn: selectedSZN !== 'All' ? selectedSZN : undefined,
        supply: selectedSupply !== '' ? selectedSupply : undefined,
        supplyCompare: supplyCompare,
        searchTerm: searchTerm !== '' ? searchTerm : undefined,
        addressSearchTerm: addressSearchTerm !== '' ? addressSearchTerm : undefined,
        startDate: startDate !== '' ? startDate : undefined,
        endDate: endDate !== '' ? endDate : undefined,
        sortKey: sortConfig.key,
        sortDirection: sortConfig.direction,
      });
      setSalesData(response.data);
      setTotalRecords(response.totalCount);
    } catch (error) {
      console.error('Error fetching sales bot data:', error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    selectedSaleType,
    selectedMemeName,
    selectedSZN,
    selectedSupply,
    supplyCompare,
    searchTerm,
    addressSearchTerm,
    sortConfig,
  ]);

  // Implement sorting
  const sortTable = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    setCurrentPage(0); // Reset to first page when sorting changes
  };

  // Handle page change
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    return new Date(dateString).toLocaleString(undefined, options);
  };

  // Prepare data for CSV download (disabled)
  /*
  const csvData = useMemo(() => {
    const headers = [
      { label: "Date", key: "createdat" },
      { label: "Name", key: "name" },
      { label: "Sale Type", key: "orderside" },
      { label: "Amount (ETH)", key: "decimal" },
      { label: "Amount (USD)", key: "usd" },
      { label: "Meme Name", key: "memename" },
      { label: "SZN", key: "typeseason" },
      { label: "Seller", key: "from_address" },
      { label: "Buyer", key: "to_address" },
      { label: "Tx Hash", key: "txhash" },
    ];

    const data = salesData.map(item => ({
      ...item,
      orderside: capitalizeFirstLetter(item.orderside),
      decimal: parseFloat(item.decimal).toFixed(5),
      usd: parseFloat(item.usd).toFixed(2),
    }));

    return {
      headers,
      data,
    };
  }, [salesData]);
  */

  // Handle Search button click
  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(0);
    fetchData();
  };

  // Handle Clear Search button click
  const handleClearSearch = () => {
    setStartDate('');
    setEndDate('');
    setSelectedMemeName('All');
    setSelectedSaleType('All');
    setSelectedSZN('All');
    setSelectedSupply('');
    setSupplyCompare('gt');
    setSearchTerm('');
    setAddressSearchTerm('');
    setCurrentPage(0);
    fetchData();
  };

  return (
    <div className="sales-bot-all-time">
      <Helmet>
        <title>6529 Memes NFT Sales Tracker | Full Sales History & Live Updates</title>
        <meta 
          name="description" 
          content="6529 Memes NFT Sales Tracker – A real-time record of every sale. View recent transactions instantly or analyse sales data with advanced filtering tools."
        />
        <meta 
          property="og:title" 
          content="6529 Memes NFT Sales Tracker | Full Sales History & Live Updates"
        />
        <meta 
          property="og:description" 
          content="6529 Memes NFT Sales Tracker – A real-time record of every sale. View recent transactions instantly or analyse sales data with advanced filtering tools."
        />
        <meta property="og:image" content="https://6529stats.xyz/share-image.png" />
        <meta property="og:url" content="https://6529stats.xyz/sales" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta 
          name="twitter:title" 
          content="6529 Memes NFT Sales Tracker | Full Sales History & Live Updates"
        />
        <meta 
          name="twitter:description" 
          content="6529 Memes NFT Sales Tracker – A real-time record of every sale. View recent transactions instantly or analyse sales data with advanced filtering tools."
        />
        <meta name="twitter:image" content="https://6529stats.xyz/share-image.png" />
      </Helmet>

      <h1 className="sales-bot-title">6529 Memes NFT Complete Sales History Tracker</h1>

      <button 
        className="filter-toggle-button"
        onClick={() => setShowFilters(!showFilters)}
      >
        Search & Filters
        <ChevronDown className={`chevron-icon ${showFilters ? 'open' : ''}`} />
      </button>

      <form className={`sales-bot-filters-form ${showFilters ? 'show' : ''}`} onSubmit={handleSearch}>
        {/* First row of filters */}
        <div className="sales-bot-filters-row">
          <div className="sales-bot-filter-item">
            <label htmlFor="sale-type-filter">Sale Type</label>
            <select
              id="sale-type-filter"
              value={selectedSaleType}
              onChange={(e) => {
                setSelectedSaleType(e.target.value);
              }}
            >
              <option value="All">All</option>
              <option value="ask">Ask</option>
              <option value="bid">Bid</option>
            </select>
          </div>
          <div className="sales-bot-filter-item">
            <label htmlFor="meme-name-filter">Meme Name</label>
            <select
              id="meme-name-filter"
              value={selectedMemeName}
              onChange={(e) => {
                setSelectedMemeName(e.target.value);
              }}
            >
              <option value="All">All</option>
              {memeNames.map((name, index) => (
                <option key={index} value={name}>{name}</option>
              ))}
            </select>
          </div>
          <div className="sales-bot-filter-item">
            <label htmlFor="szn-filter">SZN</label>
            <select
              id="szn-filter"
              value={selectedSZN}
              onChange={(e) => {
                setSelectedSZN(e.target.value);
              }}
            >
              <option value="All">All</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
            </select>
          </div>
          <div className="sales-bot-filter-item">
            <label htmlFor="supply-filter">Supply Filter</label>
            <div className="supply-value-filter">
              <select
                id="supply-compare-filter"
                value={supplyCompare}
                onChange={(e) => {
                  setSupplyCompare(e.target.value);
                  setCurrentPage(0);
                }}
                className="sales-bot-filter-select"
              >
                <option value="gt">Greater than</option>
                <option value="lt">Less than</option>
                <option value="eq">Equal to</option>
              </select>
              <input
                type="number"
                id="supply-filter"
                min="0"
                placeholder="Enter Supply value"
                value={selectedSupply}
                onChange={(e) => {
                  setSelectedSupply(e.target.value);
                  setCurrentPage(0);
                }}
                className="sales-bot-filter-input"
              />
            </div>
          </div>
        </div>

        {/* Second row of filters */}
        <div className="sales-bot-filters-row">
          <div className="sales-bot-filter-item">
            <label htmlFor="start-date-filter">Start Date</label>
            <input
              type="date"
              id="start-date-filter"
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
            />
          </div>
          <div className="sales-bot-filter-item">
            <label htmlFor="end-date-filter">End Date</label>
            <input
              type="date"
              id="end-date-filter"
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
            />
          </div>
        </div>

        {/* Third row of filters */}
        <div className="sales-bot-filters-row">
          <div className="sales-bot-filter-item">
            <label htmlFor="search-input">Search Name</label>
            <input
              type="text"
              id="search-input"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setShowSuggestions(true);
                setCurrentPage(0);
              }}
              onFocus={() => setShowSuggestions(true)}
              onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
              placeholder="Enter name to search"
            />
            {showSuggestions && searchTerm.length >= 2 && (
              <ul className="sales-bot-suggestions-list">
                {salesData
                  .map(item => item.name)
                  .filter((name, index, self) => name.toLowerCase().includes(searchTerm.toLowerCase()) && self.indexOf(name) === index)
                  .slice(0, 5)
                  .map((suggestion, index) => (
                    <li
                      key={index}
                      onMouseDown={() => {
                        setSearchTerm(suggestion);
                        setShowSuggestions(false);
                        setCurrentPage(0);
                      }}
                    >
                      {suggestion}
                    </li>
                  ))}
              </ul>
            )}
          </div>
          <div className="sales-bot-filter-item">
            <label htmlFor="address-search-input">Address Search</label>
            <input
              type="text"
              id="address-search-input"
              value={addressSearchTerm}
              onChange={(e) => {
                setAddressSearchTerm(e.target.value);
                setCurrentPage(0);
              }}
              placeholder="Enter Ethereum address"
            />
          </div>
        </div>

        {/* Buttons and record count */}
        <div className="sales-bot-action-buttons-row">
          <div className="sales-bot-buttons-group">
            <button type="submit">Search</button>
            <button type="button" onClick={handleClearSearch}>Clear Search</button>
          </div>
          <div className="sales-bot-records-and-download">
            <span><strong>Number of Records:</strong> {totalRecords}</span>
            {/* CSV Download button disabled */}
            {/*
            <CSVLink
              data={csvData.data}
              headers={csvData.headers}
              filename={`sales_bot_all_time.csv`}
              className="btn btn-primary"
            >
              Download CSV
            </CSVLink>
            */}
          </div>
        </div>
      </form>

      {/* Table */}
      <table className="sales-bot-table">
        <thead>
          <tr>
            {/* Sortable Columns */}
            <th onClick={() => sortTable('createdat')}>
              <div className="sales-bot-header-content">
                Date
                <ArrowUpDown
                  className={`sales-bot-sort-icon ${sortConfig.key === 'createdat' ? (sortConfig.direction === 'asc' ? 'sales-bot-sorted-asc' : 'sales-bot-sorted-desc') : ''}`}
                />
              </div>
            </th>
            {/* Non-Sortable Columns */}
            <th>Image</th>
            <th>Name</th>
            {/* Sortable Columns */}
            <th onClick={() => sortTable('orderside')}>
              <div className="sales-bot-header-content">
                Sale Type
                <ArrowUpDown
                  className={`sales-bot-sort-icon ${sortConfig.key === 'orderside' ? (sortConfig.direction === 'asc' ? 'sales-bot-sorted-asc' : 'sales-bot-sorted-desc') : ''}`}
                />
              </div>
            </th>
            <th onClick={() => sortTable('decimal')}>
              <div className="sales-bot-header-content">
                Amount (ETH)
                <ArrowUpDown
                  className={`sales-bot-sort-icon ${sortConfig.key === 'decimal' ? (sortConfig.direction === 'asc' ? 'sales-bot-sorted-asc' : 'sales-bot-sorted-desc') : ''}`}
                />
              </div>
            </th>
            <th onClick={() => sortTable('usd')}>
              <div className="sales-bot-header-content">
                Amount (USD)
                <ArrowUpDown
                  className={`sales-bot-sort-icon ${sortConfig.key === 'usd' ? (sortConfig.direction === 'asc' ? 'sales-bot-sorted-asc' : 'sales-bot-sorted-desc') : ''}`}
                />
              </div>
            </th>
            <th onClick={() => sortTable('memename')}>
              <div className="sales-bot-header-content">
                Meme Name
                <ArrowUpDown
                  className={`sales-bot-sort-icon ${sortConfig.key === 'memename' ? (sortConfig.direction === 'asc' ? 'sales-bot-sorted-asc' : 'sales-bot-sorted-desc') : ''}`}
                />
              </div>
            </th>
            <th onClick={() => sortTable('typeseason')}>
              <div className="sales-bot-header-content">
                SZN
                <ArrowUpDown
                  className={`sales-bot-sort-icon ${sortConfig.key === 'typeseason' ? (sortConfig.direction === 'asc' ? 'sales-bot-sorted-asc' : 'sales-bot-sorted-desc') : ''}`}
                />
              </div>
            </th>
            <th onClick={() => sortTable('supply')}>
              <div className="sales-bot-header-content">
                Supply
                <ArrowUpDown
                  className={`sales-bot-sort-icon ${sortConfig.key === 'supply' ? (sortConfig.direction === 'asc' ? 'sales-bot-sorted-asc' : 'sales-bot-sorted-desc') : ''}`}
                />
              </div>
            </th>
            {/* Non-Sortable Columns */}
            <th>Seller</th>
            <th>Buyer</th>
            <th>Tx</th>
            <th>Opensea</th>
            <th>Seize</th>
          </tr>
        </thead>
        <tbody>
          {salesData.map((item, index) => (
            <tr key={index}>
              <td>{formatDate(item.createdat)}</td>
              <td>
                <img
                  src={getCompactImageUrl(item)}
                  alt={item.name}
                  className="sales-bot-item-image"
                />
              </td>
              <td>
                <NavLink
                  to={`/token/${item.tokenid}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="sales-bot-name-link"
                >
                  {item.name} <ChevronRight className="sales-bot-chevron-icon" />
                </NavLink>
              </td>
              <td>{capitalizeFirstLetter(item.orderside)}</td>
              <td>{parseFloat(item.decimal).toFixed(5)}</td>
              <td>${parseFloat(item.usd).toFixed(2)}</td>
              <td>{item.memename}</td>
              <td>{item.typeseason}</td>
              <td>{item.supply}</td>
              <td>
                <a
                  href={`https://6529.io/${item.from_address}/identity`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="sales-bot-name-link"
                >
                  {item.from_address.slice(0, 6)}...{item.from_address.slice(-4)}
                  <ChevronRight className="sales-bot-chevron-icon" />
                </a>
              </td>
              <td>
                <a
                  href={`https://6529.io/${item.to_address}/identity`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="sales-bot-name-link"
                >
                  {item.to_address.slice(0, 6)}...{item.to_address.slice(-4)}
                  <ChevronRight className="sales-bot-chevron-icon" />
                </a>
              </td>
              <td>
                <a
                  href={`https://etherscan.io/tx/${item.txhash}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={etherscanIcon} alt="Etherscan" className="sales-bot-icon-image" />
                </a>
              </td>
              <td>
                <a
                  href={`https://opensea.io/assets/ethereum/${item.contract}/${item.tokenid}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={openseaIcon} alt="Opensea" className="sales-bot-icon-image" />
                </a>
              </td>
              <td>
                <a
                  href={`https://seize.io/the-memes/${item.tokenid}?focus=live`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={seizeIcon} alt="Seize" className="sales-bot-icon-image" />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="sales-bot-pagination">
        <ReactPaginate
          previousLabel={'← Previous'}
          nextLabel={'Next →'}
          breakLabel={'...'}
          pageCount={Math.ceil(totalRecords / itemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'sales-bot-pagination'}
          activeClassName={'active'}
        />
      </div>
    </div>
  );
}

export default SalesBotAllTimeComponent;
