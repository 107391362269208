import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { calculateTDH, searchConsolidationTDH } from './apiService';
import './TdhCalculatorComponent.css';

const TdhCalculatorComponent = () => {
    const [consolidationTokenIds, setConsolidationTokenIds] = useState('');
    const [calculatorTokenIds, setCalculatorTokenIds] = useState('');
    const [targetTDH, setTargetTDH] = useState('');
    const [address, setAddress] = useState('');
    const [result, setResult] = useState(null);
    const [consolidationResult, setConsolidationResult] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleCalculate = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            // Parse token IDs with optional quantities and track quantities
            const tokenIdArray = [];
            const tokenQuantities = {};
            const entries = calculatorTokenIds
                .split(',')
                .map(entry => entry.trim())
                .filter(entry => entry !== '');

            for (const entry of entries) {
                let id, qty;
                if (entry.includes(':')) {
                    const [idStr, qtyStr] = entry.split(':').map(s => s.trim());
                    id = parseInt(idStr);
                    qty = parseInt(qtyStr);
                    if (isNaN(id) || id <= 0 || isNaN(qty) || qty <= 0) {
                        throw new Error(`Invalid token ID or quantity: ${entry}`);
                    }
                } else {
                    id = parseInt(entry);
                    qty = 1;
                    if (isNaN(id) || id <= 0) {
                        throw new Error(`Invalid token ID: ${entry}`);
                    }
                }
                tokenQuantities[id] = (tokenQuantities[id] || 0) + qty;
                for (let i = 0; i < qty; i++) {
                    tokenIdArray.push(id);
                }
            }

            if (tokenIdArray.length === 0) {
                throw new Error('Please enter at least one token ID');
            }

            const targetTDHNum = parseFloat(targetTDH);
            if (isNaN(targetTDHNum) || targetTDHNum <= 0) {
                throw new Error('Please enter a valid target TDH value greater than 0');
            }

            // Calculate TDH with unique token IDs
            const uniqueTokenIds = [...new Set(tokenIdArray)];
            const response = await calculateTDH(uniqueTokenIds);
            if (!response.success) {
                throw new Error(response.error || 'Failed to calculate TDH');
            }

            const { tokenDetails } = response.data;

            // Group token details using the quantities from input
            const groupedTokens = {};
            tokenDetails.forEach(token => {
                const { ID, Name, HodlRate } = token;
                const quantity = tokenQuantities[ID] || 0;
                groupedTokens[ID] = {
                    ID,
                    Name,
                    quantity,
                    totalHodlRate: HodlRate * quantity
                };
            });

            // Calculate the correct totalHodlRate
            const totalHodlRate = Object.values(groupedTokens).reduce(
                (sum, token) => sum + token.totalHodlRate,
                0
            );

            const daysToAchieve = totalHodlRate > 0 ? Math.ceil(targetTDHNum / totalHodlRate) : Infinity;

            setResult({
                groupedTokenDetails: Object.values(groupedTokens),
                totalHodlRate,
                daysToAchieve,
                targetTDH: targetTDHNum
            });
        } catch (err) {
            setError(err.message);
            setResult(null);
        } finally {
            setLoading(false);
        }
    };

    const handleConsolidationSearch = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            if (!address) {
                throw new Error('Please enter an Ethereum address');
            }

            const tokenIdArray = consolidationTokenIds
                .split(',')
                .map(id => id.trim())
                .filter(id => id !== '')
                .map(id => {
                    const parsed = parseInt(id);
                    if (isNaN(parsed)) throw new Error(`Invalid token ID: ${id}`);
                    return parsed;
                });

            if (tokenIdArray.length === 0) {
                throw new Error('Please enter at least one token ID');
            }

            const response = await searchConsolidationTDH(address, tokenIdArray);
            if (!response.success) {
                throw new Error(response.error || 'Failed to search consolidation TDH');
            }

            setConsolidationResult(response.data);
            setTargetTDH(response.data.totalBoostedTDH.toString());
        } catch (err) {
            setError(err.message);
            setConsolidationResult(null);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="tdh-calculator-container">
            <Helmet>
                <title>6529 Memes NFT TDH Calculator | TDH Loss & Recovery Tool</title>
                <meta 
                    name="description" 
                    content="6529 Memes NFT TDH Calculator – Track how much TDH is burned when you sell and how long it will take to earn it back through new purchases."
                />
                <meta 
                    property="og:title" 
                    content="6529 Memes NFT TDH Calculator | TDH Loss & Recovery Tool"
                />
                <meta 
                    property="og:description" 
                    content="6529 Memes NFT TDH Calculator – Track how much TDH is burned when you sell and how long it will take to earn it back through new purchases."
                />
                <meta property="og:image" content="https://6529stats.xyz/share-image.png" />
                <meta property="og:url" content="https://6529stats.xyz/tdh-calculator" />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta 
                    name="twitter:title" 
                    content="6529 Memes NFT TDH Calculator | TDH Loss & Recovery Tool"
                />
                <meta 
                    name="twitter:description" 
                    content="6529 Memes NFT TDH Calculator – Track how much TDH is burned when you sell and how long it will take to earn it back through new purchases."
                />
                <meta name="twitter:image" content="https://6529stats.xyz/share-image.png" />
            </Helmet>

            <h1>6529 Memes NFT TDH Calculator – Track TDH Gains & Losses</h1>
            
            <div className="intro-box">
                <div className="step-container">
                    <div className="step">
                        <h3>Step 1: Calculate TDH Loss</h3>
                        <p>
                            Enter your 6529 profile address and token numbers below to see how much TDH you will lose from selling.
                        </p>
                    </div>
                    <div className="step">
                        <h3>Step 2: Plan TDH Recovery</h3>
                        <p>
                            Enter the token numbers you plan to buy to calculate how long it will take to recover your TDH.
                        </p>
                    </div>
                </div>
            </div>

            {/* TDH Loss Calculator Section */}
            <div className="calculator-section">
                <h2>Calculate TDH Loss</h2>
                <form onSubmit={handleConsolidationSearch}>
                    <div className="form-group">
                        <label htmlFor="address">Your 6529 Profile Address:</label>
                        <input
                            type="text"
                            id="address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            placeholder="Enter your 6529 profile address"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="consolidationTokenIds">Tokens Being Sold (Token numbers):</label>
                        <input
                            type="text"
                            id="consolidationTokenIds"
                            value={consolidationTokenIds}
                            onChange={(e) => setConsolidationTokenIds(e.target.value)}
                            placeholder="Enter token numbers, separated by commas (e.g., 1,2,3)"
                        />
                    </div>
                    <button type="submit" disabled={loading}>
                        {loading ? 'Calculating...' : 'Calculate TDH Loss'}
                    </button>
                </form>

                {consolidationResult && (
                    <div className="results">
                        <h3>TDH Loss Breakdown</h3>
                        <div className="token-details">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Token No</th>
                                        <th>Current TDH</th>
                                        <th>Quantity</th>
                                        <th>Daily TDH Rate</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {consolidationResult.tokens.map(token => (
                                        <tr key={token.token_id}>
                                            <td>{token.token_id}</td>
                                            <td>{Math.round(parseFloat(token.token_tdh_boosted))}</td>
                                            <td>{token.token_balance}</td>
                                            <td>{parseFloat(token.hodl_rate).toFixed(4)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="summary">
                            <p><strong>Total TDH Loss:</strong> {Math.round(consolidationResult.totalBoostedTDH)}</p>
                        </div>
                        <div className="important-notice">
                            <p>
                                <strong>Important: Multiple Token Holdings</strong><br />
                                If you're selling only part of your holdings, note that individual token TDH varies based on how long each token has been held. 
                                The TDH shown above is the total for your entire balance - selling partial amounts will require manual TDH calculations per token.
                            </p>
                        </div>
                    </div>
                )}
            </div>

            {/* TDH Recovery Calculator Section */}
            <div className="calculator-section">
                <h2>Plan TDH Recovery</h2>
                <form onSubmit={handleCalculate}>
                    <div className="form-group">
                        <label htmlFor="calculatorTokenIds">Tokens to Buy (Token numbers):</label>
                        <input
                            type="text"
                            id="calculatorTokenIds"
                            value={calculatorTokenIds}
                            onChange={(e) => setCalculatorTokenIds(e.target.value)}
                            placeholder="Enter token numbers with optional quantities, e.g., 244,111:2,82:3"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="targetTDH">TDH to Recover:</label>
                        <input
                            type="number"
                            id="targetTDH"
                            value={targetTDH}
                            onChange={(e) => setTargetTDH(e.target.value)}
                            placeholder="Enter target TDH amount"
                            min="0"
                            step="any"
                        />
                        <span className="input-helper-text">
                            Auto-filled with TDH loss from above, but you can adjust this target as needed
                        </span>
                    </div>
                    <button type="submit" disabled={loading}>
                        {loading ? 'Calculating...' : 'Calculate Recovery Time'}
                    </button>
                </form>

                {error && <div className="error-message">{error}</div>}

                {result && (
                    <div className="results">
                        <h3>Recovery Plan</h3>
                        <div className="token-details">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Token ID</th>
                                        <th>Name</th>
                                        <th>Quantity</th>
                                        <th>Total Daily TDH Rate</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {result.groupedTokenDetails.map(token => (
                                        <tr key={token.ID}>
                                            <td>{token.ID}</td>
                                            <td>{token.Name}</td>
                                            <td>{token.quantity}</td>
                                            <td>{token.totalHodlRate.toFixed(4)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="summary">
                            <p><strong>Combined Daily TDH Rate:</strong> {result.totalHodlRate.toFixed(4)}</p>
                            <p><strong>Target TDH:</strong> {result.targetTDH}</p>
                            <p><strong>Time to Recover:</strong> {
                                result.daysToAchieve === Infinity 
                                    ? 'Not possible with selected tokens'
                                    : `${result.daysToAchieve} days`
                            }</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TdhCalculatorComponent;