import React, { useState } from 'react';
import './Artist306Component.css';
// Import icons
import foundationIcon from '../assets/foundationIcon.jpeg';
import superrareIcon from '../assets/superrareIcon.png';
import xIcon from '../assets/XIcon.png';
import seizeIcon from '../assets/seizeIcon.png';
import openseaIcon from '../assets/openseaIcon.png';
// Import baiwei image
import baiweiImage from '../assets/306_sale_pfp.png';
// Import meme card cover image
import memeCardCover from '../assets/306_still_cover.jpeg';
// Import Exchange Art logo
import exchangeArtLogo from '../assets/ExchangeArt_Logo2022-07.png';

function Artist306Component() {
    // State for tracking which sections are expanded (initial state: collapsed)
    const [expandedSections, setExpandedSections] = useState({
        featuredWorks: false,
        memeCard: false,
        provenanceSeals: false
    });

    // Toggle section expansion
    const toggleSection = (section) => {
        setExpandedSections(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    return (
        <div className="artist-306-container">
            <h1 className="artist-306-title">Artist Profile: <span style={{ color: 'red' }}>Baiwei</span></h1>
            <div className="artist-306-content">
                <div className="artist-306-section">
                    <div className="artist-306-about">
                        <div className="artist-306-about-image">
                            <img src={baiweiImage} alt="baiwei" />
                        </div>
                        <div className="artist-306-about-text">
                            <h2>About baiwei</h2>
                            <p>Provenancer, cryptochronicler, reviving ancient Asian provenance tradition on the blockchain. I retrieve Art from data, unveiling meaning beyond the cycles of gain and loss.</p>
                        </div>
                    </div>
                </div>
                
                <div className="artist-306-section">
                    <div className="artist-306-meme-header">
                        <div className="artist-306-meme-cover">
                            <img 
                                src={memeCardCover} 
                                alt="Buidl Value Meme Card Cover" 
                                onClick={() => toggleSection('memeCard')}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                        <div className="artist-306-meme-intro">
                            <h2 
                                style={{ color: 'red', cursor: 'pointer' }}
                                onClick={() => toggleSection('memeCard')}
                                className="collapsible-header"
                            >
                                "Buidl Value" Meme Card
                                <span className={`chevron ${expandedSections.memeCard ? 'expanded' : ''}`}>▼</span>
                            </h2>
                            <p className="artist-306-meme-description">
                            A summary of 9 SZNs of Meme Cards, fusing meme with high art. Also, a provanance experiment
                            </p>
                            <button 
                                className="artist-306-view-now-button"
                                onClick={() => toggleSection('memeCard')}
                            >
                                More
                            </button>
                        </div>
                    </div>
                    
                    {expandedSections.memeCard && (
                        <div className="artist-306-meme-content">
                            <div className="artist-306-meme-video">
                                <video 
                                    controls 
                                    autoPlay
                                    muted
                                    loop
                                    width="100%"
                                    className="meme-video"
                                    playsInline
                                    preload="metadata"
                                >
                                    <source 
                                        src="https://artist-profiles.s3.eu-north-1.amazonaws.com/306/BuidlValueUpdated.MP4" 
                                        type="video/mp4" 
                                    />
                                    Your browser does not support the video tag.
                                </video>
                                <div className="artist-306-opensea-link">
                                    <a 
                                        href="https://opensea.io/assets/ethereum/0x33fd426905f149f8376e227d0c9d3340aad17af1/306" 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                    >
                                        <img src={openseaIcon} alt="OpenSea" className="opensea-icon" />
                                        <span>View The Meme Card</span>
                                    </a>
                                </div>
                                <div className="artist-306-meme-poem">
                                    <p>
                                        By boat carried - meme<br />
                                        Up through the seasons<br />
                                        In peaks and valleys hidden<br />
                                        Data ethereal - for provenance<br />
                                        Legacy to unfold
                                    </p>
                                </div>
                                <div className="artist-306-meme-description-full">
                                    <p>
                                        In "Buidl Value", 6529 journeys through a landscape based on the historic ETH trending data from
                                        9 consecutive seasons of Meme Cards (Jun '22 - Nov '24). The scroll unfolds slowly,
                                        accompanied by music evoking the reverence of classical Chinese art and the deliberate,
                                        meditative process of its creation. It becomes a metaphor for building with calm persistence,
                                        regardless of market ux. This ancient "meme" draws inspiration from Chinese painting, where
                                        poetry and symbols encrypt meaning across multiple layers - from personal to philosophical. The
                                        scroll opens and closes with provenance seals and custom calligraphy (English stylized into
                                        characters), conceptually bridging ancient artistic traditions with cryptoart. "Buidl Value"
                                        reimagines the blockchain legacy of 6529 movement in the timeless spirit of literati culture.
                                    </p>
                                </div>
                                <div className="artist-306-opensea-link">
                                    <a 
                                        href="https://opensea.io/assets/ethereum/0x33fd426905f149f8376e227d0c9d3340aad17af1/306" 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                    >
                                        <img src={openseaIcon} alt="OpenSea" className="opensea-icon" />
                                        <span>View The Meme Card</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                
                <div className="artist-306-section">
                    <div className="artist-306-meme-header">
                        <div className="artist-306-meme-cover">
                            <img 
                                src="https://artist-profiles.s3.eu-north-1.amazonaws.com/306/ProvenanceSeals.gif" 
                                alt="Provenance Seals" 
                                onClick={() => toggleSection('provenanceSeals')}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                        <div className="artist-306-meme-intro">
                            <h2 
                                style={{ color: 'red', cursor: 'pointer' }}
                                onClick={() => toggleSection('provenanceSeals')}
                                className="collapsible-header"
                            >
                                Provenance Seals
                                <span className={`chevron ${expandedSections.provenanceSeals ? 'expanded' : ''}`}>▼</span>
                            </h2>
                            <p className="artist-306-meme-description">
                                An ancient Asian tradition revived on blockchain.
                            </p>
                            <button 
                                className="artist-306-view-now-button"
                                onClick={() => toggleSection('provenanceSeals')}
                            >
                                More
                            </button>
                        </div>
                    </div>
                    
                    {expandedSections.provenanceSeals && (
                        <div className="artist-306-meme-content">
                            <div className="artist-306-seal-image">
                                <img 
                                    src="https://artist-profiles.s3.eu-north-1.amazonaws.com/306/SealsMemeCards.jpg" 
                                    alt="Seals Meme Cards" 
                                    loading="lazy"
                                />
                            </div>
                            
                            <div className="artist-306-meme-description-full">
                                <p>
                                    Signature seals symbolize provenance in Asian art tradition. Originating in ancient times,
                                    sealmaking is a sophisticated art form. Similar to blockchain transaction, on-art imprint provides a
                                    transparent record across time and adds value to the original artwork.
                                </p>
                            </div>
                            
                            <div className="artist-306-opensea-link">
                                <a 
                                    href="https://opensea.io/collection/baiweiseals" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >
                                    <img src={openseaIcon} alt="OpenSea" className="opensea-icon" />
                                    <span>View Complete Seal Collection</span>
                                </a>
                            </div>
                            
                            <div className="artist-306-seal-image">
                                <img 
                                    src="https://artist-profiles.s3.eu-north-1.amazonaws.com/306/ProvenanceSeals.gif" 
                                    alt="Provenance Seals GIF"
                                    loading="lazy"
                                />
                            </div>
                            
                            <div className="artist-306-opensea-link">
                                <a 
                                    href="https://opensea.io/collection/baiweiseals" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >
                                    <img src={openseaIcon} alt="OpenSea" className="opensea-icon" />
                                    <span>View Complete Seal Collection</span>
                                </a>
                            </div>
                        </div>
                    )}
                </div>

                <div className="artist-306-section">
                    <div className="artist-306-meme-header">
                        <div className="artist-306-meme-cover">
                            <img 
                                src="https://artist-profiles.s3.eu-north-1.amazonaws.com/306/MemoirsOfBull02.jpg" 
                                alt="Datascapes" 
                                onClick={() => toggleSection('featuredWorks')}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                        <div className="artist-306-meme-intro">
                            <h2 
                                style={{ color: 'red', cursor: 'pointer' }}
                                onClick={() => toggleSection('featuredWorks')}
                                className="collapsible-header"
                            >
                                Datascapes
                                <span className={`chevron ${expandedSections.featuredWorks ? 'expanded' : ''}`}>▼</span>
                            </h2>
                            <p className="artist-306-meme-description">
                                Turning data and history into poetic cryptochronicles.
                            </p>
                            <button 
                                className="artist-306-view-now-button"
                                onClick={() => toggleSection('featuredWorks')}
                            >
                                More
                            </button>
                        </div>
                    </div>
                    
                    {expandedSections.featuredWorks && (
                        <div className="artist-306-meme-content">
                            <h3 style={{ color: 'red', textAlign: 'center', marginBottom: '20px' }}>Memoirs of Bull</h3>
                            
                            <div className="artist-306-image-container" style={{ display: 'flex', justifyContent: 'center', gap: '20px', flexWrap: 'wrap' }}>
                                <div className="artist-306-seal-image" style={{ flex: '1', minWidth: '300px', maxWidth: '45%' }}>
                                    <img 
                                        src="https://artist-profiles.s3.eu-north-1.amazonaws.com/306/MemoirsOfBull.jpg" 
                                        alt="Memoirs Of Bull" 
                                        loading="lazy"
                                        style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                                    />
                                </div>
                                <div className="artist-306-seal-image" style={{ flex: '1', minWidth: '300px', maxWidth: '45%' }}>
                                    <img 
                                        src="https://artist-profiles.s3.eu-north-1.amazonaws.com/306/MemoirsOfBull02.jpg" 
                                        alt="Memoirs Of Bull 02" 
                                        loading="lazy"
                                        style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                                    />
                                </div>
                            </div>
                            
                            <div className="artist-306-meme-description-full">
                                <p>
                                    Poetic, co-creative crypto chronicle, woven from Ethereum's data and history. Following Asian
                                    landscape art tradition, it conceptually carries their profound legacy and builds provenance on-
                                    art. All bidders co-create each MEMOIR, imprinting custom signatures.
                                </p>
                            </div>
                            
                            <div className="artist-306-opensea-link">
                                <a 
                                    href="https://foundation.app/collection/MemoirsofBull" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >
                                    <img src={foundationIcon} alt="Foundation" className="opensea-icon" />
                                    <span>View Complete Collection</span>
                                </a>
                            </div>
                            
                            <h3 style={{ color: 'red', textAlign: 'center', marginTop: '40px', marginBottom: '20px' }}>SOL Scrolls</h3>
                            
                            <div className="artist-306-meme-video">
                                <video 
                                    controls 
                                    autoPlay
                                    muted
                                    loop
                                    width="100%"
                                    className="meme-video"
                                    playsInline
                                    preload="metadata"
                                >
                                    <source 
                                        src="https://artist-profiles.s3.eu-north-1.amazonaws.com/306/solscrolls.mp4" 
                                        type="video/mp4" 
                                    />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            
                            <div className="artist-306-meme-description-full">
                                <p>
                                    Historical $SOL trending data encrypted as Asian fusion landscapes.
                                </p>
                            </div>
                            
                            <div className="artist-306-opensea-link">
                                <a 
                                    href="https://exchange.art/series/SOL%20Scrolls/nfts" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >
                                    <img src={exchangeArtLogo} alt="Exchange Art" className="opensea-icon" />
                                    <span>View on Exchange Art</span>
                                </a>
                            </div>
                            
                            <h3 style={{ color: 'red', textAlign: 'center', marginTop: '40px', marginBottom: '20px' }}>CHRoNCL:ASH</h3>
                            
                            <div className="artist-306-seal-image" style={{ maxWidth: '800px', margin: '0 auto 20px', width: '100%' }}>
                                <img 
                                    src="https://artist-profiles.s3.eu-north-1.amazonaws.com/306/CHRoNCL_ASH.jpg" 
                                    alt="CHRoNCL ASH" 
                                    loading="lazy"
                                    style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                                />
                            </div>
                            
                            <div className="artist-306-meme-description-full">
                                <p>
                                    Collection interprets ASH/ETH trending data and history of ASH ecosystem into a cryptoartistic
                                    "chronicle." The project utilizes Asian landscape art tradition and aesthetics to build on-art
                                    provenance with calligraphy and seals. It invites collectors into a dialogue with the Chronicler to
                                    leave permanent marks not only on blockchain but on the artwork itself.
                                </p>
                            </div>
                            
                            <div className="artist-306-opensea-link">
                                <a 
                                    href="https://foundation.app/collection/chronclash" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >
                                    <img src={foundationIcon} alt="Foundation" className="opensea-icon" />
                                    <span>View on Foundation</span>
                                </a>
                            </div>
                            
                            <h3 style={{ color: 'red', textAlign: 'center', marginTop: '40px', marginBottom: '20px' }}>Bitcoin Strategic Reserve 2023</h3>
                            
                            <div className="artist-306-seal-image" style={{ maxWidth: '800px', margin: '0 auto 20px', width: '100%' }}>
                                <img 
                                    src="https://artist-profiles.s3.eu-north-1.amazonaws.com/306/btc2.jpeg" 
                                    alt="Bitcoin Strategic Reserve 2023" 
                                    loading="lazy"
                                    style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                                />
                            </div>
                            
                            <div className="artist-306-meme-description-full">
                                <p>
                                    A sublime mountain and water painting (山水画 aka "landscape") based on Bitcoin's trending data from 2023. 
                                    Imprinted with a short cryptic calligraphy "BTC to USD 2023" and seals [Baiwei] [BTC].
                                </p>
                            </div>
                            
                            <div className="artist-306-opensea-link">
                                <a 
                                    href="https://foundation.app/mint/eth/0x17919a70517A38b62257D1378D40fc34CE057cA7/7" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >
                                    <img src={foundationIcon} alt="Foundation" className="opensea-icon" />
                                    <span>View on Foundation</span>
                                </a>
                            </div>
                        </div>
                    )}
                </div>

                <div className="artist-306-section">
                    <h2 style={{ color: 'red' }}>Contact & Links</h2>
                    <div className="artist-306-links">
                        <a href="https://x.com/baiweiart" target="_blank" rel="noopener noreferrer">
                            <img src={xIcon} alt="X" className="social-icon" />
                            <span>X</span>
                        </a>
                        <a href="https://6529.io/baiwei/identity" target="_blank" rel="noopener noreferrer">
                            <img src={seizeIcon} alt="6529" className="social-icon" />
                            <span>6529</span>
                        </a>
                        <a href="https://foundation.app/@baiwei.eth" target="_blank" rel="noopener noreferrer">
                            <img src={foundationIcon} alt="Foundation" className="social-icon" />
                            <span>Foundation</span>
                        </a>
                        <a href="https://superrare.com/baiweiart" target="_blank" rel="noopener noreferrer">
                            <img src={superrareIcon} alt="SuperRare" className="social-icon" />
                            <span>SuperRare</span>
                        </a>
                        <a href="https://opensea.io/baiwei/created" target="_blank" rel="noopener noreferrer">
                            <img src={openseaIcon} alt="OpenSea" className="social-icon" />
                            <span>OpenSea</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Artist306Component;
