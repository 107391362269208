import React, { useEffect, useState } from 'react';
import { fetchPrices, fetchSznPrices } from './apiService';
import './PricesComponent.css'; // Import the component-specific CSS
import { ArrowUpDown, ChevronRight, ChevronDown } from 'lucide-react';
import ReactPaginate from 'react-paginate';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const PricesComponent = () => {
  // State variables
  const [prices, setPrices] = useState([]);
  const [sznPrices, setSznPrices] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'ID', direction: 'ascending' });
  const [selectedSZN, setSelectedSZN] = useState('All');
  const [selectedSupply, setSelectedSupply] = useState('');
  const [supplyCompare, setSupplyCompare] = useState('gt');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const itemsPerPage = 25;
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  // Remove fetchData function and start directly with useEffect
  useEffect(() => {
    const getPrices = async () => {
      try {
        const response = await fetchPrices({
          page: currentPage + 1,
          pageSize: itemsPerPage,
          sortKey: sortConfig.key,
          sortDirection: sortConfig.direction === 'ascending' ? 'asc' : 'desc',
          szn: selectedSZN,
          supply: selectedSupply !== '' ? selectedSupply : undefined,
          supplyCompare: supplyCompare,
          searchTerm: searchTerm,
        });
        setPrices(response.data);
        setTotalRecords(response.totalCount);
      } catch (error) {
        console.error('Error fetching prices:', error);
      }
    };

    const getSznPrices = async () => {
      try {
        const response = await fetchSznPrices();
        setSznPrices(response.data);
      } catch (error) {
        console.error('Error fetching SZN prices:', error);
      }
    };

    getPrices();
    getSznPrices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    sortConfig,
    selectedSZN,
    selectedSupply,
    supplyCompare,
    searchTerm,
  ]);

  const sortTable = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    
    // If sorting by HighestBid, convert to number for proper sorting
    if (key === 'HighestBid') {
      setPrices(prevPrices => {
        const sortedPrices = [...prevPrices].sort((a, b) => {
          const aValue = parseFloat(a.HighestBid) || 0;
          const bValue = parseFloat(b.HighestBid) || 0;
          return direction === 'ascending' ? aValue - bValue : bValue - aValue;
        });
        return sortedPrices;
      });
    }
    
    setSortConfig({ key, direction });
    setCurrentPage(0); // Reset to first page when sorting changes
  };

  // Handle page change
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const calculateTotalSznValues = () => {
    const totalAsk = sznPrices.reduce((sum, szn) => sum + (parseFloat(szn.AskTotal) || 0), 0);
    const totalBid = sznPrices.reduce((sum, szn) => {
      const bidTotal = parseFloat(szn.BidTotal);
      return isNaN(bidTotal) ? sum : sum + bidTotal;
    }, 0);
    const totalMarketCapAsk = sznPrices.reduce((sum, szn) => sum + (parseFloat(szn.MarketCapAsk) || 0), 0);
    const totalMarketCapBid = sznPrices.reduce((sum, szn) => sum + (parseFloat(szn.MarketCapBid) || 0), 0);
    const totalPPTDH = sznPrices.reduce((sum, szn) => sum + (parseFloat(szn.PPTDHTotal) || 0), 0);
    return { totalAsk, totalBid, totalMarketCapAsk, totalMarketCapBid, totalPPTDH };
  };

  // Unique values for filters
  const [uniqueSZNs, setUniqueSZNs] = useState(['All']);

  useEffect(() => {
    // Fetch unique SZNs
    const fetchUniqueValues = async () => {
      try {
        const response = await fetchPrices({
          page: 1,
          pageSize: 1000, 
        });
        const data = response.data;
        const szns = new Set(data.map(item => item.TypeSeason));
        setUniqueSZNs(['All', ...Array.from(szns)]);
      } catch (error) {
        console.error('Error fetching unique values:', error);
      }
    };
    fetchUniqueValues();
  }, []);

  return (
    <div className="prices-page">
      <Helmet>
        <title>6529 Memes NFT Prices & Valuations | Live Bids, Floor & Sales Data</title>
        <meta 
          name="description" 
          content="6529 Memes NFT Prices & Market Data – View live bids, asks & floor prices. Get season-specific and NFT-specific valuations with real-time updates."
        />
        <meta 
          property="og:title" 
          content="6529 Memes NFT Prices & Valuations | Live Bids, Floor & Sales Data"
        />
        <meta 
          property="og:description" 
          content="6529 Memes NFT Prices & Market Data – View live bids, asks & floor prices. Get season-specific and NFT-specific valuations with real-time updates."
        />
        <meta property="og:image" content="https://6529stats.xyz/share-image.png" />
        <meta property="og:url" content="https://6529stats.xyz/prices" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta 
          name="twitter:title" 
          content="6529 Memes NFT Prices & Valuations | Live Bids, Floor & Sales Data"
        />
        <meta 
          name="twitter:description" 
          content="6529 Memes NFT Prices & Market Data – View live bids, asks & floor prices. Get season-specific and NFT-specific valuations with real-time updates."
        />
        <meta name="twitter:image" content="https://6529stats.xyz/share-image.png" />
      </Helmet>

      <div className="prices-container">
        <div className="prices-header">
          <h1 className="prices-title">6529 Memes NFT Real Time Prices & Valuations Dashboard</h1>
        </div>

        {/* Filter Toggle Button */}
        <button 
          className="prices-filter-toggle"
          onClick={() => setShowFilters(!showFilters)}
        >
          Search & Filters
          <ChevronDown className={`chevron-icon ${showFilters ? 'open' : ''}`} />
        </button>

        {/* Main Table with Filters */}
        <form className={`prices-filters-form ${showFilters ? 'show' : ''}`} onSubmit={(e) => e.preventDefault()}>
          <div className="prices-filters-row">
            <div className="prices-filter-item">
              <label>SZN</label>
              <select
                value={selectedSZN}
                onChange={(e) => {
                  setSelectedSZN(e.target.value);
                  setCurrentPage(0);
                }}
              >
                {uniqueSZNs.map((szn) => (
                  <option key={szn} value={szn}>{szn}</option>
                ))}
              </select>
            </div>
            <div className="prices-filter-item">
              <label>Supply</label>
              <div className="supply-value-filter">
                <select
                  value={supplyCompare}
                  onChange={(e) => {
                    setSupplyCompare(e.target.value);
                    setCurrentPage(0);
                  }}
                  className="prices-filter-select"
                >
                  <option value="gt">Greater than</option>
                  <option value="lt">Less than</option>
                  <option value="eq">Equal to</option>
                </select>
                <input
                  type="number"
                  min="0"
                  placeholder="Enter Supply value"
                  value={selectedSupply}
                  onChange={(e) => {
                    setSelectedSupply(e.target.value);
                    setCurrentPage(0);
                  }}
                  className="prices-filter-input"
                />
              </div>
            </div>
            <div className="prices-filter-item">
              <label>Search</label>
              <div className="prices-search-container">
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    if (e.target.value.length >= 2) {
                      setShowSuggestions(true);
                    } else {
                      setShowSuggestions(false);
                    }
                  }}
                  onFocus={() => {
                    if (searchTerm.length >= 2) {
                      setShowSuggestions(true);
                    }
                  }}
                  onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
                  placeholder="Search cards..."
                />
                {showSuggestions && searchTerm.length >= 2 && (
                  <ul className="prices-suggestions-list">
                    {prices
                      .filter(item => item.Name.toLowerCase().includes(searchTerm.toLowerCase()))
                      .slice(0, 5)
                      .map((suggestion, index) => (
                        <li
                          key={index}
                          onMouseDown={() => {
                            setSearchTerm(suggestion.Name);
                            setShowSuggestions(false);
                            setCurrentPage(0);
                          }}
                        >
                          {suggestion.Name}
                        </li>
                      ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
          <div className="prices-filter-buttons">
            <button type="submit" className="prices-filter-button">Search</button>
            <button 
              type="button" 
              className="prices-filter-button"
              onClick={() => {
                setSelectedSZN('All');
                setSelectedSupply('');
                setSupplyCompare('gt');
                setSearchTerm('');
                setCurrentPage(0);
              }}
            >
              Clear Search
            </button>
          </div>
        </form>

        {/* Standalone info row */}
        <div className="prices-info-row">
          <span className="prices-records-count">
            <strong>Number of Records:</strong> {totalRecords}
          </span>
          <span className="prices-last-updated">
            <strong>Last Updated:</strong> {new Date().toLocaleString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: false
            })}
          </span>
        </div>

        <div className="prices-table-wrapper">
          <table className="prices-table">
            <thead>
              <tr>
                <th onClick={() => sortTable('ID')}>
                  <div className="prices-header-content">
                    Token No
                    <ArrowUpDown className="inline" />
                  </div>
                </th>
                <th onClick={() => sortTable('Name')}>
                  <div className="prices-header-content">
                    Name
                    <ArrowUpDown className="inline" />
                  </div>
                </th>
                <th onClick={() => sortTable('Artist')}>
                  <div className="prices-header-content">
                    Artist
                    <ArrowUpDown className="inline" />
                  </div>
                </th>
                <th onClick={() => sortTable('Supply')}>
                  <div className="prices-header-content">
                    Supply
                    <ArrowUpDown className="inline" />
                  </div>
                </th>
                <th onClick={() => sortTable('TypeSeason')}>
                  <div className="prices-header-content">
                    SZN
                    <ArrowUpDown className="inline" />
                  </div>
                </th>
                <th onClick={() => sortTable('FloorPrice')}>
                  <div className="prices-header-content">
                    Ask Price
                    <ArrowUpDown className="inline" />
                  </div>
                </th>
                <th onClick={() => sortTable('MarketCapAsk')}>
                  <div className="prices-header-content">
                    Market Cap (Ask Prices)
                    <ArrowUpDown className="inline" />
                  </div>
                </th>
                <th onClick={() => sortTable('HighestBid')}>
                  <div className="prices-header-content">
                    Top Bid
                    <ArrowUpDown className="inline" />
                  </div>
                </th>
                <th onClick={() => sortTable('MarketCapBid')}>
                  <div className="prices-header-content">
                    Market Cap (Bid Prices)
                    <ArrowUpDown className="inline" />
                  </div>
                </th>
                <th onClick={() => sortTable('LastSalePrice')}>
                  <div className="prices-header-content">
                    Last Sale Price
                    <ArrowUpDown className="inline" />
                  </div>
                </th>
                <th onClick={() => sortTable('HodlRate')}>
                  <div className="prices-header-content">
                    TDH Rate
                    <ArrowUpDown className="inline" />
                  </div>
                </th>
                <th onClick={() => sortTable('PPTDH')}>
                  <div className="prices-header-content">
                    PPTDH
                    <ArrowUpDown className="inline" />
                  </div>
                </th>
                <th>
                  <div className="prices-header-content">
                    6529
                  </div>
                </th>
                <th>
                  <div className="prices-header-content">
                    OS
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {prices.map((price, index) => (
                <tr key={index}>
                  <td>{price.ID}</td>
                  <td>
                    <NavLink
                      to={`/token/${price.ID}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="prices-name-link"
                    >
                      {price.Name} <ChevronRight className="prices-chevron-icon" />
                    </NavLink>
                  </td>
                  <td>
                    <a
                      href={`https://6529.io/${price.ArtistHandle}/identity`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="prices-name-link"
                    >
                      {price.Artist} <ChevronRight className="prices-chevron-icon" />
                    </a>
                  </td>
                  <td>{price.Supply}</td>
                  <td>{price.TypeSeason}</td>
                  <td>{price.FloorPrice}</td>
                  <td>{price.MarketCapAsk}</td>
                  <td>{price.HighestBid ? parseFloat(price.HighestBid).toFixed(5) : 'N/A'}</td>
                  <td>{price.MarketCapBid}</td>
                  <td>{parseFloat(price.LastSalePrice).toFixed(5)}</td>
                  <td>{price.HodlRate}</td>
                  <td>{price.PPTDH}</td>
                  <td>
                    <a
                      href={`https://6529.io/the-memes/${price.ID}?focus=live`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="prices-icon-link"
                    >
                      <img src={require('./assets/seizeIcon.png')} alt="6529" className="prices-table-icon" />
                    </a>
                  </td>
                  <td>
                    <a
                      href={`https://opensea.io/assets/ethereum/0x33FD426905F149f8376e227d0C9D3340AaD17aF1/${price.ID}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="prices-icon-link"
                    >
                      <img src={require('./assets/openseaIcon.png')} alt="OpenSea" className="prices-table-icon" />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <ul className="pagination">
          <ReactPaginate
            previousLabel={'← Previous'}
            nextLabel={'Next →'}
            breakLabel={'...'}
            pageCount={Math.ceil(totalRecords / itemsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            activeClassName={'active'}
            renderOnZeroPageCount={null}
          />
        </ul>

        {/* SZN Valuations Table moved to bottom */}
        <div className="prices-card">
          <table className="prices-table">
            <thead>
              <tr>
                <th colSpan="6">SZN Valuations</th>
              </tr>
              <tr>
                <th>SZN (Card Count)</th>
                <th>Ask Total</th>
                <th>Bid Total</th>
                <th>Market Cap (Ask)</th>
                <th>Market Cap (Bid)</th>
                <th>PPTDH Cost</th>
              </tr>
            </thead>
            <tbody>
              {sznPrices
                .sort((a, b) => a.SZN_ID - b.SZN_ID)
                .map((szn, index) => (
                  <tr key={index}>
                    <td>{szn.SZN_ID} ({szn.CardCount})</td>
                    <td>{parseFloat(szn.AskTotal).toFixed(2)}</td>
                    <td>{isNaN(parseFloat(szn.BidTotal)) ? '' : parseFloat(szn.BidTotal).toFixed(2)}</td>
                    <td>{parseFloat(szn.MarketCapAsk).toFixed(2)}</td>
                    <td>{parseFloat(szn.MarketCapBid).toFixed(2)}</td>
                    <td>{parseFloat(szn.PPTDHTotal).toFixed(2)}</td>
                  </tr>
                ))}
              <tr className="prices-table-total">
                <td>All SZNs</td>
                <td>{calculateTotalSznValues().totalAsk.toFixed(2)}</td>
                <td>{calculateTotalSznValues().totalBid > 0 ? calculateTotalSznValues().totalBid.toFixed(2) : ''}</td>
                <td>{calculateTotalSznValues().totalMarketCapAsk.toFixed(2)}</td>
                <td>{calculateTotalSznValues().totalMarketCapBid.toFixed(2)}</td>
                <td>{calculateTotalSznValues().totalPPTDH.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PricesComponent;
