// HodlerSupplyComponent.js

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import './HodlerSupplyComponent.css';
import { fetchHodlerSupplyData, fetchPrices } from './apiService';
import { ChevronRight, ArrowUp, ArrowDown, ArrowUpDown } from 'lucide-react';
import ReactPaginate from 'react-paginate';

const HodlerSupplyComponent = () => {
  const [data, setData] = useState([]);
  const [pricesData, setPricesData] = useState({});
  const [loading, setLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [sortConfig, setSortConfig] = useState({
    key: 'Token',
    direction: 'ascending',
  });
  const [filterConfig, setFilterConfig] = useState({
    szn: 'All',
    supply: 'All',
  });
  const [sznOptions, setSznOptions] = useState([]);
  const [supplyOptions, setSupplyOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);

  const itemsPerPage = 25;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch hodler supply data with pagination
        const response = await fetchHodlerSupplyData({
          page: currentPage + 1,
          pageSize: itemsPerPage,
          sortKey: sortConfig.key,
          sortDirection: sortConfig.direction
        });
        
        // Fetch prices data for bids and other info
        const pricesResponse = await fetchPrices({
          page: 1,
          pageSize: 1000,
        });
        
        // Create a lookup object for prices data
        const pricesLookup = pricesResponse.data.reduce((acc, item) => {
          acc[item.ID] = item;
          return acc;
        }, {});
        setPricesData(pricesLookup);

        // Merge hodler supply data with prices data
        const mergedData = response.data.map(item => ({
          ...item,
          // Override HighestBid with the value from prices data
          HighestBid: pricesLookup[item.Token]?.HighestBid || null
        }));

        setData(mergedData);
        setTotalRecords(response.totalCount);

        // Get unique TypeSeason values for the filter dropdown
        const uniqueSzn = [...new Set(response.data.map((item) => item.TypeSeason))];
        uniqueSzn.sort((a, b) => {
          const numA = parseInt(a.replace('SZN', ''));
          const numB = parseInt(b.replace('SZN', ''));
          return numA - numB;
        });
        setSznOptions(uniqueSzn);

        // Get unique Supply values for the filter dropdown
        const uniqueSupply = [...new Set(Object.values(pricesLookup).map(item => item.Supply))];
        uniqueSupply.sort((a, b) => a - b);
        setSupplyOptions(uniqueSupply);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage, sortConfig]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    setCurrentPage(0);
  };

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'ascending') {
        return <ArrowUp className="inline" />;
      } else {
        return <ArrowDown className="inline" />;
      }
    } else {
      return <ArrowUpDown className="inline" />; // Neutral sort icon to indicate sortable
    }
  };

  const sortedData = React.useMemo(() => {
    let sortableItems = [...data];

    // Apply filters
    if (filterConfig.szn !== 'All') {
      sortableItems = sortableItems.filter((item) => item.TypeSeason === filterConfig.szn);
    }

    if (filterConfig.supply !== 'All') {
      sortableItems = sortableItems.filter((item) => {
        const priceItem = pricesData[item.Token];
        return priceItem && priceItem.Supply === parseInt(filterConfig.supply);
      });
    }

    // Apply search filter
    if (searchTerm) {
      sortableItems = sortableItems.filter((item) => 
        item.Name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        // Handle null or undefined values
        if (aValue === null || aValue === undefined) aValue = '';
        if (bValue === null || bValue === undefined) bValue = '';

        // Handle numeric values for specific columns
        if (sortConfig.key === 'Supply_Excl_6529_Museum' || sortConfig.key === 'Supply_Excl_6529_Museum_IndivSZNholder' || 
            sortConfig.key === 'Supply' || sortConfig.key === 'Token' || sortConfig.key === 'FloorPrice' || 
            sortConfig.key === 'HighestBid' || sortConfig.key === 'LastSalePrice') {
          // Convert to numbers for numeric comparison
          aValue = parseFloat(aValue) || 0;
          bValue = parseFloat(bValue) || 0;
          return sortConfig.direction === 'ascending' ? aValue - bValue : bValue - aValue;
        }

        if (typeof aValue === 'string') {
          aValue = aValue.toLowerCase();
          bValue = bValue.toLowerCase();
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [data, sortConfig, filterConfig, pricesData, searchTerm]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="hodler-supply-container">
      <Helmet>
        <title>6529 Memes NFT Supply Stats | Supply & Availability by card</title>
        <meta 
          name="description" 
          content="6529 Memes NFT Supply Analysis – View each token's supply, and estimate availability by removing holdings from punk6529 and full set & season set holders."
        />
        <meta 
          property="og:title" 
          content="6529 Memes NFT Supply Stats | Supply & Availability by card"
        />
        <meta 
          property="og:description" 
          content="6529 Memes NFT Supply Analysis – View each token's supply, and estimate availability by removing holdings from punk6529 and full set & season set holders."
        />
        <meta property="og:image" content="https://6529stats.xyz/share-image.png" />
        <meta property="og:url" content="https://6529stats.xyz/supply" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta 
          name="twitter:title" 
          content="6529 Memes NFT Supply Stats | Supply & Availability by card"
        />
        <meta 
          name="twitter:description" 
          content="6529 Memes NFT Supply Analysis – View each token's supply, and estimate availability by removing holdings from punk6529 and full set & season set holders."
        />
        <meta name="twitter:image" content="https://6529stats.xyz/share-image.png" />
      </Helmet>

      <h1 className="hodler-main-header">6529 Memes NFT Collection Supply & Ownership Analysis</h1>
      <div className="hodler-filter-container">
        <div>
          <label htmlFor="szn-filter">Filter by SZN: </label>
          <select
            id="szn-filter"
            value={filterConfig.szn}
            onChange={(e) => setFilterConfig({ ...filterConfig, szn: e.target.value })}
          >
            <option value="All">All</option>
            {sznOptions.map((szn) => (
              <option key={szn} value={szn}>
                {szn}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="supply-filter">Filter by Supply: </label>
          <select
            id="supply-filter"
            value={filterConfig.supply}
            onChange={(e) => setFilterConfig({ ...filterConfig, supply: e.target.value })}
          >
            <option value="All">All</option>
            {supplyOptions.map((supply) => (
              <option key={supply} value={supply}>
                {supply}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="name-search">Search Name: </label>
          <div className="hodler-search-container">
            <input
              type="text"
              id="name-search"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                if (e.target.value.length >= 2) {
                  setShowSuggestions(true);
                } else {
                  setShowSuggestions(false);
                }
              }}
              onFocus={() => {
                if (searchTerm.length >= 2) {
                  setShowSuggestions(true);
                }
              }}
              onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
              placeholder="Search cards..."
              className="hodler-search-input"
            />
            {showSuggestions && searchTerm.length >= 2 && (
              <ul className="hodler-suggestions-list">
                {sortedData
                  .filter(item => item.Name.toLowerCase().includes(searchTerm.toLowerCase()))
                  .slice(0, 5)
                  .map((suggestion, index) => (
                    <li
                      key={index}
                      onMouseDown={() => {
                        setSearchTerm(suggestion.Name);
                        setShowSuggestions(false);
                      }}
                    >
                      {suggestion.Name}
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>
      </div>
      <div className="hodler-explanations-container">
        <div className="hodler-explanations">
          <p>
            <strong>**</strong> This includes 6529 Museum, 6529.eth, social.6529.eth, trove.6529.eth, and
            research.6529.eth
          </p>
          <p>
            <strong>***</strong> This includes the above as well as full set holders for the SZN the card is in
          </p>
        </div>
        <div className="record-count">
          Number of Records: {totalRecords}
        </div>
      </div>
      <div className="hodler-table-wrapper">
        <table className="hodler-centered-table">
          <thead>
            <tr>
              <th onClick={() => handleSort('Token')}>
                Card No {getSortIcon('Token')}
              </th>
              <th>Name</th>
              <th onClick={() => handleSort('Artist')}>
                Artist {getSortIcon('Artist')}
              </th>
              <th onClick={() => handleSort('Supply')}>
                Supply {getSortIcon('Supply')}
              </th>
              <th>SZN</th>
              <th onClick={() => handleSort('FloorPrice')}>
                Lowest Ask {getSortIcon('FloorPrice')}
              </th>
              <th onClick={() => handleSort('HighestBid')}>
                Top Bid {getSortIcon('HighestBid')}
              </th>
              <th onClick={() => handleSort('LastSalePrice')}>
                Last Sale Price {getSortIcon('LastSalePrice')}
              </th>
              <th onClick={() => handleSort('Supply')}>
                Supply {getSortIcon('Supply')}
              </th>
              <th onClick={() => handleSort('Supply_Excl_6529_Museum')}>
                Supply Excl 6529 Eco ** {getSortIcon('Supply_Excl_6529_Museum')}
              </th>
              <th onClick={() => handleSort('Supply_Excl_6529_Museum_IndivSZNholder')}>
                Supply Excl 6529 Eco Set Hodlers ***{' '}
                {getSortIcon('Supply_Excl_6529_Museum_IndivSZNholder')}
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((item) => (
              <tr key={item.Token}>
                <td>{item.Token}</td>
                <td>
                  <a 
                    href={`https://6529stats.xyz/token/${item.Token}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hodler-name-link"
                  >
                    {item.Name} <ChevronRight className="hodler-chevron-icon" />
                  </a>
                </td>
                <td>
                  {pricesData[item.Token]?.Artist ? (
                    <a 
                      href={`https://6529.io/${pricesData[item.Token].ArtistHandle}/identity`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hodler-name-link"
                    >
                      {pricesData[item.Token].Artist} <ChevronRight className="hodler-chevron-icon" />
                    </a>
                  ) : 'N/A'}
                </td>
                <td>{pricesData[item.Token]?.Supply || 'N/A'}</td>
                <td>{item.TypeSeason}</td>
                <td>{item.FloorPrice}</td>
                <td>{item.HighestBid ? parseFloat(item.HighestBid).toFixed(5) : 'N/A'}</td>
                <td>{item.LastSalePrice}</td>
                <td>{item.Supply}</td>
                <td>{item.Supply_Excl_6529_Museum}</td>
                <td>{item.Supply_Excl_6529_Museum_IndivSZNholder}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Update pagination */}
      <ReactPaginate
        previousLabel={'← Previous'}
        nextLabel={'Next →'}
        breakLabel={'...'}
        pageCount={Math.ceil(totalRecords / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'hodler-pagination'}
        activeClassName={'active'}
        previousClassName={'previous'}
        nextClassName={'next'}
        disabledClassName={'disabled'}
        breakClassName={'break'}
      />
    </div>
  );
};

export default HodlerSupplyComponent;
