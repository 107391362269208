// NotificationsComponent.js

import React from 'react';
import { Helmet } from 'react-helmet-async';
import './NotificationsComponent.css'; // Import the corresponding CSS
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

function NotificationsComponent() {
    return (
        <div className="notifications-container">
            <Helmet>
                <title>6529 NFT Email Alerts | Custom Notifications for 6529 Data</title>
                <meta 
                    name="description" 
                    content="Get custom email notifications for 6529 Memes NFTs. Track sales, prices, bids, valuations & wallet-specific alerts—tailored to your preferences."
                />
                <meta 
                    property="og:title" 
                    content="6529 NFT Email Alerts | Custom Notifications for 6529 Data"
                />
                <meta 
                    property="og:description" 
                    content="Get custom email notifications for 6529 Memes NFTs. Track sales, prices, bids, valuations & wallet-specific alerts—tailored to your preferences."
                />
                <meta property="og:image" content="https://6529stats.xyz/share-image.png" />
                <meta property="og:url" content="https://6529stats.xyz/notifications" />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta 
                    name="twitter:title" 
                    content="6529 NFT Email Alerts | Custom Notifications for 6529 Data"
                />
                <meta 
                    name="twitter:description" 
                    content="Get custom email notifications for 6529 Memes NFTs. Track sales, prices, bids, valuations & wallet-specific alerts—tailored to your preferences."
                />
                <meta name="twitter:image" content="https://6529stats.xyz/share-image.png" />
            </Helmet>

            <h1 className="notifications-main-header">6529 NFT Email Alerts | Custom Notifications for 6529 Data</h1>
            <p>
                On this page, I will demonstrate some of the options available for email notifications. 
                Essentially, I can set up notifications around pretty much any statistic or attribute 
                that is available within the website. Notifications can also be sent at custom frequencies 
                ranging from every few minutes to daily, weekly, monthly, etc.
            </p>

            <h2>Example 1 - Portfolio Update</h2>
            <p>
                The columns are self-explanatory and it also comes with a timestamp. Currently, the data only 
                updates hourly, but this will change. This can be emailed at any desired frequency and 
                will appear in this format:
            </p>

            <div className="image-item">
                <a href="/images/notifications/PortfolionNew.png" target="_blank" rel="noopener noreferrer">
                    <img 
                        src="/images/notifications/PortfolionNew.png" 
                        alt="Portfolio Update Example" 
                        loading="lazy" // Enables lazy loading for performance
                    />
                </a>
            </div>

            {/* Added line breaks for spacing */}
            <br /><br />

            <h2>Example 2 - Portfolio - Cards NOT Owned</h2>
            <p>
                This will email all the cards that you don't currently have in your Portfolio along with useful stats.
            </p>

            <div className="image-item">
                <a href="/images/notifications/NotOwned.png" target="_blank" rel="noopener noreferrer">
                    <img 
                        src="/images/notifications/NotOwned.png" 
                        alt="Cards Not Owned Example" 
                        loading="lazy" // Enables lazy loading for performance
                    />
                </a>
            </div>

            {/* Added line breaks for spacing */}
            <br /><br />

            <h2>Example 3 - Portfolio - Cards NOT Owned that ALSO have PPTDH Under 0.003</h2>

            <p>
                Following on from the previous example this can be extended further with additional filters such as cards not owned that <strong>ALSO</strong> have PPTDH under 0.003
            </p>

            <p>
                This will email all the cards that you don't currently have in your Portfolio that ALSO have PPTDH under 0.003. It will provide you with the Highest Bid Floor Price and Last Sale Price so you can act accordingly!
            </p>

            <div className="image-item">
                <a href="/images/notifications/NotOwnedPPTDHisUnder003.png" target="_blank" rel="noopener noreferrer">
                    <img 
                        src="/images/notifications/NotOwnedPPTDHisUnder003.png" 
                        alt="Cards Not Owned with PPTDH Under .003 Example" 
                        loading="lazy" // Enables lazy loading for performance
                    />
                </a>
            </div>
            <br /><br />
            <p>
                What makes it interesting is this could be applied to any criteria not just cards owned/not owned and PPTDH.
            </p>
            <p>
                <strong>Examples</strong>
            </p>

            <div className="notification-list">
                <p><strong>1:</strong> Alert me to any cards I don't own under 0.06529</p>
                <p><strong>2:</strong> Alert me to card no 4 with bids over 5.00</p>
                <p><strong>3:</strong> Alert me to any card I own with bids over 1.00</p>
                <p><strong>4:</strong> Alert me to any card in SZN9 with ask under 0.1</p>
                <p><strong>5:</strong> Alert me to any card with PPTDH under 0.002</p>
                <p><strong>6:</strong> Alert me to all cards with PPTDH under 0.003</p>
                <p><strong>7:</strong> Alert me to all cards with bids over 1.00</p>
                <p><strong>8:</strong> Alert me to all cards with asks under 0.03</p>
                <p><strong>9:</strong> Combinations of any of the above so you get the gist!</p>
                <p><strong>10:</strong> Alert me to the top sellers with prices over the past 90 days</p>
            </div>

            <p>
                The monitoring and notifying can be set up to any timescale from minutes to daily, etc.
            </p>

            <h2>Example 4 - Bid & Ask Prices Any Cards</h2>
            <p>
                This monitors the bid and ask prices for any token you maybe interested in bidding on amongst other things.  
            </p>   
            <p> 
                It will monitor bids constantly and compare them against any bids you may have and notify you within
                the email if you are the current highest bidder or not (see example below with the 
                red text next to Token 156 (MEMOVEMENT))
            </p>

            <div className="image-item">
                <a href="/images/notifications/BidAsk.png" target="_blank" rel="noopener noreferrer">
                    <img 
                        src="/images/notifications/BidAsk.png" 
                        alt="Email demonstrating bid and ask price monitoring" 
                        loading="lazy" // Enables lazy loading for performance
                    />
                </a>
            </div>

            {/* Added line breaks for spacing */}
            <br /><br />

            <p> 
                Thresholds can also be set for the bids. ie Only receive an email if the bid for card no 4 is over 1 ETH for example. This could save receiving very long emails if you're monitoring lots of cards.
            </p>
            {/* Added line breaks for spacing */}
            <br /><br />

            <h2>Example 5 - Sales Updates and Notifications</h2>
            <p>
                Sales updates and summaries can be customised to be sent at any interval required from every hour up to daily, weekly or monthly summaries.
            </p>
            <p>
                It is also possible to limit the notifications to only selected cards or SZNs etc etc 
            </p>
            {/* Added line breaks for spacing */}
            <br /><br />

            <div className="image-item">
                <a href="/images/notifications/salesreport1hr.png" target="_blank" rel="noopener noreferrer">
                    <img 
                        src="/images/notifications/salesreport1hr.png" 
                        alt="Sales Report for 6529 Memes" 
                        loading="lazy" // Enables lazy loading for performance
                    />
                </a>
            </div>

            {/* Added line breaks for spacing */}
            <br /><br />

            <h2>Example 6 - Trading Alert - Asks To Meet To Double Floor</h2>
            <p>
                Here we identify how many ask prices need to be met and at what cost for the floor price to increase by 100% or more
            </p>
            <p>
                The results for all cards are saved to a CSV file and the cards with 3 or less asks required are emailed to me
            </p>
            <p>
                The ask prices are updated every 1-2 minutes and the notifications can be set to any desired time interval 
            </p>
            {/* Added line breaks for spacing */}
            <br /><br />

            <div className="image-item">
                <a href="/images/notifications/AsksToDoubleFloor.png" target="_blank" rel="noopener noreferrer">
                    <img 
                        src="/images/notifications/AsksToDoubleFloor.png" 
                        alt="Report on how many asks to double the floor price per card" 
                        loading="lazy" // Enables lazy loading for performance
                    />
                </a>
            </div>

            {/* Added line breaks for spacing */}
            <br /><br />

            <h2>Example 7 - Wallet Tracker</h2>
            <p>
                Another email notification I have created for myself which is great for keeping in touch with all those wallets from 2021-2022 which are full of god knows what NFTs.
            </p>
            <p>
                Instead of having to remember to check them periodically I have now set a notification to email me summaries including a CSV file.
            </p>
            <p>
                These can be sent daily, weekly, monthly or even only when triggered - e.g., if any NFT has a top bid of over 0.1 for example.
            </p>

            <p>
                With this wallet it identifies the highest bids per collection in the wallet and displays the collections in that order. Sorting order can be customised easily depending on the contents of the wallet.
            </p>

            {/* Replacing the image with a video */}
            <div className="video-item">
                <video 
                    src="/images/notifications/WalletTrackerVideo.mp4" 
                    controls 
                    loading="lazy" 
                    width="600" 
                    poster="/images/notifications/WalletReport.png" // Optional: poster image before the video loads
                >
                    Your browser does not support the video tag.
                </video>
            </div>

            {/* Added line breaks for spacing */}
            <br /><br />

            <h2>Example 8 - Custom Ask Price Monitoring</h2>
            <p>
                This system allows you to monitor ask prices for any custom list of cards. Each card can be assigned its own individual ask price threshold.
            </p>
            <p>
                The ask prices are checked at intervals of your choosing—ranging from minutes to hours or even days. If any cards have an ask price below their set threshold, an email notification will be sent.
            </p>
            <p>
                In this example, 52 cards are being monitored. Only 3 have breached their thresholds, so they are the only ones included in the email. If no cards had breached their thresholds, no email would be sent.
            </p>

            <div className="image-item">
                <a href="/images/notifications/custom_asks.png" target="_blank" rel="noopener noreferrer">
                    <img 
                        src="/images/notifications/custom_asks.png" 
                        alt="Custom Ask Price Monitoring Example" 
                        loading="lazy" // Enables lazy loading for performance
                    />
                </a>
            </div>

            {/* Added line breaks for spacing */}
            <br /><br />

            <h2>Example 9 - 2 in 1 Notification</h2>
            <p>
                This is a 2 in one notification.
            </p>
            <p>
                Firstly, it checks for all cards that have an edition size under 350 as well as having an ask price under 0.003. If both of these conditions are met, then an email is sent. The edition size and ask price can be customised.
            </p>
            <p>
                Secondly, and separately, it checks for any cards with PPTDH under 0.0025 and an ask price under 0.025. If both conditions are met, then an email is sent.
            </p>
            <p>
                The script can check the ask prices at any desired interval.
            </p>

            <div className="image-item">
                <a href="/images/notifications/2in1.png" target="_blank" rel="noopener noreferrer">
                    <img 
                        src="/images/notifications/2in1.png" 
                        alt="2 in 1 Notification Example" 
                        loading="lazy" // Enables lazy loading for performance
                    />
                </a>
            </div>

            {/* Added line breaks for spacing */}
            <br /><br />

            <h2>Example 10 - Top Sellers with Prices</h2>
            <p>
                This notification highlights the top 25 best selling cards over the past 90 days, ranked by sales count. Both the number of cards and the time frame can be adjusted to meet specific needs.
            </p>
            <p>
                For each card, the notification provides:
            </p>
            <p><strong>Highest Sale Price:</strong> The maximum price at which the card was sold during the specified period.</p>
            <br />
            <p><strong>Lowest Sale Price:</strong> The minimum price at which the card was sold during the specified period.</p>
            <br />
            <p><strong>Average Sale Price:</strong> The average sale price over the specified period.</p>
            <br />
            <p><strong>Current Floor Price:</strong> The lowest available price for the card in the market at the time of the notification.</p>
            <br />
            <p><strong>Current Highest Bid:</strong> The highest existing bid price for the card in the market at the time of the notification.</p>
            <br />
            <p>
                This information allows users to identify current listings that are competitively priced relative to the 90-day sales trend.
            </p>
            <p>
                <strong>Example:</strong> The card "A Brief Animated History of NFTs" had a lowest sale price of 0.0688 ETH and an average sale price of 0.1256 ETH over the past 90 days. The current floor price is 0.069 ETH, suggesting it may be a reasonable buying opportunity compared to its recent sales history.
            </p>

            <div className="image-item">
                <a href="/images/notifications/top_sellers_with_prices.png" target="_blank" rel="noopener noreferrer">
                    <img 
                        src="/images/notifications/top_sellers_with_prices.png" 
                        alt="Top Sellers with Prices Example" 
                        loading="lazy" // Enables lazy loading for performance
                    />
                </a>
            </div>

            {/* Added line breaks for spacing */}
            <br /><br />

            {/* Example 11 - Custom Sales Notifications */}
            <h2>Example 11 - Custom Sales Notifications</h2>
            <p>
                This functionality sends an email containing two tables with detailed sales information:
            </p>
            <ul>
                <li><strong>Aggregated Data:</strong> The first table provides a summary of key sales metrics.</li>
                <li><strong>Detailed Sales Data:</strong> The second table lists all individual sales along with a comprehensive set of relevant attributes.</li>
            </ul>
            <p>
                The script is flexible and can be configured to run at any desired interval—ranging from every 30 minutes to several days or even weekly. You can also run multiple schedules simultaneously, such as:
            </p>
            <ul>
                <li><strong>Hourly Reports:</strong> Covering sales from the last hour, sent every hour.</li>
                <li><strong>Daily Reports:</strong> Covering sales from the last 24 hours, sent once a day.</li>
            </ul>
            <p>
                Additionally, the functionality includes the option to generate a CSV file with the same data, if needed.
            </p>

            <div className="image-item">
                <a href="/images/notifications/custom_sales.png" target="_blank" rel="noopener noreferrer">
                    <img 
                        src="/images/notifications/custom_sales.png" 
                        alt="Custom Sales Notifications Example" 
                        loading="lazy" // Enables lazy loading for performance
                    />
                </a>
            </div>

            {/* Added line breaks for spacing */}
            <br /><br />

            {/* Moved Contact Us button below Example 11 */}
            <Link to="/contact" className="green-button">
                Contact Us To Discuss Personalised Notifications
            </Link>

        </div>
    );
}

export default NotificationsComponent;
