// TdhStatsComponent.js

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Line } from 'react-chartjs-2';
import { ArrowUpDown } from 'lucide-react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import './TdhStatsComponent.css'; // Import the component-specific CSS
import ReactPaginate from 'react-paginate';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TdhStatsComponent = () => {
  const [tableData, setTableData] = useState([]);
  const [lastUpdated, setLastUpdated] = useState('');
  const [pptdhChartData, setPptdhChartData] = useState(null);
  const navigate = useNavigate(); // Initialize navigate
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 25;
  
  // Add new state variables for sorting and filtering
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'desc' });
  const [filters, setFilters] = useState({
    szn: '',
    supply: '',
    date: ''
  });
  const [uniqueSzns, setUniqueSzns] = useState([]);
  const [uniqueSupplies, setUniqueSupplies] = useState([]);

  useEffect(() => {
    fetch('https://api.6529stats.xyz/api/tdh_stats', { credentials: 'include' })
      .then(async (response) => {
        if (response.status === 401 || response.status === 403) {
          navigate('/subscription');
          return;
        }
        const data = await response.json();
        console.log("Raw API data:", data);

        // Sort the data by date
        const sortedData = (data.data || []).sort((a, b) => new Date(b.date) - new Date(a.date));
        setTableData(sortedData);

        // Extract unique values for filters
        const szns = [...new Set(sortedData.map(item => item.szn))].sort((a, b) => a - b);
        const supplies = [...new Set(sortedData.map(item => item.supply))].sort((a, b) => a - b);
        setUniqueSzns(szns);
        setUniqueSupplies(supplies);

        // Prepare data for the PPTDH chart
        const dailyData = {};
        sortedData.forEach(item => {
          const date = new Date(item.date).toISOString().split('T')[0];
          const pptdh = parseFloat(item.pptdh);
          // Only include PPTDH values >= 0.001
          if (pptdh >= 0.001 && (!dailyData[date] || pptdh < dailyData[date].pptdh)) {
            dailyData[date] = { date, pptdh };
          }
        });
        const chartData = Object.values(dailyData).sort((a, b) => new Date(a.date) - new Date(b.date));

        // Set up chart data
        setPptdhChartData({
          labels: chartData.map(item => item.date),
          datasets: [{
            label: 'PPTDH',
            data: chartData.map(item => item.pptdh),
            borderColor: 'red',
            backgroundColor: 'red',
            pointRadius: 3,
            tension: 0.1
          }]
        });

        // Set the last updated date using local time
        if (data.lastUpdated) {
          const apiUpdateTime = new Date(data.lastUpdated);
          const localTime = apiUpdateTime.toLocaleString(undefined, {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
          });
          setLastUpdated(`Last Updated: ${localTime}`);
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [navigate]);

  const pptdhChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: false,
        grid: { color: 'rgba(255, 255, 255, 0.1)' },
        ticks: { color: 'white' },
        title: { display: true, text: 'PPTDH', color: 'white', font: { size: 12 } }
      },
      x: {
        grid: { color: 'rgba(255, 255, 255, 0.1)' },
        ticks: {
          color: 'white',
          maxRotation: 45,
          minRotation: 45,
          callback: function(value) {
            const date = new Date(this.getLabelForValue(value));
            return date.toLocaleDateString('en-GB', {
              day: 'numeric', month: 'short', year: 'numeric'
            });
          }
        }
      }
    },
    plugins: {
      legend: { labels: { color: 'white' } },
      tooltip: {
        callbacks: {
          title: function(context) {
            const date = new Date(context[0].label);
            return date.toLocaleDateString('en-GB', {
              day: 'numeric', month: 'short', year: 'numeric'
            });
          },
          label: function(context) {
            return `PPTDH: ${context.parsed.y.toFixed(4)}`;
          }
        }
      },
      title: {
        display: true,
        text: 'Lowest PPTDH Over Time',
        color: 'white',
        font: { size: 16 }
      }
    }
  };

  // Add pagination handler
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  // Modify the groupRowsByDate function to handle pagination
  const groupRowsByDate = (data) => {
    const groups = {};
    data.forEach(row => {
      const date = new Date(row.date).toISOString().split('T')[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(row);
    });
    return Object.entries(groups);
  };

  // Get paginated data
  const getPaginatedData = (data) => {
    const groupedData = groupRowsByDate(data);
    const offset = currentPage * itemsPerPage;
    return groupedData.slice(offset, offset + itemsPerPage);
  };

  // Add sorting function
  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // Add filter handlers
  const handleFilterChange = (filterName, value) => {
    setFilters(prev => ({ ...prev, [filterName]: value }));
    setCurrentPage(0);
  };

  // Filter and sort data
  const getFilteredAndSortedData = (data) => {
    // First filter out PPTDH values below 0.001
    let filteredData = data.filter(item => parseFloat(item.pptdh) >= 0.001);

    // Apply user filters
    if (filters.szn) {
      filteredData = filteredData.filter(item => item.szn === parseInt(filters.szn));
    }
    if (filters.supply) {
      filteredData = filteredData.filter(item => item.supply === parseInt(filters.supply));
    }
    if (filters.date) {
      filteredData = filteredData.filter(item => 
        new Date(item.date).toISOString().split('T')[0] === filters.date
      );
    }

    // Apply sorting
    filteredData.sort((a, b) => {
      if (sortConfig.key === 'date') {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
      }
      if (sortConfig.key === 'pptdh') {
        const valueA = parseFloat(a.pptdh);
        const valueB = parseFloat(b.pptdh);
        return sortConfig.direction === 'asc' ? valueA - valueB : valueB - valueA;
      }
      return 0;
    });

    return filteredData;
  };

  if (!tableData.length) {
    return <div>Loading...</div>;
  }

  return (
    <div className="tdh-stats-container">
      <Helmet>
        <title>6529 Memes NFT PPTDH History | Daily Cheapest PPTDH Tracker</title>
        <meta 
          name="description" 
          content="6529 Memes NFT Collection PPTDH History. Track historical PPTDH with daily charts and see which tokens offered the lowest price per TDH each day historically"
        />
        <meta 
          property="og:title" 
          content="6529 Memes NFT PPTDH History | Daily Cheapest PPTDH Tracker"
        />
        <meta 
          property="og:description" 
          content="6529 Memes NFT Collection PPTDH History. Track historical PPTDH with daily charts and see which tokens offered the lowest price per TDH each day historically"
        />
        <meta property="og:image" content="https://6529stats.xyz/share-image.png" />
        <meta property="og:url" content="https://6529stats.xyz/tdh-stats" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta 
          name="twitter:title" 
          content="6529 Memes NFT PPTDH History | Daily Cheapest PPTDH Tracker"
        />
        <meta 
          name="twitter:description" 
          content="6529 Memes NFT Collection PPTDH History. Track historical PPTDH with daily charts and see which tokens offered the lowest price per TDH each day historically"
        />
        <meta name="twitter:image" content="https://6529stats.xyz/share-image.png" />
      </Helmet>

      <div className="tdh-header">
        <h1 className="tdh-title">6529 Memes NFT Collection TDH & PPTDH Historical Price Tracker</h1>
      </div>
      {lastUpdated && <p className="last-updated">{lastUpdated}</p>}

      <div className="chart-section">
        <h2 className="chart-section-title">Lowest PPTDH Over Time</h2>
        <div className="chart-container">
          {pptdhChartData && <Line data={pptdhChartData} options={pptdhChartOptions} />}
        </div>
      </div>

      <h2 className="chart-section-title no-border">Card(s) With Lowest Daily PPTDH</h2>
      
      <div className="filter-container">
        <div className="filter-section">
          <label>SZN:</label>
          <select 
            value={filters.szn} 
            onChange={(e) => handleFilterChange('szn', e.target.value)}
          >
            <option value="">All</option>
            {uniqueSzns.map(szn => (
              <option key={szn} value={szn}>{szn}</option>
            ))}
          </select>
        </div>
        
        <div className="filter-section">
          <label>Supply:</label>
          <select 
            value={filters.supply} 
            onChange={(e) => handleFilterChange('supply', e.target.value)}
          >
            <option value="">All</option>
            {uniqueSupplies.map(supply => (
              <option key={supply} value={supply}>{supply}</option>
            ))}
          </select>
        </div>

        <div className="search-section">
          <label>Date Search:</label>
          <input
            type="date"
            value={filters.date}
            onChange={(e) => handleFilterChange('date', e.target.value)}
            placeholder="Select date..."
          />
        </div>
      </div>

      <div className="table-wrapper">
        <table className="styled-table">
          <thead>
            <tr>
              <th className="sortable" onClick={() => requestSort('date')}>
                Date <ArrowUpDown className="inline" />
              </th>
              <th>Token No</th>
              <th>Name</th>
              <th>Meme Name</th>
              <th>Supply</th>
              <th>SZN</th>
              <th>Ask Price</th>
              <th>TDH Rate</th>
              <th className="sortable" onClick={() => requestSort('pptdh')}>
                PPTDH <ArrowUpDown className="inline" />
              </th>
            </tr>
          </thead>
          <tbody>
            {getPaginatedData(getFilteredAndSortedData(tableData)).map(([date, rows], groupIndex) => (
              rows.map((row, rowIndex) => (
                <tr key={`${date}-${rowIndex}`} className={groupIndex % 2 === 0 ? 'even-row' : 'odd-row'}>
                  <td>{new Date(row.date).toISOString().split('T')[0]}</td>
                  <td>{row.token_no}</td>
                  <td>{row.name}</td>
                  <td>{row.meme_name}</td>
                  <td>{row.supply}</td>
                  <td>{row.szn}</td>
                  <td>{parseFloat(row.ask_price).toFixed(4)}</td>
                  <td>{parseFloat(row.tdh_rate).toFixed(4)}</td>
                  <td>{parseFloat(row.pptdh).toFixed(4)}</td>
                </tr>
              ))
            ))}
          </tbody>
        </table>
      </div>

      {/* Add pagination */}
      <div className="pagination">
        <ReactPaginate
          previousLabel={'← Previous'}
          nextLabel={'Next →'}
          breakLabel={'...'}
          pageCount={Math.ceil(groupRowsByDate(tableData).length / itemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      </div>
    </div>
  );
};

export default TdhStatsComponent;
